import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PerimetriaFieldsItem: React.FC<Props> = ({ children }) => {
  return <div className="p-1 rounded-lg flex justify-center ">{children}</div>;
};

export default PerimetriaFieldsItem;
