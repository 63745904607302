import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Directory from '../Routes/Directory';
import TreinosHistorico from '../Routes/TreinosHistorico';
import Home from '../Routes/Home';
import Perfil from 'components/Routes/Perfil';
import ImportantTasks from '../Routes/ImportantTasks';
import SearchResults from '../Routes/SearchResults';
import TaskOnly from '../Routes/TaskOnly';
import TodaysTasks from '../Routes/TodaysTasks';
import HeaderTasks from './HeaderTasks';
import Videos from '../Routes/Videos';
import Document from '../Routes/Document';
import Aulas from 'components/Routes/Aulas';
import { AvaliacaoLayout } from 'components/Layouts/AvaliacaoLayout';

const MainSection: React.FC = () => {
  return (
    <main className=" pt-5 pb-8 sm:pb-16 px-3 md:px-8 md:w-full xl:w-8/12 m-auto min-h-screen">
      <HeaderTasks />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/today" element={<TodaysTasks />} />
        <Route path="/important" element={<ImportantTasks />} />
        <Route path="/completed" element={<TreinosHistorico done={true} title="Completos" />} />
        <Route path="/uncompleted" element={<TreinosHistorico done={false} title="Incompletos" />} />
        <Route path="/treinos" element={<TreinosHistorico done={true} title="Treinos" />} />
        <Route path="/results" element={<SearchResults />} />
        <Route path="/dir/:dir" element={<Directory />} />
        <Route path="/doc/:dir" element={<Document />} />
        <Route path="/task/:taskId" element={<TaskOnly />} />
        <Route path="/avaliacoes/*" element={<AvaliacaoLayout />} />
        <Route path="/atividades" element={<Aulas />} />
        <Route path="/atividades/:dir" element={<Aulas />} />
        <Route path="/tutorial" element={<Videos />} />
        <Route path="/tutorial/:dir" element={<Videos />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </main>
  );
};

export default MainSection;
