import { ArrowLeft } from 'phosphor-react';
import { Review } from './Review';
import { useAppSelector } from 'store/hooks';
import { Feedback } from './Feedback';
import { Thanks } from './Thanks';

export const Card = () => {
  const { stage } = useAppSelector((state) => state.treinos);

  return (
    <div className={`w-[410px] flex flex-col relative scale-100`}>
      <div className="w-full h-full rounded-md flex flex-col justify-center items-center gap-10">
        {stage === 'rating' && <Review />}
        {stage === 'feedback' && <Feedback />}
        {stage === 'thanks' && <Thanks />}
      </div>
    </div>
  );
};
