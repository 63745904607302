import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { treinoActions } from 'store/Treino.store';

export const Feedback = () => {
  const comment = useAppSelector((state) => state.treinos.comment);
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <div className="text-center flex flex-col gap-2">
        <h1 className="heading">Deixe um comentário</h1>
        <p className="body">Conte sobre o motivo da sua avaliação</p>
      </div>

      <TextArea value={comment} onChange={(ev) => dispatch(treinoActions.setComment(ev.target.value))} />

      <Button label="Enviar" onClick={() => dispatch(treinoActions.avaliarTreino())} />
    </div>
  );
};
