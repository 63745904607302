import { configureStore } from '@reduxjs/toolkit';
import tasksReducer, { tasksMiddleware } from './Tasks.store';
import modalReducer from './Modal.store';
import menuReducer from './Menu.store';
import videoReducer, { videosMiddleware } from './Videos.store';
import aulaReducer from './Aulas.store';
import documentoReducer from './Documentos.store';
import avaliacaoReducer from './Avaliacoes.store';
import treinoReducer from './Treino.store';
import { authReducer } from './auth.store';
import { usersReducer } from './user.store';
import professorReducer from './Professor.store';
import perfilReducer from './Perfil.store';

export * from './auth.store';
export * from './user.store';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    tasks: tasksReducer,
    modal: modalReducer,
    menu: menuReducer,
    aulas: aulaReducer,
    documentos: documentoReducer,
    videos: videoReducer,
    avaliacoes: avaliacaoReducer,
    treinos: treinoReducer,
    professor: professorReducer,
    perfil: perfilReducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(tasksMiddleware)
      .concat(videosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;
export default store;
