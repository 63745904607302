import React from 'react';
import Modal from './Modal';
import { useAppSelector } from 'store/hooks';

const ModalAtividade: React.FC<{
  onClose: () => void;
  nameForm: string;
}> = ({ onClose, nameForm }) => {
  const atividade = useAppSelector((state) => state.aulas.aula);

  return (
    <Modal onClose={onClose} title={atividade?.nome || ''}>
      {atividade && (
        <div className="w-full h-full">
          {atividade.tipo === 3 && (
            <audio controls className="w-full mt-5">
              <source src={firebaseUrl(atividade)} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          )}
          {atividade.tipo === 2 && <iframe className="w-full h-96" src={videoUrl(atividade)} title={atividade.nome}></iframe>}
          {atividade.tipo === 1 && (atividade.url.includes('jpg') || atividade.url.includes('png')) && (
            <img src={firebaseUrl(atividade)} alt="Preview" className="h-screen" style={{ margin: 'auto' }} />
          )}

          {atividade.tipo === 1 && atividade.url.includes('pdf') && (
            <object
              data={firebaseUrl(atividade)}
              width={300}
              height={600}
              type="application/pdf"
              style={{ margin: 'auto', textAlign: 'center' }}
            >
              <a href={firebaseUrl(atividade)} target="_blank" rel="noreferrer" className="text-lg font-bold text-blue-700">
                Abrir arquivo
              </a>
              <embed src={firebaseUrl(atividade)} />
            </object>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalAtividade;

const firebaseUrl = (aula: any) => {
  if (aula.tipo === 1) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/aulas%2F${aula.url}?alt=media`;
  }
  if (aula.tipo === 3) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/audios%2F${aula.url}?alt=media`;
  }
};

const videoUrl = (aula: any) => {
  if (aula.url.includes('player.vimeo')) {
    return aula.url;
  } else {
    return 'https://player.vimeo.com/video/' + aula.url.split(`/`)[3] + '?h=' + aula.url.split(`/`)[4];
  }
};
