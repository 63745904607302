import { Star as StarIcon } from 'phosphor-react';
import { treinoActions } from 'store/Treino.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface props {
  onClick: () => void;
  currentRating: number;
}

export const Star = ({ onClick, currentRating }: props) => {
  const dispatch = useAppDispatch();
  const { hover, rating } = useAppSelector((state) => state.treinos);
  return (
    <label className="w-12 h-12 bg-transparent rounded-md grid place-content-center">
      <input type="radio" className="hidden" name="rating" value={rating} onClick={onClick} />
      <StarIcon
        size={32}
        weight="fill"
        className={`w-12 h-12 p-2 bg-transparent rounded-md cursor-pointer hover:text-yellow-400 ${
          currentRating <= (hover || rating) ? 'text-yellow-400' : ''
        }`}
        // color={currentRating <= (hover || rating) ? 'var(--rating-yellow)' : 'var(--base-gray-200)'}
        onMouseEnter={() => dispatch(treinoActions.setHover(currentRating))}
        onMouseLeave={() => dispatch(treinoActions.setHover(0))}
      />
    </label>
  );
};
