import React, { useEffect, useState } from 'react';
import { Treino } from '../../interfaces';
import Habitos from 'components/Habitos';
import Pie from 'components/Pie';
import api from 'services/api';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hooks';
import cobre from 'assets/medalhas/cobre.png';
import bronze from 'assets/medalhas/bronze.png';
import prata from 'assets/medalhas/prata.png';
import ouro from 'assets/medalhas/ouro.png';

type Props = {
  title: string;
  treinos: Treino[] | [];
};

type Summary = {
  id: string;
  data: string;
  quantidade: number;
  completo: number;
}[];

type SummaryMonth = {
  ano: number;
  mes: number;
  quantidade: number;
  completo: number;
};

const LayoutRoutes: React.FC<Props> = ({ title, treinos }) => {
  const token = useAppSelector((state) => state.auth.user.token);
  const [summary, setSummary] = useState<Summary>([]);
  const [summaryMonth, setSummaryMonth] = useState<SummaryMonth>({} as SummaryMonth);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showPie, setShowPie] = useState(false);
  const [percent, setPercent] = useState(0);
  const [classificacao, setClassificacao] = useState('');
  const [totalTasks, setTotalTasks] = useState(0);
  const [summaryGrupo, setSummaryGrupo] = useState<any>(null);
  const [percentGrupo, setPercentGrupo] = useState(0);
  const [classificacaoGrupo, setClassificacaoGrupo] = useState('');
  const [currentDate, setCurrentDate] = useState(() => {
    return dayjs().set('date', 1);
  });

  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  useEffect(() => {
    setShowCalendar(false);
    api
      .get('habitos/summary', {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setSummary(response.data);
      })
      .then(() => setShowCalendar(true));
  }, [currentDate]);

  useEffect(() => {
    api
      .get('habitos/summary-month', {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setSummaryMonth(response.data);
      });
  }, [currentDate]);

  useEffect(() => {
    setShowPie(false);
    api
      .get('habitos/summary-total', {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setTotalTasks(response.data);
      });
  }, [currentDate]);

  useEffect(() => {
    setShowPie(false);
    api
      .get('habitos/summary-total-grupo', {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setSummaryGrupo(response.data);
      });
  }, [currentDate]);

  useEffect(() => {
    setShowPie(false);
    const quantidade = totalTasks;
    const completo = summaryMonth.completo;
    const calculo = (completo / quantidade) * 100;
    setPercent(Math.round(calculo));
    if (calculo < 25) {
      setClassificacao('Cobre');
    } else if (calculo >= 25 && calculo <= 50) {
      setClassificacao('Bronze');
    } else if (calculo > 50 && calculo <= 75) {
      setClassificacao('Prata');
    } else if (calculo > 75) {
      setClassificacao('Ouro');
    } else {
      setClassificacao('Sem classificação');
    }
    setShowPie(true);
  }, [summaryMonth, totalTasks, currentDate]);

  useEffect(() => {
    if (summaryGrupo == null) return;
    const quantidade = summaryGrupo.quantidade;
    const completo = summaryGrupo.completo;
    const calculo = (completo / quantidade) * 100;
    setPercentGrupo(Math.round(calculo));
    if (calculo < 25) {
      setClassificacaoGrupo('Cobre');
    } else if (calculo >= 25 && calculo <= 50) {
      setClassificacaoGrupo('Bronze');
    } else if (calculo > 50 && calculo <= 75) {
      setClassificacaoGrupo('Prata');
    } else if (calculo > 75) {
      setClassificacaoGrupo('Ouro');
    } else {
      setClassificacaoGrupo('Sem classificação');
    }
  }, [summaryGrupo]);

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">Atividades</h1>
      <div className="flex flex-wrap">
        <div className="md:max-w-[540px] md:max-h-[550px] sm:max-h-[370px]">
          {showCalendar && <Habitos summary={summary} setSummary={setSummary} currentDate={currentDate} setCurrentDate={setCurrentDate} />}
        </div>
        <div className="w-full xl:w-2/5 h-auto">
          {showPie && (
            <div className="h-auto py-2 px-2 bg-slate-100 rounded-xl text-slate-800 leading-none lg:gap-12 dark:bg-slate-800 dark:text-slate-400">
              <div>
                <div className="font-semibold text-center h-min text-2xl">Atividades realizadas</div>
                <div className="grid place-content-center relative">
                  <Pie percentage={percent} colour="blue" />
                </div>
                <div className="flex gap-8 justify-center mt-auto font-medium text-lg xl:text-2xl">
                  <div className="flex items-center gap-2">
                    <span className="flex items-center gap-2">Planejadas</span>
                    <span>{totalTasks}</span>
                  </div>
                  <div className="item">
                    <span>Realizadas </span>
                    <span>{summaryMonth.completo || 0}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center p-10">
                <div>
                  {percent > 0 && percent < 25 && <img src={cobre} className="h-44 w-44" alt="cobre" />}
                  {percent >= 25 && percent <= 50 && <img src={bronze} className="h-44 w-44" alt="bronze" />}
                  {percent > 50 && percent <= 75 && <img src={prata} className="h-44 w-44" alt="prata" />}
                  {percent > 75 && <img src={ouro} className="h-44 w-44" alt="ouro" />}
                </div>
                <span className="flex items-center mt-2 font-medium text-2xl">{classificacao}</span>
              </div>
            </div>
          )}
        </div>
        <div className="w-full xl:w-2/5 h-auto">
          {summaryGrupo && (
            <div className="h-[625px] md:h-[550px] mt-3 py-2 px-2 bg-slate-100 rounded-xl text-slate-800 leading-none lg:gap-12 dark:bg-slate-800 dark:text-slate-400">
              <div>
                <div className="font-semibold text-center h-min text-2xl">Atividades do Grupo</div>
                <div className="grid place-content-center relative">
                  <Pie percentage={percentGrupo} colour="blue" />
                </div>
                <div className="flex gap-8 justify-center mt-auto font-medium text-lg xl:text-2xl">
                  <div className="flex items-center gap-2">
                    <span className="flex items-center gap-2">Planejadas</span>
                    <span>{summaryGrupo.quantidade || 0}</span>
                  </div>
                  <div className="item">
                    <span>Realizadas </span>
                    <span>{summaryGrupo.completo || 0}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center p-10">
                <div>
                  {percentGrupo < 25 && <img src={cobre} className="h-44 w-44" alt="cobre" />}
                  {percentGrupo >= 25 && percentGrupo <= 50 && <img src={bronze} className="h-44 w-44" alt="bronze" />}
                  {percentGrupo > 50 && percentGrupo <= 75 && <img src={prata} className="h-44 w-44" alt="prata" />}
                  {percentGrupo > 75 && <img src={ouro} className="h-44 w-44" alt="ouro" />}
                </div>
                <span className="flex items-center mt-2 font-medium text-2xl">{classificacaoGrupo}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(LayoutRoutes);
