import React from 'react';
import { Treino } from '../../../interfaces';
import { Link } from 'react-router-dom';
import { ReactComponent as StarLine } from '../../../assets/star-line.svg';
import ActionsTaskItem from './ActionsTaskItem';
import InfosTreino from './InfosTreino';
import ActionsTreinoItem from './ActionsTreinoItem';
import { useAppDispatch } from 'store/hooks';
import { treinoActions } from 'store/Treino.store';

const TreinoItem: React.FC<{ isListInView1: boolean; treino: Treino }> = ({ isListInView1, treino }) => {
  const dispatch = useAppDispatch();
  const markFavorito = () => {
    dispatch(treinoActions.markFavorito(Number(treino.id)));
  };

  return (
    <>
      <li key={treino.id}>
        <Link
          to={`/dir/${treino.dir}`}
          title={treino.dir}
          className="ml-auto mr-4 w-min whitespace-nowrap overflow-hidden max-w-[10rem] text-center text-ellipsis bg-green-200 text-green-600 px-4 py-1 rounded-t-md transition dark:bg-slate-700 dark:text-slate-200 block hover:bg-green-300 dark:hover:bg-green-500"
        >
          {treino.dir}
        </Link>
        <article
          className={`bg-slate-100 rounded-lg p-3 sm:p-4 flex text-left transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent ${
            isListInView1 ? 'flex-row sm:h-32' : 'flex-col h-52 sm:h-64'
          }`}
        >
          <InfosTreino key={treino.id} treino={treino} isListInView1={isListInView1} />
          {/* <ActionsTreinoItem treino={treino} isListInView1={isListInView1} /> */}
          <div className="mb-1 text-right">
            <button
              title={treino.favorito ? 'Desfavoritar' : 'Favoritar'}
              onClick={markFavorito}
              className={`transition hover:text-slate-700 dark:hover:text-slate-200`}
            >
              <StarLine className={`w-5 h-5 sm:w-6 sm:h-6 ${treino.favorito ? 'fill-green-500 stroke-green-500 ' : 'fill-none'}`} />
            </button>
          </div>
        </article>
      </li>
    </>
  );
};

export default React.memo(TreinoItem);
