import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import LayoutRoutes from '../Utilities/LayoutRoutes';
import useSearchTreinoQuery from 'components/hooks/useSearchTreinoQuery';

const SearchResults: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [currQueryParam, setCurrQueryParam] = useState<string>('');

  useEffect(() => {
    const query = searchParams.get('q');
    if (!query) {
      // se "q" for igual a '' ou "q" não existir
      navigate('/');
    } else {
      setCurrQueryParam(query);
    }
  }, [navigate, searchParams]);

  const matched = useSearchTreinoQuery(currQueryParam);

  const title = `Results for "${currQueryParam}"`;

  useDescriptionTitle(title, title);

  return <LayoutRoutes title={title} treinos={matched}></LayoutRoutes>;
};

export default SearchResults;
