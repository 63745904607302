import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { avaliacaoActions } from '../../../store/Avaliacoes.store';

type IObjetivos = {
  checked: boolean;
  valor: string;
  objetivo: number;
};
type Inputs = {
  id: number;
  descricao: string;
  conclusao: any;
  empresa: number;
  departamento: number;
  tipoAlunos: number;
  objetivos: IObjetivos[];
};

const Objetivos: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const perguntas = useAppSelector((state) => state.avaliacoes.perguntas);
  const objetivo = useAppSelector((state) => state.avaliacoes.objetivos.selected);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(avaliacaoActions.objetivos());
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.objetivosById(Number(id)));
    }
  }, [id, dispatch]);

  const { register, handleSubmit, setValue } = useForm<Inputs>();

  useEffect(() => {
    if (!objetivo) return;
    objetivo.forEach((d: any) => {
      setValue(`objetivos.${d.objetivo}.checked`, true);
      setValue(`objetivos.${d.objetivo}.objetivo`, d.objetivo);
      setValue(`objetivos.${d.objetivo}.valor`, d.valor);
    });
  }, [objetivo, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      data.objetivos = data.objetivos.filter((o: any) => o.checked);
      await dispatch(avaliacaoActions.addObjetivos(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Objetivos</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <p className="text-sm text-gray-500">Defina de 3 a 5 objetivos prioritaarios para os próximos 6 meses?</p>
                  <p className="text-sm text-gray-500">Objetivo Geral: Melhorar os indicadores de saúde</p>
                  <p className="text-sm text-gray-500">Objetivos Específicos:</p>
                  <div className="mt-4 space-y-4">
                    {perguntas
                      .filter((p: any) => p.categoriaGeral === 1)
                      .map((o: any) => (
                        <div className="flex items-center" key={o.id}>
                          <div className="grow text-sm leading-6 w-72">
                            <label htmlFor={`filed${o.id}`} className="font-medium text-gray-900">
                              {o.titulo}
                            </label>
                          </div>
                          <div className="grow-0 flex ml-3 h-6 items-center">
                            <input
                              id={`filed${o.id}`}
                              type="checkbox"
                              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                              {...register(`objetivos.${o.id}.checked`, { required: false })}
                            />
                          </div>
                          <div className="flex ml-3 h-6 items-center">
                            <input
                              type="text"
                              className="mt-2 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              {...register(`objetivos.${o.id}.valor`, { required: false })}
                            />
                            <input
                              className="hidden"
                              type="text"
                              value={o.id}
                              {...register(`objetivos.${o.id}.objetivo`, { required: false })}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </fieldset>
                <fieldset>
                  <p className="text-sm text-gray-500">Objetivo Geral: Melhorar o bem estar</p>
                  <p className="text-sm text-gray-500">Objetivos Específicos:</p>
                  <div className="mt-4 space-y-4">
                    {perguntas
                      .filter((p: any) => p.categoriaGeral === 2)
                      .map((o: any) => (
                        <div className="flex items-center" key={o.id}>
                          <div className="grow text-sm leading-6 w-72">
                            <label htmlFor={`filed${o.id}`} className="font-medium text-gray-900">
                              {o.titulo}
                            </label>
                          </div>
                          <div className="grow-0 flex ml-3 h-6 items-center">
                            <input
                              id={`filed${o.id}`}
                              type="checkbox"
                              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                              {...register(`objetivos.${o.id}.checked`, { required: false })}
                            />
                          </div>
                          <div className="flex ml-3 h-6 items-center">
                            <input
                              type="text"
                              className="mt-2 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              {...register(`objetivos.${o.id}.valor`, { required: false })}
                            />
                            <input
                              className="hidden"
                              type="text"
                              value={o.id}
                              {...register(`objetivos.${o.id}.objetivo`, { required: false })}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </fieldset>
                <fieldset>
                  <p className="text-sm text-gray-500">Objetivo Geral: Melhorar o condicionamento físico</p>
                  <p className="text-sm text-gray-500">Objetivos Específicos:</p>
                  <div className="mt-4 space-y-4">
                    {perguntas
                      .filter((p: any) => p.categoriaGeral === 3)
                      .map((o: any) => (
                        <div className="flex items-center" key={o.id}>
                          <div className="grow text-sm leading-6 w-72">
                            <label htmlFor={`filed${o.id}`} className="font-medium text-gray-900">
                              {o.titulo}
                            </label>
                          </div>
                          <div className="grow-0 flex ml-3 h-6 items-center">
                            <input
                              id={`filed${o.id}`}
                              type="checkbox"
                              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                              {...register(`objetivos.${o.id}.checked`, { required: false })}
                            />
                          </div>
                          <div className="flex ml-3 h-6 items-center">
                            <input
                              type="text"
                              className="mt-2 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              {...register(`objetivos.${o.id}.valor`, { required: false })}
                            />
                            <input
                              className="hidden"
                              type="text"
                              value={o.id}
                              {...register(`objetivos.${o.id}.objetivo`, { required: false })}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </fieldset>
                <fieldset>
                  <p className="text-sm text-gray-500">Objetivo Geral: Estética corporal</p>
                  <p className="text-sm text-gray-500">Objetivos Específicos:</p>
                  <div className="mt-4 space-y-4">
                    {perguntas
                      .filter((p: any) => p.categoriaGeral === 4)
                      .map((o: any) => (
                        <div className="flex items-center" key={o.id}>
                          <div className="grow text-sm leading-6 w-72">
                            <label htmlFor={`filed${o.id}`} className="font-medium text-gray-900">
                              {o.titulo}
                            </label>
                          </div>
                          <div className="grow-0 flex ml-3 h-6 items-center">
                            <input
                              id={`filed${o.id}`}
                              type="checkbox"
                              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                              {...register(`objetivos.${o.id}.checked`, { required: false })}
                            />
                          </div>
                          <div className="flex ml-3 h-6 items-center">
                            <input
                              type="text"
                              className="mt-2 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              {...register(`objetivos.${o.id}.valor`, { required: false })}
                            />
                            <input
                              className="hidden"
                              type="text"
                              value={o.id}
                              {...register(`objetivos.${o.id}.objetivo`, { required: false })}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white text-2xl shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 w-full h-12"
                  disabled={loading}
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Objetivos };
