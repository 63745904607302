import { Navigate, Outlet } from 'react-router-dom';

import { history } from '../../helpers/history';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';
import { authActions } from 'store';

function PrivateRoute() {
  const dispatch = useAppDispatch();
  const liberado: number = useAppSelector((state) => state.auth.liberado);
  const { authenticate, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      history.navigate('/login');
      return;
    }
    const data = {
      id: user.id,
    };

    if (user.completed) {
      dispatch(authActions.check(data));
    }
  }, []);

  if (!authenticate) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  if (!user.completed) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/perfil" state={{ from: history.location }} />;
  }

  if (user.locked) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/questionario/bloqueado" state={{ from: history.location }} />;
  }

  if (liberado === 0) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/objetivos" state={{ from: history.location }} />;
  }
  if (liberado === 1) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/parq" state={{ from: history.location }} />;
  }
  if (liberado === 2) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/atestado" state={{ from: history.location }} />;
  }
  if (liberado === 3) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/anamnese" state={{ from: history.location }} />;
  }
  if (liberado === 4) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/radar" state={{ from: history.location }} />;
  }
  if (liberado === 5) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/neuroq" state={{ from: history.location }} />;
  }
  if (liberado === 6) {
    // logged and parq pendent in so redirect to parq page with the return url
    return <Navigate to="/questionario/antropometria" state={{ from: history.location }} />;
  }

  // authorized so return outlet for child routes
  return <Outlet />;
}

export { PrivateRoute };
