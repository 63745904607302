import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useCompletedTasks from '../hooks/useCompletedTasks';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import LayoutTreinos from '../Utilities/LayoutTreinos';
import { treinoActions } from 'store/Treino.store';

const TreinosHistorico: React.FC<{ done: boolean; title: string }> = ({ done, title }) => {
  const dispatch = useAppDispatch();
  const { treinos, favoritos } = useAppSelector((state) => state.treinos);

  useEffect(() => {
    const filter = { favoritos: favoritos };
    dispatch(treinoActions.treinosAll(filter));
    dispatch(treinoActions.exercicios());
  }, [dispatch, favoritos]);

  const filter = () => {
    dispatch(treinoActions.filterFavoritos());
  };

  // const { tasks: tasksFiltered } = useCompletedTasks({ tasks, done });
  useDescriptionTitle('Todos os treinos', title);
  return <LayoutTreinos title={title} treinos={treinos} favoritos={favoritos} setFavoritos={filter} />;
};

export default TreinosHistorico;
