import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import useCompletedTasks from '../hooks/useCompletedTasks';
import useTodayTasks from '../hooks/useTodayTasks';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import Resumo from 'components/Resumo';

const ProfessorData: React.FC = () => {
  const professor: any = useAppSelector((state) => state.professor.professor);
  const [whatsapp, setWhatsapp] = useState();

  useEffect(() => {
    if (!professor) return;

    const celular = professor.celular;
    const whats = celular.replace(/[^0-9]/g, '');
    setWhatsapp(whats);
  }, [professor]);

  return (
    <>
      {/* <div className="mt-8">
        <span className="flex justify-between mb-2">
          <span>Atividades de hoje</span> {1}/{5}
        </span>
        <div className="barProgress">
          <div style={{ width: 50 + '%' }}></div>
        </div>
      </div>
      <div className="mt-6">
        <span className="flex justify-between mb-2">
          <span>Todas as atividades </span> {5}/{15}
        </span>
        <div className="barProgress">
          <div style={{ width: 10 + '%' }}></div>
        </div>
      </div> */}
      {/* <span className="mt-6 block pt-4 border-t-slate-200 dark:border-t-slate-700/[.3] border-t-2">Sem atividades</span> */}
      <div className="mt-6">
        <article className="flex-col h-40 sm:h-40 bg-slate-100 rounded-lg p-3 sm:p-4 flex text-left transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent">
          <div className="flex flex-col flex-1 cursor-pointer ">
            <div className="flex items-center justify-between mb-2">
              <span className="block font-medium dark:text-slate-200">
                Professor:
                <br /> {professor?.nome}
              </span>
            </div>
          </div>
          <div className="flex border-dashed border-slate-200 dark:border-slate-700/[.3] border-t-2 w-full pt-4 mt-4">
            <a
              href={`https://wa.me/55${whatsapp}`}
              target="_blank"
              title="Whatsapp"
              className="bg-green-500 text-red-50  mr-4 order-0 rounded-full font-medium"
            >
              <span className="h-10 w-10 grid place-items-center">
                <FaWhatsapp size={30} />
              </span>
            </a>
            <a href={`mailto:${professor?.email}`} title="E-mail" className="bg-white text-red-50  mr-4 order-0 rounded-full font-medium">
              <span className="h-10 w-10 grid place-items-center">
                <FaEnvelope size={35} color="gray" />
              </span>
            </a>
            {/* <Resumo /> */}
          </div>
        </article>
      </div>
    </>
  );
};

export default React.memo(ProfessorData);
