import React, { useEffect, useState } from 'react';
import { useForm, Resolver } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { authActions } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

const Forgot: React.FC = () => {
  const { token } = useParams();
  const { register, handleSubmit } = useForm<FormValues>({});
  const dispatch = useAppDispatch();
  const [hasToken, setHasToken] = useState(false);
  //const { authenticate } = useAppSelector((state) => state.auth);
  //const navigate = useNavigate();

  useEffect(() => {
    setHasToken(token !== undefined);
  }, []);

  const onSubmit = handleSubmit((data) => {
    dispatch(authActions.forgot(data));
  });

  const onSubmitUpdate = handleSubmit((data) => {
    const request = { ...data, token: token };
    dispatch(authActions.reset(request));
  });

  return (
    <section>
      <div className="container mx-auto h-full px-6 py-10 md:py-24">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="mb-12 md:mb-0 w-3/4 md:w-8/12 lg:w-6/12">
            <img src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="w-full" alt="Logo" />
          </div>
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            {hasToken ? (
              <form onSubmit={onSubmitUpdate}>
                <div className="relative mb-6" data-te-input-wrapper-init>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                    id="password"
                    placeholder="E-mail"
                    {...register('password', { required: true })}
                  />
                  <label
                    htmlFor="password"
                    className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                  >
                    Nova senha
                  </label>
                </div>
                <div className="relative mb-6" data-te-input-wrapper-init>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                    id="password"
                    placeholder="E-mail"
                    {...register('confirmPassword', { required: true })}
                  />
                  <label
                    htmlFor="password"
                    className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                  >
                    Confirme a senha
                  </label>
                </div>
                <button
                  type="submit"
                  className="inline-block w-full rounded bg-blue-600 px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
                >
                  Enviar
                </button>
              </form>
            ) : (
              <form onSubmit={onSubmit}>
                <div className="relative mb-6" data-te-input-wrapper-init>
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                    id="exampleFormControlInput3"
                    placeholder="E-mail"
                    {...register('email', { required: true })}
                  />
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                  >
                    E-mail
                  </label>
                </div>
                <button
                  type="submit"
                  className="inline-block w-full rounded bg-blue-600 px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
                >
                  Redefinir
                </button>
              </form>
            )}
            <a
              href="/login"
              className="mt-5 inline-block w-full rounded bg-blue-600 px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-center text-white transition duration-150 ease-in-out hover:bg-blue-700"
            >
              Voltar
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forgot;
