import { useState, useEffect } from 'react';
import { Video } from '../../interfaces';

const useSortVideos = (videos: Video[]) => {
  const [sortedBy, setSortedBy] = useState<string>('');

  const [sortedVideos, setSortedVideos] = useState<Video[]>(videos);

  useEffect(() => {
    const sortByDate = (order: 'max-date' | 'min-date'): Video[] => {
      const toMillisseconds = (date: string) => Date.parse(date);
      const tasksCopy = [...videos];
      const sorted = tasksCopy.sort((task1, task2) => {
        const date1 = toMillisseconds(task1.insercao);
        const date2 = toMillisseconds(task2.insercao);

        if (date1 < date2) {
          return -1;
        }

        if (date1 > date2) {
          return 1;
        }

        return 0;
      });

      if (order === 'min-date') {
        return sorted;
      }

      if (order === 'max-date') {
        return sorted.reverse();
      }

      return videos; //se não existir tasks (para não retornar undefined)
    };

    // const sortByCompletedStatus = (completed: boolean): Video[] => {
    //   const tasksCopy = [...videos];
    //   const sorted = tasksCopy.sort((task1) => {
    //     if (task1.completed) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    //   if (completed) {
    //     return sorted;
    //   }
    //   if (!completed) {
    //     return sorted.reverse();
    //   }
    //   return videos;
    // };

    if (sortedBy === 'min-date' || sortedBy === 'max-date') {
      setSortedVideos(sortByDate(sortedBy));
    }
    if (sortedBy === '' || sortedBy === 'order-added') {
      setSortedVideos(videos);
    }
    // if (sortedBy === 'completed-first') {
    //   setSortedVideos(sortByCompletedStatus(true));
    // }
    // if (sortedBy === 'uncompleted-first') {
    //   setSortedVideos(sortByCompletedStatus(false));
    // }
  }, [sortedBy, videos]);
  return { sortedBy, setSortedBy, sortedVideos };
};

export default useSortVideos;
