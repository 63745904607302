import React from 'react';

import ItemDirectory from './ItemDirectory';

const ContentDirectories: React.FC<{ classActive: string }> = ({ classActive }) => {
  const directories = ['Documento', 'Video', 'Audio'];

  return (
    <>
      <ul className="max-h-36 overflow-auto">
        {directories.map((dir: string) => (
          <ItemDirectory key={dir} classActive={classActive} dir={dir} />
        ))}
      </ul>
    </>
  );
};

export default ContentDirectories;
