import React, { useEffect, useRef, useState } from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Treino } from '../../interfaces';
import { useAppSelector } from '../../store/hooks';
import Modal from './Modal';
import { FaVideo } from 'react-icons/fa';

const InputCheckbox: React.FC<{
  label: string;
  isChecked: boolean;
  setChecked: (value: React.SetStateAction<boolean>) => void;
}> = ({ isChecked, setChecked, label }) => {
  return (
    <label className="mb-0 flex items-center cursor-pointer">
      <div className="mr-2 bg-slate-300/[.5] dark:bg-slate-800 w-5 h-5 rounded-full grid place-items-center border border-slate-300 dark:border-slate-700">
        {isChecked && <span className="bg-green-500 w-2 h-2 block rounded-full"></span>}
      </div>
      <span className="order-1 flex-1">{label}</span>
      <input type="checkbox" className="sr-only" checked={isChecked} onChange={() => setChecked((prev: boolean) => !prev)} />
    </label>
  );
};

const ModalTreino: React.FC<{
  onClose: () => void;
  treino?: Treino;
  nameForm: string;
  // onConfirm: (treino: Treino) => void;
}> = ({ onClose, treino, nameForm }) => {
  const directories = useAppSelector((state) => state.tasks.directories);
  const exercicios = useAppSelector((state) => state.treinos.exercicios);
  const [viewVideo, setViewVideo] = useState(false);
  const [video, setVideo] = useState<any>({});

  const today: Date = new Date();
  const [currentExercicios, setCurrentExercicios] = useState([]);

  useEffect(() => {
    const _arr: any = [];
    const _data = eval(treino?.prescricao || '');

    _data.map((a: any) => {
      let _exercicio = {
        ...exercicios.find((e: any) => e.id == a.id),
        prescricao: a.prescricao,
        observacao: a.observacao,
      };

      _arr.push(_exercicio);
    });
    setCurrentExercicios(_arr);
  }, []);

  const select = (item: any) => {
    setVideo(item);
    setViewVideo(true);
  };

  return (
    <Modal onClose={onClose} title={nameForm}>
      <div className="flex flex-col pb-16 h-full stylesInputsField">
        <ScrollArea.Root className="w-full h-screen rounded overflow-hidden">
          <ScrollArea.Viewport className="w-full h-full rounded sm:pb-0 pb-20">
            <div className="grid gap-2 grid-cols-1">
              {currentExercicios.map((c: any) => (
                <div
                  key={c.id}
                  className={`relative flex rounded-[20px] !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent flex-col`}
                >
                  <div className={`h-full w-full`}>
                    <div className={`relative flex flex-row h-full w-full justify-between items-center text-center`}>
                      <div className="w-72">
                        <img src={firebaseUrl(c.imagem)} alt="exercicio" />
                      </div>
                      <div className="flex flex-col items-start gap-y-2 w-2/3">
                        <div className="font-bold flex">
                          {c.nome}
                          {c.video && (
                            <FaVideo color="#1171ef" style={{ cursor: 'pointer', marginLeft: 20 }} size={20} onClick={() => select(c)} />
                          )}
                        </div>
                        <div className="">{c.prescricao}</div>
                        <div className="">{c.observacao}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="flex select-none touch-none p-0.5 bg-blackA6 transition-colors duration-[160ms] ease-out hover:bg-blackA8 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="flex select-none touch-none p-0.5 bg-blackA6 transition-colors duration-[160ms] ease-out hover:bg-blackA8 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Corner className="bg-blackA8" />
        </ScrollArea.Root>
      </div>
      {viewVideo && (
        <Modal onClose={() => setViewVideo(false)} title={video.nome}>
          <iframe className="w-full h-96" src={decoreUrl(video.video)} title={video.nome}></iframe>
        </Modal>
      )}
    </Modal>
  );
};

export default ModalTreino;

const firebaseUrl = (nome: string) => `https://atitudevs.blob.core.windows.net/exercicios/${nome}`;
const decoreUrl = (url: string) => {
  let _url_fix = '';
  if (url.includes('vimeo')) {
    const urlArray = url.split('/')[3];
    _url_fix = `https://player.vimeo.com/video/${urlArray}?title=0&byline=0&portrait=0`;
  } else {
    if (url.split('/')[2] === 'youtu.be') {
      _url_fix = `https://www.youtube.com/embed/${url.split('/')[3]}`;
    } else {
      _url_fix = url.replace('watch?v=', 'embed/');
    }
  }
  return _url_fix;
};
