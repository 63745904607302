import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
// import { tasksActions } from '../../store/Tasks.store';
import ModalConfirm from '../Utilities/ModalConfirm';
import { authActions } from 'store';
import { useNavigate } from 'react-router-dom';

const DeleteTasks: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setIsModalShown] = useState<boolean>(false);

  const logout = () => {
    dispatch(authActions.logout());
    // navigate('/login');
  };

  return (
    <>
      {showModal && <ModalConfirm onClose={() => setIsModalShown(false)} text="Sair?" onConfirm={logout} />}
      <button
        className="mt-auto font-semibold text-center pt-4 hover:text-red-600 dark:hover:text-slate-200 transition "
        onClick={() => setIsModalShown(true)}
      >
        Sair
      </button>
    </>
  );
};

export default React.memo(DeleteTasks);
