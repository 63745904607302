import { ArrowRight } from 'phosphor-react';

interface Props {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button = ({ label, onClick, disabled }: Props) => {
  return (
    <button
      className="w-80 px-4 py-5 bg-blue-700 border-none rounded-lg text-gray-100 flex justify-between items-center flex-shrink cursor-pointer"
      onClick={onClick}
      disabled={disabled}
    >
      <p className="text-base	font-bold">{label}</p>
      <ArrowRight size={32} weight="fill" />
    </button>
  );
};
