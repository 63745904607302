import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { FaFile } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { app } from 'helpers';
import { convertToDate } from 'utils/converter';
import Pendente from './pendente';

// import { Container } from './styles';

type Inputs = {
  exame: any;
  dataPrescricao: any;
  observacao: string;
};

const Atestado: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const atestadoOk = useAppSelector((state) => state.avaliacoes.atestadoOk);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>(null);
  const { register, handleSubmit } = useForm<Inputs>();
  const storage = getStorage(app);

  useEffect(() => {
    dispatch(avaliacaoActions.checkAtestado());
  }, [dispatch]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      const file = data.exame[0];
      const time = new Date().getTime();
      const anexoName = time + '_' + file.name;
      const storageRef = ref(storage, 'atestados/' + anexoName);
      await uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file!');
        const request = {
          exame: anexoName,
          dataPrescricao: convertToDate(data.dataPrescricao),
          situacao: 1,
          observacao: data.observacao,
        };
        dispatch(avaliacaoActions.addAtestado(request));
        setLoading(false);
        navigate('/');
      });
    } catch (error) {
      setLoading(false);
    }
  };
  if (atestadoOk) return <Pendente />;

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Atestado</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <p className="text-sm text-gray-500 mb-5">
                    É obrigatório que o exame médico de liberação para prática da atividade física tenha assinatura e carimbo com o CRM do
                    médico.
                  </p>
                </fieldset>
                <fieldset>
                  <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      Arquivo
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="exame"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="exame"
                              type="file"
                              className="sr-only"
                              {...register('exame', { required: true })}
                              onChange={(e) => setFile(e.target.files)}
                            />
                          </label>
                          {/* <p className="pl-1">or drag and drop</p> */}
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, PDF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
                {file && (
                  <section>
                    <div className="flex gap-3 px-2 py-8 w-auto rounded-lg border border-gray-900/25 relative">
                      <div className="flex px-4 items-center justify-center rounded bg-gray-200">
                        <FaFile size={30} />
                      </div>
                      <div className="flex-1">
                        <div className="text-sm font-bold	">{file[0].name}</div>
                        <div className="text-xs	font-medium">
                          {/* <span>30 MB / </span> */}
                          <span>{(file[0].size / 1024 / 1024).toFixed(2)} MB</span>
                        </div>
                        <div className="flex items-center gap-3 mt-1">
                          <progress className="flex-1" value={100} max={100}></progress>
                          <span>100%</span>
                        </div>
                      </div>
                      <div className="absolute top-2 right-2 cursor-pointer">
                        <IoMdClose onClick={() => setFile(null)} />
                      </div>
                    </div>
                    <div></div>
                    <div></div>
                  </section>
                )}
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="dataPrescricao" className="block text-sm font-medium leading-6 text-gray-900">
                      Vencimento
                    </label>
                    <div className="mt-2">
                      {/* <input
                      type="text"
                      id="dataPrescricao"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      {...register('dataPrescricao', { required: true })}
                    /> */}
                      <ReactInputMask
                        mask={'99/99/9999'}
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...register('dataPrescricao', { required: true })}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="observacao" className="block text-sm font-medium leading-6 text-gray-900">
                      Observação
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="observacao"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...register('observacao', { required: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
                  disabled={loading}
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Atestado };
