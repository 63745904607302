import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PerimetriaDesc: React.FC<Props> = ({ children }) => {
  return <div className="p-1 w-6/12 md:w-5/6 flex items-center justify-start ">{children}</div>;
};

export default PerimetriaDesc;
