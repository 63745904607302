import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { history } from '../../helpers/history';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authActions } from 'store';

function PrivateRouteSafe() {
  const dispatch = useAppDispatch();
  const liberado: number = useAppSelector((state) => state.auth.liberado);
  const { authenticate, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const { id } = user;
    dispatch(authActions.check({ id }));
  }, [dispatch, user]);

  if (!authenticate) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  if (liberado === 7) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" state={{ from: history.location }} />;
  }

  // authorized so return outlet for child routes
  return <Outlet />;
}

export { PrivateRouteSafe };
