const Bloqueado: React.FC = () => {
  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">
        Acesso Negado!
      </h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
              <fieldset>
                <div className="mt-4 space-y-4">
                  <p className="text-sm text-gray-500 mb-5">Usuario bloqueado, procure seu professor!</p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Bloqueado };
