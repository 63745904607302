import { treinoActions } from 'store/Treino.store';
import { Button } from '../Button';
import { StarRating } from '../StarRating';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Review = () => {
  const rating = useAppSelector((state) => state.treinos.rating);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col justify-center items-center gap-10">
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="text-center flex flex-col gap-2">
          <h1 className="text-xl font-bold">Avalie o treino</h1>
          <p className="text-base font-normal">O que você achou do treino ?</p>
          {/* <p>
            <span className="text-blue-500">Smartwatch Amazfit Bip U Pro</span>?
          </p> */}
        </div>

        <div>
          <StarRating />
          <div className="flex justify-between px-0 py-2">
            <span className="span">Ruim</span>
            <span className="span">Ótimo</span>
          </div>
        </div>
      </div>

      <Button label="Continuar" onClick={() => dispatch(treinoActions.changeStage())} disabled={rating <= 0 ? true : false} />
    </div>
  );
};
