import Pagination from 'components/Pagination';
import ListItem from 'components/TasksSection/TaskItem/ListItem';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const AntropometriaList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { list, currentPage, pages } = useAppSelector((state) => state.avaliacoes.antropometria);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(avaliacaoActions.antropometriaByAluno());
  }, [dispatch]);

  useEffect(() => {
    dispatch(avaliacaoActions.nextPageAntropometria(page));
  }, [page, dispatch]);

  return (
    <section>
      <div className="flex justify-between">
        <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200 uppercase">
          Antropometria
        </h1>
        <div className="justify-end my-auto">
          <Link to={'0'} className="">
            <div className="w-40 xl:w-52 bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 active:bg-blue-700 rounded-md px-5 py-2 text-sm leading-5 font-semibold text-white text-center">
              Novo
            </div>
          </Link>
        </div>
      </div>
      <ul className={`tasksList mt-4 grid gap-2 grid-cols-1`}>
        {list.map((item: any) => (
          <ListItem key={item.id} item={item} />
        ))}
      </ul>
      <Pagination currentPage={currentPage} pages={pages} handleGotoPage={setPage} />
    </section>
  );
};

export { AntropometriaList };
