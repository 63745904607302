import React from 'react';
import avatar1 from '../../assets/avatar.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { menusActions } from '../../store/Menu.store';
import LayoutMenus from '../Utilities/LayoutMenus';
import DarkMode from './DarkMode';
import DeleteTasks from './DeleteTasks';
import ProfessorData from './ProfessorData';
import { modalActions } from 'store/Modal.store';
import { actions } from 'store/Perfil.store';

const AccountData: React.FC = () => {
  const menuOpen = useAppSelector((state) => state.menu.menuAccountOpened);
  const user = useAppSelector((state) => state.auth.user);
  const completed = useAppSelector((state) => state.auth.user.completed);

  const dispatch = useAppDispatch();

  const closeMenuHandler = () => {
    dispatch(menusActions.closeMenuAccount());
  };

  const openPerfilModal = () => {
    if (completed) {
      dispatch(actions.getPerfil());
      dispatch(modalActions.openModalPerfil());
    }
  };

  return (
    <LayoutMenus menuOpen={menuOpen} closeMenuHandler={closeMenuHandler} className="top-0 right-0 ">
      <section className="p-5 flex flex-col h-full">
        <span className="flex items-center mx-auto cursor-pointer" onClick={openPerfilModal}>
          <span className="font-medium">Olá, {user.nome}!</span>
          <img src={avatar1} alt="cat" className="w-10 rounded-full ml-4" />
        </span>
        <DarkMode />
        {completed && <ProfessorData />}
        <DeleteTasks />
      </section>
    </LayoutMenus>
  );
};

export default AccountData;
