import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PerimetriaField: React.FC<Props> = ({ children }) => {
  return <div className="p-1 w-3/12 md:w-1/6 flex-none flex items-center justify-center ">{children}</div>;
};

export default PerimetriaField;
