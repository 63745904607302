export function convertToDate(dateString: string) {
  let d = dateString.split('/');
  let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  return dat;
}

export function formatToDate(date: Date) {
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  let dat = d + '/' + m + '/' + y;
  return dat;
}

export function Capitalize(word: string) {
  return word
    .split('')
    .map((letter, index) => (index ? letter.toLowerCase() : letter.toUpperCase()))
    .join('');
}
