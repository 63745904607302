import { useEffect, useState } from 'react';
import { Treino } from '../../interfaces';
import { useAppSelector } from '../../store/hooks';

const useSearchTreinoQuery = (searchQuery: string) => {
  const treinos = useAppSelector((state) => state.treinos.treinos);

  const [matched, setMatched] = useState<Treino[]>([]);

  useEffect(() => {
    const filtered = treinos.filter((treino: Treino) => {
      return treino.titulo.toLowerCase().includes(searchQuery.toLowerCase());
    });
    if (searchQuery.trim().length) {
      setMatched(filtered);
    } else {
      setMatched([]);
    }
  }, [searchQuery, treinos]);

  return matched;
};

export default useSearchTreinoQuery;
