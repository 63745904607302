import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { useAppDispatch } from 'store/hooks';

type Inputs = {
  peso: string;
  altura: string;
  ciente: boolean;
};

const Imc: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await dispatch(avaliacaoActions.addIMC(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Imc</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="peso" className="block text-sm font-medium leading-6 text-gray-900">
                        Qual é o seu peso, em Kg?
                      </label>
                      <div className="mt-2">
                        <input type="text" id="peso" className="inputStyles w-full" {...register('peso', { required: true })} />
                      </div>
                      {errors.peso && <p className="text-red-500">*Obrigatório.</p>}
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="altura" className="block text-sm font-medium leading-6 text-gray-900">
                        Qual é a sua altura, em centímetros?
                      </label>
                      <div className="mt-2">
                        <input type="text" id="altura" className="inputStyles w-full" {...register('altura', { required: true })} />
                      </div>
                      {errors.altura && <p className="text-red-500">*Obrigatório.</p>}
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-6 space-y-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id="ciente"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          {...register('ciente', { required: true })}
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          Confirmo a veracidade dos dados aqui apresentados e me responsabilizo pelos mesmos, sabendo que são de fundamental
                          importância para minha avaliação física.
                        </label>
                      </div>
                    </div>
                    {errors.ciente && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Imc };
