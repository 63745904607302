import React, { useState } from 'react';
import { Treino } from '../../../interfaces';
import { ReactComponent as Calendar } from '../../../assets/date.svg';
import useDate from '../../hooks/useDate';
import { useAppDispatch } from 'store/hooks';
import { treinoActions } from 'store/Treino.store';
import ModalTreino from 'components/Utilities/ModalTreino';

const nomeDiaSemana = ['Dom', ' Seg', ' Ter', ' Quar', ' Qui', ' Sex', ' Sab'];

const InfosTreino: React.FC<{ treino: Treino; isListInView1: boolean }> = ({ treino, isListInView1 }) => {
  const dateFormated = useDate(treino.insercao);
  const dateFormatedEnd = useDate(treino.dataFinal);
  const [modalEditTaskOpen, setModalEditTaskOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const closeModalEditTask = () => {
    setModalEditTaskOpen(false);
  };

  const openModalEditTask = () => {
    setModalEditTaskOpen(true);
  };

  const editTaskHandler = (treino: Treino) => {
    dispatch(treinoActions.edit(treino));
  };

  const formatWeekDay = (treino: Treino) => {
    const week = [];
    if (treino.domingo) week.push('DOM');
    if (treino.segunda) week.push('SEG');
    if (treino.terca) week.push('TER');
    if (treino.quarta) week.push('QUA');
    if (treino.quinta) week.push('QUI');
    if (treino.sexta) week.push('SEX');
    if (treino.sabado) week.push('SAB');

    return week.toString();
  };

  return (
    <>
      <div className={`flex flex-col flex-1 cursor-pointer ${isListInView1 ? 'mr-6' : ''}`} onClick={openModalEditTask}>
        <div className={`flex items-center justify-between ${isListInView1 ? 'mb-1' : 'mb-2'}`}>
          <span className="block font-medium dark:text-slate-200">
            {`Treino `}
            {formatWeekDay(treino)}
          </span>
        </div>
        <p
          title={treino.descricao}
          className={`description mb-2 text-slate-500 dark:text-slate-500 ${
            isListInView1 ? 'line-clamp-2 sm:line-clamp-1' : 'line-clamp-3'
          }`}
        >
          {treino.descricao}
        </p>
        <time className="mt-auto flex w-full">
          <Calendar className="mr-2 w-4 sm:w-5" /> {dateFormated} - {dateFormatedEnd}
        </time>
      </div>
      {modalEditTaskOpen && (
        <ModalTreino
          key={treino.id}
          onClose={closeModalEditTask}
          treino={treino}
          nameForm={`Treino ${dateFormated}`}
          // onConfirm={editTaskHandler}
        />
      )}
    </>
  );
};

export default InfosTreino;
