import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PerimetriaRow: React.FC<Props> = ({ children }) => {
  return <div className="flex gap-2 mb-3 text-sm font-bold leading-6">{children}</div>;
};

export default PerimetriaRow;
