import * as Checkbox from '@radix-ui/react-checkbox';
import dayjs from 'dayjs';
import { Check, File, Medal } from 'phosphor-react';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { actions } from 'store/Aulas.store';
import { modalActions } from 'store/Modal.store';
import { treinoActions } from 'store/Treino.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface HabitLisProps {
  date: Date;
  onCompletedChanged: (completed: number) => void;
}

interface HabitsInfo {
  habitosPossiveis: {
    id: string;
    titulo: string;
    criado: string;
    atividade: string;
  }[];
  treinosPossiveis: {
    id: string;
    titulo: string;
    criado: string;
  }[];
  habitosCompletos: string[];
  treinosCompletos: string[];
}

export function HabitosList({ date, onCompletedChanged }: HabitLisProps) {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.user.token);
  const [habitsInfo, setHabitsInfo] = useState<HabitsInfo>();
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  useEffect(() => {
    api
      .get('habitos/dia', {
        params: {
          dia: new Date(date.toDateString()).toISOString(),
        },
      })
      .then((response) => {
        setHabitsInfo(response.data);
      });
  }, [date]);

  async function handleToggleHabit(habitId: string) {
    const isHabitAlreadyCompleted = habitsInfo!.habitosCompletos.includes(habitId);

    await api.patch(`/habitos/habits/${habitId}/toggle?data=${new Date(date.toDateString()).toISOString()}`);

    let habitosCompletos: string[] = [];

    if (isHabitAlreadyCompleted) {
      habitosCompletos = habitsInfo!.habitosCompletos.filter((id) => id !== habitId);
    } else {
      habitosCompletos = [...habitsInfo!.habitosCompletos, habitId];
    }

    setHabitsInfo({
      habitosPossiveis: habitsInfo!.habitosPossiveis,
      treinosPossiveis: habitsInfo!.treinosPossiveis,
      habitosCompletos,
      treinosCompletos: habitsInfo!.treinosCompletos,
    });

    onCompletedChanged(habitosCompletos.length + habitsInfo!.treinosCompletos.length);
  }

  async function handleToggleTreino(treinoId: string) {
    const isTreinoAlreadyCompleted = habitsInfo!.treinosCompletos.includes(treinoId);

    if (!isTreinoAlreadyCompleted) {
      dispatch(treinoActions.setTreino({ treinoId, data: new Date(date.toDateString()).toISOString() }));
      dispatch(treinoActions.resetStage());
      dispatch(modalActions.openModalReview());
    } else {
      const data = {
        treino: treinoId,
        nota: 0,
        data: new Date(date.toDateString()).toISOString(),
        comentario: '',
      };
      await api.post(`/treinos/review/`, data);
    }

    let treinosCompletos: string[] = [];

    if (isTreinoAlreadyCompleted) {
      treinosCompletos = habitsInfo!.treinosCompletos.filter((id) => id !== treinoId);
    } else {
      treinosCompletos = [...habitsInfo!.treinosCompletos, treinoId];
    }

    setHabitsInfo({
      habitosPossiveis: habitsInfo!.habitosPossiveis,
      treinosPossiveis: habitsInfo!.treinosPossiveis,
      habitosCompletos: habitsInfo!.habitosCompletos,
      treinosCompletos,
    });

    onCompletedChanged(treinosCompletos.length + habitsInfo!.habitosCompletos.length);
  }

  const isDateInPast = dayjs(date).endOf('day').isBefore(new Date()) && false;

  const getAula = (id: string) => {
    dispatch(actions.getAula(Number(id)));
    dispatch(modalActions.openModalAtividade());
  };

  const getTreino = (id: string) => {
    dispatch(treinoActions.getTreino(Number(id)));
    dispatch(modalActions.openModalTreino());
  };

  return (
    <div className="mt-6 flex flex-col gap-3">
      {habitsInfo?.habitosPossiveis.map((habit) => {
        return (
          <div key={habit.id} className="flex">
            <Checkbox.Root
              key={habit.id}
              onCheckedChange={() => handleToggleHabit(habit.id)}
              checked={habitsInfo.habitosCompletos.includes(habit.id)}
              disabled={isDateInPast}
              className="flex items-center gap-3 group focus:outline-none disabled:cursor-not-allowed"
            >
              <div className="h-8 w-8 rounded-lg flex items-center justify-center bg-gray-200 border-2 border-gray-300 group-data-[state=checked]:bg-green-500 group-data-[state=checked]:border-green-50 transition-colors group-focus:ring-2 group-focus:ring-violet-600 group-focus:ring-offset-2 group-focus:ring-offset-background">
                <Checkbox.Indicator>
                  <Check size={20} className="text-white" />
                </Checkbox.Indicator>
              </div>

              <span className="font-semibold text-xl text-gray-800 leading-tight group-data-[state=checked]:line-through group-data-[state=checked]:text-zinc-400">
                {habit.titulo}
              </span>
            </Checkbox.Root>
            <span
              className="font-semibold text-xl text-gray-800 leading-tight ml-3 cursor-pointer"
              onClick={() => getAula(habit.atividade)}
            >
              <File size={32} className="text-white" />
            </span>
          </div>
        );
      })}
      {habitsInfo?.treinosPossiveis.map((treino) => {
        return (
          <div key={treino.id} className="flex">
            <Checkbox.Root
              key={treino.id}
              onCheckedChange={() => handleToggleTreino(treino.id)}
              checked={habitsInfo.treinosCompletos.includes(treino.id)}
              disabled={isDateInPast}
              className="flex items-center gap-3 group focus:outline-none disabled:cursor-not-allowed"
            >
              <div className="h-8 w-8 rounded-lg flex items-center justify-center bg-gray-200 border-2 border-gray-300 group-data-[state=checked]:bg-green-500 group-data-[state=checked]:border-green-50 transition-colors group-focus:ring-2 group-focus:ring-violet-600 group-focus:ring-offset-2 group-focus:ring-offset-background">
                <Checkbox.Indicator>
                  <Check size={20} className="text-green-600" weight="fill" />
                </Checkbox.Indicator>
              </div>

              <span className="font-semibold text-xl text-gray-800 leading-tight group-data-[state=checked]:line-through group-data-[state=checked]:text-zinc-400">
                {treino.titulo}
              </span>
            </Checkbox.Root>
            <span className="font-semibold text-xl text-gray-800 leading-tight ml-3 cursor-pointer" onClick={() => getTreino(treino.id)}>
              <Medal size={32} className="text-blue-600" weight="fill" />
            </span>
          </div>
        );
      })}
    </div>
  );
}
