import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Aula } from 'interfaces';
import { Capitalize } from 'utils/converter';
import { ReactComponent as StarLine } from '../../../assets/star-line.svg';
import { useAppDispatch } from 'store/hooks';
import { actions } from 'store/Aulas.store';

const AulaItem: React.FC<{ isListInView1: boolean; item: Aula }> = ({ isListInView1, item }) => {
  const dispatch = useAppDispatch();

  const markFavorito = () => {
    dispatch(actions.markFavorito(Number(item.id)));
  };

  return (
    <>
      <li key={item.id}>
        <Dialog.Root>
          <div className="ml-auto mr-4 w-min whitespace-nowrap overflow-hidden max-w-[10rem] text-center text-ellipsis bg-green-200 text-green-600 px-4 py-1 rounded-t-md transition dark:bg-slate-700 dark:text-slate-200 block hover:bg-green-300 dark:hover:bg-green-500">
            {Capitalize(item.tipoDesc)}
          </div>
          <div
            className={`relative flex rounded-[20px] w-full !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent ${
              isListInView1 ? 'flex-row sm:h-32' : 'flex-col h-auto'
            }`}
          >
            <div className={`h-full w-full ${isListInView1 ? 'flex' : ''}`}>
              <Dialog.Trigger asChild>
                <div className={`relative w-full ${isListInView1 ? 'h-full' : 'h-52'}`}>
                  <div
                    style={{
                      backgroundImage: `url(https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/Nft3.3b3e6a4b3ada7618de6c.png)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                    className={`mb-3 h-full w-full rounded-xl`}
                  />
                </div>
              </Dialog.Trigger>
              <div className="my-3 w-full flex items-center justify-between px-1 md:items-start">
                <div className="mb-2 w-11/12">
                  <p className="text-lg font-bold text-navy-700 truncate"> {item.nome} </p>
                  <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">Por: {item.criadorDesc} </p>
                </div>
                <div className="mb-2">
                  <button
                    title={item.favorito ? 'Desfavoritar' : 'Favoritar'}
                    onClick={markFavorito}
                    className={`transition hover:text-slate-700 dark:hover:text-slate-200`}
                  >
                    <StarLine className={`w-5 h-5 sm:w-6 sm:h-6 ${item.favorito ? 'fill-green-500 stroke-green-500 ' : 'fill-none'}`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[850px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">{item.nome}</Dialog.Title>
              <div>
                {item.tipo === 3 && (
                  <audio controls className="w-full mt-5">
                    <source src={firebaseUrl(item)} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                )}
                {item.tipo === 2 && <iframe className="w-full h-96" src={videoUrl(item)} title={item.nome}></iframe>}
                {item.tipo === 1 && (item.url.includes('jpg') || item.url.includes('png')) && (
                  <img src={firebaseUrl(item)} alt="Preview" className="w-auto h-auto" style={{ margin: 'auto' }} />
                )}

                {item.tipo === 1 && item.url.includes('pdf') && (
                  <object data={firebaseUrl(item)} width={600} height={600} style={{ margin: 'auto' }}>
                    pdf
                  </object>
                )}
              </div>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </li>
    </>
  );
};

export default React.memo(AulaItem);

const firebaseUrl = (aula: any) => {
  if (aula.tipo === 1) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/aulas%2F${aula.url}?alt=media`;
  }
  if (aula.tipo === 3) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/audios%2F${aula.url}?alt=media`;
  }
};

const videoUrl = (aula: any) => {
  if (aula.url.includes('player.vimeo')) {
    return aula.url;
  } else {
    return 'https://player.vimeo.com/video/' + aula.url.split(`/`)[3] + '?h=' + aula.url.split(`/`)[4];
  }
};
