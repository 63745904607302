import { Action, createAsyncThunk, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { Aula } from '../interfaces';
import { authHeader } from 'helpers';
import api from 'services/api';

const initialState: {
  professor: any;
  error: boolean;
} = {
  professor: null,
  error: false,
};

const name = 'professor';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const professorSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    // method: 'GET',
    headers: authHeader(),
  };

  return {
    getProfessor: getProfessor(),
  };

  function getProfessor() {
    return createAsyncThunk(
      `${name}/get`,
      async () =>
        await api.get(`professores/aluno`, {
          headers: authHeader(),
        })
    );
  }
}

function createExtraReducers() {
  return (builder: any) => {
    getProfessor();

    function getProfessor() {
      let { pending, fulfilled, rejected } = extraActions.getProfessor;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.professor = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const actions = { ...professorSlice.actions, ...extraActions };
export default professorSlice.reducer;
