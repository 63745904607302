import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { convertToDate, formatToDate } from 'utils/converter';
import { estados } from 'utils/apoio';
import api from 'services/api';
import _ from 'lodash';
import { authActions } from 'store/auth.store';
import { history } from 'helpers';
import ReactInputMask from 'react-input-mask';

type Inputs = {
  nome: string;
  sobrenome: string;
  nascimento: Date;
  nascimentoFormat: string;
  sexo: string;
  estadoCivil: string;
  escolaridade: string;
  celular: string;
  email: string;
  cpf: string;
  cep: string;
  endereco: string;
  estado: string;
  cidade: string;
  codigo: string;
  professor: number;
  grupo: number;
};

const sexos = [
  { name: 'Feminino', value: '2' },
  { name: 'Masculino', value: '1' },
];
const estadoCivil = [
  { name: 'Solteiro', value: 1 },
  { name: 'Casado', value: 2 },
  { name: 'Viuvo', value: 3 },
  { name: 'Divorciado', value: 4 },
  { name: 'Não Declarado', value: 5 },
];
const escolaridade = [
  {
    id: 1,
    value: 1,
    name: 'Educação infantil ',
  },
  {
    id: 2,
    value: 2,
    name: 'Ensino Fundamental ',
  },
  {
    id: 3,
    value: 3,
    name: 'Ensino Médio ',
  },
  {
    id: 4,
    value: 4,
    name: 'Superior (Graduação) ',
  },
  {
    id: 5,
    value: 5,
    name: 'Pós-graduação ',
  },
  {
    id: 6,
    value: 6,
    name: 'Mestrado ',
  },
  {
    id: 7,
    value: 7,
    name: 'Doutorado',
  },
];

const Perfil: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token, nome, sobrenome, email } = useAppSelector((state) => state.auth.user);
  const { aluno } = useAppSelector((state) => state.perfil);
  const [grupoProfessor, setGrupoProfessor] = useState<any>(null);
  const [valid, setValid] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    setValue('nome', nome);
    setValue('sobrenome', sobrenome);
    setValue('email', email);
  }, [aluno]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!valid) {
      toast.error(`Codigo invalido!`);
      return;
    }
    data.nascimento = convertToDate(data.nascimentoFormat);
    const _data = _.mapValues(data, (v) => (v === '' ? null : v));
    await dispatch(authActions.createPerfil(_data));
    // console.log(_data);
    history.navigate('/');
  };

  const getCEP = (cepValue: string) => {
    const _cep: string = cepValue || aluno.cep;
    const cep = _cep.replaceAll('_', '');
    console.log(_cep);

    if (cep.length != 8) return;

    api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        if (response.data.erro) {
          return;
        }

        const { uf, logradouro, localidade } = response.data;
        const estado = estados.find((e) => e.name === uf);

        setValue('endereco', logradouro);
        setValue('estado', estado?.value || `0`);
        setValue('cidade', localidade);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Validar = () => {
    const { codigo } = watch();
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api
      .get(`professores/validacao/${codigo}`)
      .then((response) => {
        setGrupoProfessor(response.data);
        setValid(true);
      })
      .then(() => {
        if (grupoProfessor.tipo === 1) {
          setValue('grupo', grupoProfessor.grupo);
        }
        setValue('professor', grupoProfessor.professor);
      })
      .catch(() => {
        toast.error('Codigo invalido!');
      });
  };

  return (
    <form className="flex flex-col stylesInputsField" onSubmit={handleSubmit(onSubmit)}>
      <label>
        Nome
        <input type="text" placeholder="Nome" required {...register('nome', { required: true })} className="w-full" />
      </label>
      <label>
        Sobrenome
        <input type="text" placeholder="Sobrenome" required {...register('sobrenome', { required: true })} className="w-full" />
      </label>
      <label>
        Nascimento
        {/* <input type="text" className="w-full" required {...register('nascimentoFormat', { required: true })} /> */}
        <ReactInputMask mask={'99/99/9999'} required {...register('nascimentoFormat', { required: true })} className="w-full" />
      </label>
      <label>
        Sexo
        <select className="block w-full" {...register('sexo', { required: true })}>
          {sexos.map((sexo: any) => (
            <option key={sexo.id} value={sexo.value} className="bg-slate-100 dark:bg-slate-800">
              {sexo.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Estado civil
        <select className="block w-full" {...register('estadoCivil', { required: false })}>
          <option value="" className="bg-slate-100 dark:bg-slate-800" />
          {estadoCivil.map((e: any) => (
            <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
              {e.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Escolaridade
        <select className="block w-full" {...register('escolaridade', { required: false })}>
          <option value="" className="bg-slate-100 dark:bg-slate-800" />
          {escolaridade.map((e: any) => (
            <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
              {e.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Email
        <input type="text" readOnly placeholder="email@email.com" {...register('email', { required: true })} className="w-full" />
      </label>
      <label>
        Celular
        {/* <input type="text" placeholder="99999-9999" required {...register('celular', { required: true })} className="w-full" /> */}
        <ReactInputMask mask={'(99) 99999-9999'} required {...register('celular', { required: true })} className="w-full" />
      </label>
      <label>
        CPF
        {/* <input type="text" placeholder="999.999.999-99" required {...register('cpf', { required: true })} className="w-full" /> */}
        <ReactInputMask mask={'999.999.999-99'} required {...register('cpf', { required: true })} className="w-full" />
      </label>
      <label>
        CEP
        <input
          type="text"
          placeholder="99999-999"
          {...register('cep', { required: false })}
          onChange={(e) => getCEP(e.target.value)}
          className="w-full"
        />
      </label>
      <label>
        Endereço
        <input type="text" placeholder="Endereço" {...register('endereco', { required: false })} className="w-full" />
      </label>
      <label>
        Estado
        <select className="block w-full" {...register('estado', { required: false })}>
          <option value="" className="bg-slate-100 dark:bg-slate-800" />
          {estados.map((e: any) => (
            <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
              {e.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Cidade
        <input type="text" placeholder="Cidade" {...register('cidade', { required: false })} className="w-full" />
      </label>
      <label>
        <a>Codigo</a>
        <div className="flex">
          <input type="text" placeholder="Codigo" {...register('codigo', { required: false })} className="w-full" />
          <button type="button" className="btn m-auto" onClick={Validar}>
            Validar
          </button>
        </div>
      </label>
      {grupoProfessor && (
        <div>{grupoProfessor.tipo === 1 ? <>Grupo: {grupoProfessor.nomeGrupo}</> : <>Professor:{grupoProfessor.nomeProfessor}</>}</div>
      )}
      <button type="submit" className="btn mt-5 mb-20">
        Gravar
      </button>
    </form>
  );
};

export default Perfil;
