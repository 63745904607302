import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { ProgressBar } from './ProgressBar';
import dayjs from 'dayjs';
import { HabitosList } from './HabitosList';
import { useEffect, useState } from 'react';

interface HabitDayProps {
  date: dayjs.Dayjs;
  defaultCompleted?: number;
  amount?: number;
  disabled: boolean;
}

export function HabitDay({ defaultCompleted = 0, amount = 0, date, disabled }: HabitDayProps) {
  const [completed, setCompleted] = useState(defaultCompleted);
  const [completedPercentage, setCompletedPercentage] = useState(0);

  // useEffect(() => {
  //   console.log(date.get('date'), amount, defaultCompleted);
  // }, [defaultCompleted]);

  useEffect(() => {
    const percentage = amount > 0 ? Math.round((defaultCompleted / amount) * 100) : 0;
    setCompletedPercentage(percentage);
  }, [defaultCompleted, amount]);

  useEffect(() => {
    const percentage = amount > 0 ? Math.round((completed / amount) * 100) : 0;
    setCompletedPercentage(percentage);
  }, [completed, amount]);

  const dayAndMonth = date.format('DD/MM');
  const dayOfWeek = date.format('dddd');

  function handleCompletedChaged(completed: number) {
    setCompleted(completed);
  }

  return (
    <Popover.Root>
      <Popover.Trigger
        asChild
        className={clsx(
          'w-full h-full aspect-square text-center text-gray-900 cursor-pointer border-2 border-gray-200 rounded-lg transition-colors hover:bg-blue-400 disabled:cursor-default disabled:bg-none disabled:opacity-40',
          {
            'bg-gray-100 border-gray-200 text-gray-900': completedPercentage === 0,
            'bg-blue-900 border-blue-500 text-white': completedPercentage > 0 && completedPercentage < 20,
            'bg-blue-800 border-blue-500 text-white': completedPercentage >= 20 && completedPercentage < 40,
            'bg-blue-700 border-blue-500 text-white': completedPercentage >= 40 && completedPercentage < 60,
            'bg-blue-600 border-blue-500 text-white': completedPercentage >= 60 && completedPercentage < 80,
            'bg-blue-500 border-blue-400 text-white': completedPercentage >= 80,
          }
        )}
      >
        <button
          className="w-full aspect-square text-center text-gray-900 cursor-pointer rounded-sm hover:bg-blue-600 hover:text-white disabled:cursor-not-allowed disabled:bg-none disabled:opacity-40"
          //   onClick={() => alert(date.toDate())}
          disabled={disabled}
        >
          {date.get('date')}
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="min-w-[320px] p-6 rounded-2xl bg-gray-300 flex flex-col z-20">
          <span className="font-semibold text-zinc-900">{dayOfWeek}</span>
          <span className="mt-1 font-extrabold leading-tight text-3xl">{dayAndMonth}</span>
          {/* <ProgressBar progress={completedPercentage} /> */}
          <HabitosList date={date.toDate()} onCompletedChanged={handleCompletedChaged} />
          <Popover.Arrow height={8} width={16} className="fill-zinc-900" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
