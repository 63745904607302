import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { avaliacaoActions } from 'store/Avaliacoes.store';

type Inputs = {
  qesa01: string;
  qesa02: string;
  qesa03: string;
  qesa04: string;
  qesa05: string;
  qesa06: string;
  qesa07: string;
  qesa08: string;
  qesa09: string;
  qesa10: string;
  qesa11: string;
  qesa12: string;
  qesa13: string;
  qesa14: string;
  qesa15: string;
  qesa16: string;
  qesa17: string;
  qesa18: string;
  qesa19: string;
  qesa20: string;
  qesa21: string;
  qesa22: string;
  qesa23: string;
  qesa24: string;
  qesa25: string;
  qesa26: string;
};

const NeuroQ: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const avaliacao = useAppSelector((state) => state.avaliacoes.neuroq.selected);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.neuroqById(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!avaliacao) return;
    reset(avaliacao);
  }, [avaliacao, reset]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await dispatch(avaliacaoActions.addNeuroQ(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Neuro-Q</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset></fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">1. Qual objetivo principal fez você querer praticar atividade física?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa01', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa01 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">2. Que benefícios que você percebe que trará para sua vida?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa02', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa02 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">3. Por quê estes objetivos e benefícios são importantes para você?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa03', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa03 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      4. De 0 a 10 qual o grau de engajamento está para alcançar estes objetivos?
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa04', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa04 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      5. Você já pratica atividade física? No seu lazer, no seu trabalho, na sua casa ou deslocamento de casa para o
                      trabalho e retorno.
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa05', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">5.1. Se sim, o que faz?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa06', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">5.2. Aonde faz?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa07', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">5.2. Quantas vezes na semana?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa08', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">5.4. E quantas horas por dia?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa09', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">6. Se não, já praticou?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa10', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">6.1. Qual atividade?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa11', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">6.2. Há quanto tempo está parada(o)?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa12', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">7. Que tipo de atividade física é mais prazerosa para você praticar?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa13', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa07 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      7.1. Qual(ais) atividade(s) física(s) poderia adicionar no seu lazer, em casa, no seu trabalho ou nos seus
                      deslocamento para trabalho e retorno?
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa14', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa07 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      8. Que locais você tem oportunidade para fazer atividade física? Condomínio, praças, praias, academias, clubes,
                      trabalho e etc.
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa15', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      9. Quais os dias da semana que você poderia praticar atividade física e que só depende de você se organizar?
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa16', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      10. Quanto tempo você consegue priorizar nestes dias para praticar atividade física?
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa17', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">11. Sente alguma dor no corpo? Aonde?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa18', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      12. Possui alguma restrição a movimentos para as atividades da vida diária?
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa19', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      13. Qual a posição predominante no seu trabalho? (Sentado(a), em pé, outra)
                    </p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa20', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">13.1. Quanto tempo por dia fica nesta posição?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa21', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">13.2. Que equipamentos utiliza?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa22', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">14. Sua atividade profissional exige muita concentração?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa23', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">14.1. É estressante?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa24', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">15. Dorme bem?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa25', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">15.1. Acorda disposta?</p>
                    <div className="mt-2">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 focus-visible:outline-blue-500 sm:text-sm sm:leading-6"
                        {...register('qesa26', { required: true })}
                      ></textarea>
                    </div>
                    {errors.qesa08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 w-full"
                  disabled={loading}
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { NeuroQ };
