import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authHeader, history } from 'helpers';
import api from 'services/api';

const initialState: {
  aluno: any;
  error: boolean;
  loading: boolean;
} = {
  aluno: null,
  error: false,
  loading: false,
};

const name = 'perfil';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const alunoSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {},
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    headers: authHeader(),
  };

  return {
    getPerfil: getPerfil(),
    createPerfil: createPerfil(),
    updatePerfil: updatePerfil(),
  };

  function getPerfil() {
    return createAsyncThunk(`${name}/get_perfil`, async () => await api.get(`alunos/me`, requestOptions));
  }

  function createPerfil() {
    return createAsyncThunk(`${name}/create_perfil`, async (data: any, { rejectWithValue }) => {
      try {
        await api.post(`alunos/completar`, data, requestOptions);
        history.navigate('/');
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data.message);
      }
    });
  }

  function updatePerfil() {
    return createAsyncThunk(`${name}/update_perfil`, async (data: any) => await api.put(`alunos/${data.id}`, data, requestOptions));
  }
}

function createExtraReducers() {
  return (builder: any) => {
    getPerfil();
    createPerfil();
    updatePerfil();

    function getPerfil() {
      let { pending, fulfilled, rejected } = extraActions.getPerfil;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.aluno = action.payload.data;
          state.loading = false;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
          state.loading = false;
        });
    }
    function createPerfil() {
      let { pending, fulfilled, rejected } = extraActions.createPerfil;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.loading = false;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
          state.loading = false;
        });
    }
    function updatePerfil() {
      let { pending, fulfilled, rejected } = extraActions.updatePerfil;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.loading = false;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
          state.loading = false;
        });
    }
  };
}

export const actions = { ...alunoSlice.actions, ...extraActions };
export default alunoSlice.reducer;
