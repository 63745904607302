import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface Props {
  currentPage: number;
  pages: number;
  handleGotoPage: React.Dispatch<React.SetStateAction<any>>;
}

const Pagination: React.FC<Props> = ({ currentPage, pages, handleGotoPage }) => {
  const MAX_ITEMS = 9;
  const MAX_LEFT = (MAX_ITEMS - 1) / 2;
  const first = Math.max(currentPage - MAX_LEFT, 1);
  const anterior = Math.max(currentPage - 1, 1);
  return (
    <div className="flex items-center justify-between border-t border-gray-200 py-3">
      <div className="flex flex-1 items-center justify-between bg-white rounded-md">
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm cursor-pointer" aria-label="Pagination">
            <div
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => handleGotoPage(anterior)}
            >
              <span className="sr-only">Anterior</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {Array.from(
              { length: MAX_ITEMS },
              (_, i) =>
                i + first <= pages && (
                  <div
                    key={i}
                    onClick={() => handleGotoPage(i + first)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      i + 1 === currentPage
                        ? `text-white z-10 bg-blue-600`
                        : `text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`
                    }`}
                  >
                    {i + first}
                  </div>
                )
            )}
            <div
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => handleGotoPage(currentPage + 1)}
            >
              <span className="sr-only">Proximo</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
