import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const ItemDirectory: React.FC<{ dir: string; classActive: string }> = ({ dir, classActive }) => {
  const route = useLocation();
  const currentPath = route.pathname;

  return (
    <>
      <li className={`flex items-center pr-4 pl-9 py-2 itemDirectory ${currentPath === '/doc/' + dir.toLowerCase() ? classActive : ''}`}>
        <NavLink
          to={`/doc/${dir.toLowerCase()}`}
          title={dir}
          className="hover:text-green-600 dark:hover:text-slate-200 transition text-ellipsis whitespace-nowrap overflow-hidden max-w-[7rem]"
        >
          {dir}
        </NavLink>
      </li>
    </>
  );
};

export default ItemDirectory;
