import { Action, createAsyncThunk, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { Documento } from '../interfaces';
import { authHeader } from 'helpers';
import api from 'services/api';

const initialState: {
  list: Documento[];
  currentPage: number;
  pages: number;
} = {
  list: [],
  currentPage: 0,
  pages: 0,
};

const name = 'documentos';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const documentosSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    addNew(state, action: PayloadAction<Documento>) {
      state.list = [action.payload, ...state.list];
    },
    remove(state, action) {
      const newList = state.list.filter((item) => item.id !== action.payload);
      state.list = newList;
    },
    markAsImportant(state, action: PayloadAction<string>) {
      const newFavorited = state.list.find((item) => item.id === action.payload);
      newFavorited!.important = !newFavorited!.important;
    },
    edit(state, action: PayloadAction<Documento>) {
      const Id = action.payload.id;
      const newEdited: Documento = state.list.find((item: Documento) => item.id === Id)!;
      const index = state.list.indexOf(newEdited);
      state.list[index] = action.payload;
    },
    deleteAllData(state) {
      state.list = [];
    },
  },
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    // method: 'GET',
    headers: authHeader(),
  };

  return {
    list: getList(),
    nextPage: nextPage(),
  };

  function getList() {
    return createAsyncThunk(`${name}/list`, async () => await api.get(`documentos`, requestOptions));
  }

  function nextPage() {
    return createAsyncThunk(`${name}/list/page`, async ({ categoria, page, text }: any) => {
      const query = text ? `&Text=${text}` : '';
      return await api.get(`documentos?categoria=${categoria}&PageNumber=${page}${query}`, requestOptions);
    });
  }
}

function createExtraReducers() {
  return (builder: any) => {
    getList();
    nextPage();

    function getList() {
      let { pending, fulfilled, rejected } = extraActions.list;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function nextPage() {
      let { pending, fulfilled, rejected } = extraActions.nextPage;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const actions = { ...documentosSlice.actions, ...extraActions };
export default documentosSlice.reducer;

export const aulasMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
  const nextAction = next(action);
  const isADirectoryAction: boolean = action.type.toLowerCase().includes('directory');

  if (action.type.startsWith('documentos/') && !isADirectoryAction) {
    const List = store.getState().documentos.list;
    localStorage.setItem('documentos', JSON.stringify(List));
  }

  if (actions.deleteAllData.match(action)) {
    localStorage.removeItem('documentos');
  }

  if (actions.remove.match(action)) {
    if (localStorage.getItem('documentos')) {
      const _localStorage = JSON.parse(localStorage.getItem('documentos')!);
      if (_localStorage.length === 0) {
        localStorage.removeItem('documentos');
      }
    }
  }
  return nextAction;
};
