import { Action, createAsyncThunk, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { Video } from '../interfaces';
import { authHeader } from 'helpers';
import api from 'services/api';

const initialState: {
  list: Video[];
  currentPage: number;
  pages: number;
  video: Video | null;
} = {
  list: [],
  currentPage: 0,
  pages: 0,
  video: null,
};

const name = 'videos';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const videosSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    addNewVideo(state, action: PayloadAction<Video>) {
      state.list = [action.payload, ...state.list];
    },
    removeVideo(state, action) {
      const newVideoList = state.list.filter((video) => video.id !== action.payload);
      state.list = newVideoList;
    },
    markAsImportant(state, action: PayloadAction<string>) {
      const newVideoFavorited = state.list.find((video) => video.id === action.payload);
      newVideoFavorited!.important = !newVideoFavorited!.important;
    },
    editVideo(state, action: PayloadAction<Video>) {
      const videoId = action.payload.id;
      const newVideoEdited: Video = state.list.find((video: Video) => video.id === videoId)!;
      const indexVideo = state.list.indexOf(newVideoEdited);
      state.list[indexVideo] = action.payload;
    },
    deleteAllData(state) {
      state.list = [];
    },
  },
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    // method: 'GET',
    headers: authHeader(),
  };

  return {
    videos: videos(),
    nextPage: nextPage(),
  };

  function videos() {
    return createAsyncThunk(`${name}/list`, async () => await api.get(`videos`, requestOptions));
  }

  function nextPage() {
    return createAsyncThunk(`${name}/list/page`, async ({ categoria, page, text }: any) => {
      const query = text ? `&Text=${text}` : '';
      return await api.get(`videos?categoria=${categoria}&PageNumber=${page}${query}`, requestOptions);
    });
  }
}

function createExtraReducers() {
  return (builder: any) => {
    videos();
    nextPage();

    function videos() {
      let { pending, fulfilled, rejected } = extraActions.videos;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function nextPage() {
      let { pending, fulfilled, rejected } = extraActions.nextPage;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const videosActions = { ...videosSlice.actions, ...extraActions };
export default videosSlice.reducer;

export const videosMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
  const nextAction = next(action);
  const isADirectoryAction: boolean = action.type.toLowerCase().includes('directory');

  if (action.type.startsWith('videos/') && !isADirectoryAction) {
    const videosList = store.getState().videos.videos;
    localStorage.setItem('videos', JSON.stringify(videosList));
  }

  if (videosActions.deleteAllData.match(action)) {
    localStorage.removeItem('videos');
  }

  if (videosActions.removeVideo.match(action)) {
    if (localStorage.getItem('videos')) {
      const localStorageVideos = JSON.parse(localStorage.getItem('videos')!);
      if (localStorageVideos.length === 0) {
        localStorage.removeItem('videos');
      }
    }
  }
  return nextAction;
};
