import { Routes, Route } from 'react-router-dom';
import Perfil from 'components/Routes/Perfil';
import HeaderTasks from 'components/TasksSection/HeaderTasks';
import AccountData from 'components/AccountSection/AccountData';
import Menu from 'components/Menu/Menu';

function PerfilLayout() {
  //   const auth = useSelector((x: any) => x.auth.value);
  // prevent flicker before redirect if already logged in
  //   if (auth) return null;

  return (
    <>
      <Menu />
      <main className=" pt-5 pb-8 sm:pb-16 px-3 md:px-8 md:w-full xl:w-8/12 m-auto min-h-screen">
        {/* <HeaderTasks /> */}
        <Routes>
          <Route path="/" element={<Perfil />} />
        </Routes>
        <AccountData />
      </main>
    </>
  );
}

export { PerfilLayout };
