export function authHeader() {
  // return authorization header with jwt token
  const storage: any = localStorage.getItem('user');
  if (!storage) return {};

  let user = JSON.parse(storage);

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}
