import React from 'react';
import { useAppSelector } from '../../store/hooks';
import Modal from './Modal';
import { Card } from 'components/Card';

const ModalReview: React.FC<{
  onClose: () => void;
  nameForm: string;
}> = ({ onClose, nameForm }) => {
  return (
    <Modal onClose={onClose} title={nameForm} wfull={false}>
      <Card />
    </Modal>
  );
};

export default ModalReview;
