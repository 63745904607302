import React, { useState } from 'react';
import { Treino } from '../../interfaces';
import useSortTreinos from 'components/hooks/useSortTreinos';
import TreinoItem from 'components/TasksSection/TaskItem/TreinoItem';
import Habitos from 'components/Habitos';
import Checkbox from 'components/Checkbox';

type Props = {
  title: string;
  treinos: Treino[] | [];
  favoritos: boolean;
  setFavoritos: React.Dispatch<React.SetStateAction<any>>;
};

const LayoutTreinos: React.FC<Props> = ({ title, treinos, favoritos, setFavoritos }) => {
  const [isListInView1] = useState<boolean>(false);
  const { sortedTreinos } = useSortTreinos(treinos);
  const Title = `${title} (${treinos.length} ${treinos.length === 1 ? 'atividade' : 'atividades'})`;

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">{Title}</h1>
      {/* <ButtonsSort isListInView1={isListInView1} setIsListInView1={setIsListInView1} sortedBy={sortedBy} setSortedBy={setSortedBy} /> */}
      <Checkbox isChecked={favoritos} setChecked={setFavoritos} label="Favoritos" />
      <ul
        className={`tasksList mt-4 grid gap-2 sm:gap-4 xl:gap-6 ${
          isListInView1 ? 'grid-cols-1' : '2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-end'
        }`}
      >
        {sortedTreinos.map((treino) => (
          <TreinoItem key={treino.id} isListInView1={isListInView1} treino={treino} />
        ))}
      </ul>
    </section>
  );
};

export default React.memo(LayoutTreinos);
