import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { Questionario } from '../../../interfaces';

const ListItem: React.FC<{ item: Questionario }> = ({ item }) => {
  const route = useLocation();
  const currentPath = route.pathname;
  return (
    <>
      <li key={item.id}>
        <Link to={`${currentPath}/${item.id}`}>
          <div
            className={`relative flex rounded-[10px] w-full bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent flex-col h-12`}
          >
            <div className="my-3 ml-3 flex items-center justify-between px-1 md:items-start">
              <p className="text-lg font-bold text-navy-700 truncate"> {`Avaliação ${format(new Date(item.criado), 'dd/MM/yyyy')}`}</p>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
};

export default React.memo(ListItem);
