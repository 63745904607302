import { useState, useEffect } from 'react';
import { Documento } from '../../interfaces';

const useSortDocumentos = (documentos: Documento[]) => {
  const [sortedBy, setSortedBy] = useState<string>('');

  const [sortedDocumentos, setSortedDocumentos] = useState<Documento[]>(documentos);

  useEffect(() => {
    const sortByDate = (order: 'max-date' | 'min-date'): Documento[] => {
      const toMillisseconds = (date: string) => Date.parse(date);
      const itemsCopy = [...documentos];
      const sorted = itemsCopy.sort((item1, item2) => {
        const date1 = toMillisseconds(item1.insercao);
        const date2 = toMillisseconds(item2.insercao);

        if (date1 < date2) {
          return -1;
        }

        if (date1 > date2) {
          return 1;
        }

        return 0;
      });

      if (order === 'min-date') {
        return sorted;
      }

      if (order === 'max-date') {
        return sorted.reverse();
      }

      return documentos; //se não existir items (para não retornar undefined)
    };

    // const sortByCompletedStatus = (completed: boolean): Video[] => {
    //   const itemsCopy = [...videos];
    //   const sorted = itemsCopy.sort((item1) => {
    //     if (item1.completed) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    //   if (completed) {
    //     return sorted;
    //   }
    //   if (!completed) {
    //     return sorted.reverse();
    //   }
    //   return videos;
    // };

    if (sortedBy === 'min-date' || sortedBy === 'max-date') {
      setSortedDocumentos(sortByDate(sortedBy));
    }
    if (sortedBy === '' || sortedBy === 'order-added') {
      setSortedDocumentos(documentos);
    }
    // if (sortedBy === 'completed-first') {
    //   setSortedDocumentos(sortByCompletedStatus(true));
    // }
    // if (sortedBy === 'uncompleted-first') {
    //   setSortedDocumentos(sortByCompletedStatus(false));
    // }
  }, [sortedBy, documentos]);
  return { sortedBy, setSortedBy, sortedDocumentos };
};

export default useSortDocumentos;
