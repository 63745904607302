import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// import { history } from '../../helpers/history';
import Login from '../../pages/login';
import Register from '../../pages/register';
import Forgot from 'pages/forgot';

function PublicLayout() {
  //   const auth = useSelector((x: any) => x.auth.value);
  // prevent flicker before redirect if already logged in
  //   if (auth) return null;

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset" element={<Forgot />} />
      <Route path="reset/:token" element={<Forgot />} />
    </Routes>
  );
}

export { PublicLayout };
