import React, { useState } from 'react';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import ContentDirectories from './ContentDirectories';

const Tutorial: React.FC<{ classActive: string }> = ({ classActive }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="py-2">
      <button className={`flex items-center w-full mx-4 mb-1 ${isOpen ? 'dark:text-slate-200' : ''}`} onClick={toggleOpen}>
        <Arrow className={`w-3 h-3 mr-2 rotate-90 transition ${isOpen ? 'rotate-180' : ''}`} />
        Tutoriais
      </button>
      <div className={isOpen ? 'visible' : 'hidden'}>
        <ContentDirectories classActive={classActive} />
      </div>
    </div>
  );
};

export default Tutorial;
