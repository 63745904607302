import React from 'react';
import { Link } from 'react-router-dom';

const AvaliacaoMenuIcon: React.FC<any> = ({ nome, icon, link, disable }) => {
  return (
    <li>
      <Link to={link}>
        <div
          className={`relative flex rounded-[20px] w-full !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent flex-col h-auto ${
            disable ? 'cursor-not-allowed	' : 'cursor-pointer'
          }`}
        >
          <div className={`h-full w-full`}>
            <div className={`relative flex flex-col w-full h-40 justify-around items-center text-center`}>
              <div className="w-20 h-20">
                <img src={icon} alt="menu" className={disable ? 'opacity-50' : 'opacity-100'} />
              </div>
              <div>{nome}</div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default AvaliacaoMenuIcon;
