import { NavigateFunction, Location, useNavigation, useLocation } from 'react-router-dom';

interface Ihistory {
  navigate: NavigateFunction;
  location: any;
}

export const history: Ihistory = {
  navigate: useNavigation,
  location: useLocation,
};
