import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const links = [
  {
    name: 'Principal',
    path: '/',
  },
  // {
  //   name: 'Treinos do dia',
  //   path: '/today',
  // },
  // {
  //   name: 'Treinos Importantes',
  //   path: '/important',
  // },
  // {
  //   name: 'Treinos Completos',
  //   path: '/completed',
  // },
  // {
  //   name: 'Treinos Incompletos',
  //   path: '/uncompleted',
  // },
  {
    name: 'Treinos',
    path: '/treinos',
  },
  {
    name: 'Avaliações',
    path: '/avaliacoes',
  },
];

const NavLinks: React.FC<{ classActive: string }> = ({ classActive }) => {
  const route = useLocation();
  const currentPath = route.pathname;
  return (
    <nav>
      <ul className="grid gap-2">
        {links.map((link) => (
          <li key={link.path}>
            <NavLink
              to={link.path}
              className={`px-4 py-2 w-full block transition hover:text-green-600 dark:hover:text-slate-200 font-semibold ${
                currentPath === link.path ? classActive : ''
              }`}
            >
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavLinks;
