import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { avaliacaoActions } from 'store/Avaliacoes.store';

type Inputs = {
  ini01: number;
  ini02: string;
  ini03: number;
  ini04: string;
  ini05: string;
  ini06: number;
  ini07: string;
  ini08: number;
  ini09: string;
  ini10: number;
  ini11: string;
  ini12: number;
  ini13: string;
  ini14: string;
  ini15: string;
  ini16: number;
  ini17: number;
  ini18: number;
  ini19: number;
  ini20: number;
  ini21: number;
  ini22: number;
  ini23: number;
  ini24: number;
};

const Anamnese: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const avaliacao = useAppSelector((state) => state.avaliacoes.anamnese.selected);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.anamneseById(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!avaliacao) return;
    const _data = _.mapValues(avaliacao, (v) => v.toString());
    reset(_data);
  }, [avaliacao, reset]);

  const { ini01, ini03, ini06, ini08, ini10, ini12 } = watch();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await dispatch(avaliacaoActions.addAnamnese(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Anamnese</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">1. Faz algum acompanhamento/tratamento médico?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini01.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini01', { required: true })}
                      />
                      <label htmlFor={'ini01.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini01.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini01', { required: true })}
                      />
                      <label htmlFor={'ini01.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini01 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini01) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">1.1. Nos últimos 12 meses quantas vezes se consultou?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini02.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'1 a 2x'}
                          {...register('ini02', { required: Number(ini01) === 1 })}
                        />
                        <label htmlFor={'ini02.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          1 a 2x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini02.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'3 a 4x'}
                          {...register('ini02', { required: Number(ini01) === 1 })}
                        />
                        <label htmlFor={'ini02.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          3 a 4x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini02.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'5 a 10x'}
                          {...register('ini02', { required: Number(ini01) === 1 })}
                        />
                        <label htmlFor={'ini02.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          5 a 10x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini02.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'+ que 10'}
                          {...register('ini02', { required: Number(ini01) === 1 })}
                        />
                        <label htmlFor={'ini02.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          + que 10
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">2. Faz algum acompanhamento/tratamento psicológico/psiquiátrico?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini03.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini03', { required: true })}
                      />
                      <label htmlFor={'ini03.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini03.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini03', { required: true })}
                      />
                      <label htmlFor={'ini03.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini03 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini03) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">2.1. Se sim, qual o motivo?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini04.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Ansiedade'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Ansiedade
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Depressão'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Depressão
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'TOC'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          TOC
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Fobias'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Fobias
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.5'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Síndrome do Pânico'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.5'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Síndrome do Pânico
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.6'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Vácios'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.6'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Vácios
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini04.7'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Outros Transtornos'}
                          {...register('ini04', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini04.7'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Outros Transtornos
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                {Number(ini03) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">2.2. Nos últimos 12 meses quantas vezes se consultou?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini05.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'1 a 2x'}
                          {...register('ini05', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini05.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          1 a 2x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini05.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'3 a 4x'}
                          {...register('ini05', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini05.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          3 a 4x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini05.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'5 a 10x'}
                          {...register('ini05', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini05.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          5 a 10x
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini05.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'+ que 10'}
                          {...register('ini05', { required: Number(ini03) === 1 })}
                        />
                        <label htmlFor={'ini05.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          + que 10
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">3. Faz algum acompanhamento/tratamento odontológico?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini06.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini06', { required: true })}
                      />
                      <label htmlFor={'ini06.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini06.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini06', { required: true })}
                      />
                      <label htmlFor={'ini06.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini06) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">3.1. Qual foi a última vez que se consultou ao dentista?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini07.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Menos de 6 meses'}
                          {...register('ini07', { required: Number(ini06) === 1 })}
                        />
                        <label htmlFor={'ini07.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Menos de 6 meses
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini07.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'De 6 meses a 1 ano'}
                          {...register('ini07', { required: Number(ini06) === 1 })}
                        />
                        <label htmlFor={'ini07.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          De 6 meses a 1 ano
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini07.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Mais de 1 ano'}
                          {...register('ini07', { required: Number(ini06) === 1 })}
                        />
                        <label htmlFor={'ini07.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Mais de 1 ano
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">4. Faz algum acompanhamento nutricional por recomendação médica?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini08.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini08', { required: true })}
                      />
                      <label htmlFor={'ini08.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini08.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini08', { required: true })}
                      />
                      <label htmlFor={'ini08.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini08) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">4.1. Nos últimos 12 meses quantas vezes se consultou?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini09.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Ansiedade'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Ansiedade
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Depressão'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Depressão
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'TOC'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          TOC
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Fobias'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Fobias
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.5'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Síndrome do Pânico'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.5'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Síndrome do Pânico
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.6'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Vácios'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.6'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Vácios
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini09.7'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Outros Transtornos'}
                          {...register('ini09', { required: Number(ini08) === 1 })}
                        />
                        <label htmlFor={'ini09.7'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Outros Transtornos
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">5. Toma algum tipo de medicamento?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini10.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini10', { required: true })}
                      />
                      <label htmlFor={'ini10.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini10.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini10', { required: true })}
                      />
                      <label htmlFor={'ini10.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini10 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini10) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">5.1. Quem recomendou?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini11.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Médico'}
                          {...register('ini11', { required: Number(ini10) === 1 })}
                        />
                        <label htmlFor={'ini11.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Médico
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini11.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Automedicação'}
                          {...register('ini11', { required: Number(ini10) === 1 })}
                        />
                        <label htmlFor={'ini11.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Automedicação
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini11.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Ambos'}
                          {...register('ini11', { required: Number(ini10) === 1 })}
                        />
                        <label htmlFor={'ini11.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Ambos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini11.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Não Aplicável'}
                          {...register('ini11', { required: Number(ini10) === 1 })}
                        />
                        <label htmlFor={'ini11.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Não Aplicável
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">6. Possui Plano de saúde?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini12.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini12', { required: true })}
                      />
                      <label htmlFor={'ini12.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini12.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini12', { required: true })}
                      />
                      <label htmlFor={'ini12.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini12 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {Number(ini12) === 1 && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">6.1. Se sim, qual?</p>
                      <div className="flex items-center">
                        <input
                          id={'ini13.1'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Amil'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Amil
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.2'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Assim Saúde'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Assim Saúde
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.3'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Bradesco Saúde'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Bradesco Saúde
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.4'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Golden Cross'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Golden Cross
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.5'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Sulamérica'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.5'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Sulamérica
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.6'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Unimed'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.6'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Unimed
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.7'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Outro'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.7'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Outro
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id={'ini13.8'}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Não Aplicável'}
                          {...register('ini13', { required: Number(ini12) === 1 })}
                        />
                        <label htmlFor={'ini13.8'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Não Aplicável
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">7. Como você classifica o seu estado de saúde atual?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini14.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito Bom'}
                        {...register('ini14', { required: true })}
                      />
                      <label htmlFor={'ini14.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito Bom
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini14.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Bom'}
                        {...register('ini14', { required: true })}
                      />
                      <label htmlFor={'ini14.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Bom
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini14.3'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Regular'}
                        {...register('ini14', { required: true })}
                      />
                      <label htmlFor={'ini14.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Regular
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini14.4'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Ruim'}
                        {...register('ini14', { required: true })}
                      />
                      <label htmlFor={'ini14.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Ruim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini14.5'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito Ruim'}
                        {...register('ini14', { required: true })}
                      />
                      <label htmlFor={'ini14.5'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito Ruim
                      </label>
                    </div>
                    {errors.ini14 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">8. Seu pai ou mãe, ou ainda, avó ou avô tiveram alguma dessas doenças?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini15.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Não'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Hipertensão Arterial'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Hipertensão Arterial
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.3'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diabétes'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.3'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diabétes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.4'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Infarto'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.4'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Infarto
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.5'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Acidente Vascular Cerebral (Derrame)'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.5'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Acidente Vascular Cerebral (Derrame)
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.6'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Câncer'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.6'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Câncer
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.7'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Depressão'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.7'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Depressão
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini15.8'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Mais de uma'}
                        {...register('ini15', { required: true })}
                      />
                      <label htmlFor={'ini15.8'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Mais de uma
                      </label>
                    </div>
                    {errors.ini15 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">9. Diabetes?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini16.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini16', { required: true })}
                      />
                      <label htmlFor={'ini16.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini16.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini16', { required: true })}
                      />
                      <label htmlFor={'ini16.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini16 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">10. Hipertensão arterial?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini17.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini17', { required: true })}
                      />
                      <label htmlFor={'ini17.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini17.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini17', { required: true })}
                      />
                      <label htmlFor={'ini17.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini17 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">11. Obesidade?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini18.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini18', { required: true })}
                      />
                      <label htmlFor={'ini18.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini18.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini18', { required: true })}
                      />
                      <label htmlFor={'ini18.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini18 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">12. Doença Respiratória (bronquite, asma, rinite, sinusite ou outra)?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini19.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini19', { required: true })}
                      />
                      <label htmlFor={'ini19.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini19.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini19', { required: true })}
                      />
                      <label htmlFor={'ini19.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini19 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">13. Doença articular (artrite, artrose ou outra)?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini20.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini20', { required: true })}
                      />
                      <label htmlFor={'ini20.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini20.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini20', { required: true })}
                      />
                      <label htmlFor={'ini20.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini20 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">14. Problema de Coluna (costas)?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini21.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini21', { required: true })}
                      />
                      <label htmlFor={'ini21.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini21.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini21', { required: true })}
                      />
                      <label htmlFor={'ini21.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini21 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">15. Tendinite ou LER?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini22.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini22', { required: true })}
                      />
                      <label htmlFor={'ini22.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini22.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini22', { required: true })}
                      />
                      <label htmlFor={'ini22.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini22 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">16. Depressão?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini23.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini23', { required: true })}
                      />
                      <label htmlFor={'ini23.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini23.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini23', { required: true })}
                      />
                      <label htmlFor={'ini23.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini23 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">17. Transtorno de Ansiedade?</p>
                    <div className="flex items-center">
                      <input
                        id={'ini24.1'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={1}
                        {...register('ini24', { required: true })}
                      />
                      <label htmlFor={'ini24.1'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={'ini24.2'}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={0}
                        {...register('ini24', { required: true })}
                      />
                      <label htmlFor={'ini24.2'} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.ini24 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
                  disabled={loading}
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Anamnese };
