import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STAGES } from 'constants/review';
import { authHeader } from 'helpers';
import { Treino } from 'interfaces';
import { toast } from 'react-toastify';
import api from 'services/api';
import { RootState } from 'store';
import { modalActions } from './Modal.store';

type State = {
  treinos: Treino[];
  treino: Treino | undefined;
  exercicios: any[];
  loading: boolean;
  error: any;
  treinoId: number;
  rating: number;
  hover: number;
  stage: string;
  comment: string;
  data: string;
  favoritos: boolean;
};

const initialState: State = {
  treinos: [],
  treino: undefined,
  exercicios: [],
  loading: false,
  error: null,
  treinoId: 0,
  rating: 0,
  hover: 0,
  stage: STAGES.rating,
  comment: '',
  data: '',
  favoritos: false,
};
const name = 'treinos';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    edit(state, action: PayloadAction<Treino>) {
      const treinoId = action.payload.id;
      const newEdited: Treino = state.treinos.find((treino: Treino) => treino.id === treinoId)!;
      const index = state.treinos.indexOf(newEdited);
      state.treinos[index] = action.payload;
    },
    setTreino(state, action: PayloadAction<any>) {
      state.treinoId = action.payload.treinoId;
      state.data = action.payload.data;
    },
    setRating(state, action: PayloadAction<any>) {
      state.rating = action.payload;
    },
    setHover(state, action: PayloadAction<any>) {
      state.hover = action.payload;
    },
    setComment(state, action: PayloadAction<any>) {
      state.comment = action.payload;
    },
    changeStage(state) {
      if (state.stage === STAGES.rating) {
        state.stage = STAGES.feedback;
      } else if (state.stage === STAGES.feedback) {
        state.stage = STAGES.thanks;
      }
    },
    resetStage(state) {
      state.comment = '';
      state.hover = 0;
      state.rating = 0;
      state.stage = STAGES.rating;
    },
    filterFavoritos(state) {
      state.favoritos = !state.favoritos;
    },
    markAsFavorite(state, action: PayloadAction<string>) {
      const newFavorited = state.treinos.find((item) => item.id === action.payload);
      newFavorited!.favorito = !newFavorited!.favorito;
    },
  },
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    // method: 'GET',
    headers: authHeader(),
  };

  return {
    treinos: treinos(),
    treinosAll: treinosAll(),
    exercicios: exercicios(),
    markReview: markReview(),
    markFavorito: markFavorito(),
    getTreino: getTreino(),
    avaliarTreino: avaliarTreino(),
  };

  function treinos() {
    return createAsyncThunk(
      `${name}/list`,
      async () =>
        await api.get(`treinos/today`, {
          headers: authHeader(),
        })
    );
  }

  function treinosAll() {
    return createAsyncThunk(
      `${name}/list_all`,
      async ({ favoritos }: any) => await api.get(`treinos/all?favoritos=${favoritos}`, requestOptions)
    );
  }

  function getTreino() {
    return createAsyncThunk(`${name}/get_treino`, async (id: number) => await api.get(`treinos/${id}`, requestOptions));
  }

  function exercicios() {
    return createAsyncThunk(`${name}/exercicios_list`, async () => await api.get(`exercicios/all`, requestOptions));
  }

  function markReview() {
    return createAsyncThunk(`${name}/review`, async (data: any) => await api.post(`atestados`, data, requestOptions));
  }

  function markFavorito() {
    return createAsyncThunk(
      `${name}/favoritar`,
      async (id: number) => await api.post(`treinos/favoritar/${id}`, { id: id }, requestOptions)
    );
  }

  function avaliarTreino() {
    return createAsyncThunk(`${name}/avaliar`, async (_, { getState, dispatch }) => {
      const state = getState() as RootState;
      const _data = {
        treino: state.treinos.treinoId,
        aluno: 0,
        nota: state.treinos.rating,
        comentario: state.treinos.comment,
        data: state.treinos.data,
      };
      await api.post(`treinos/review`, _data, requestOptions).catch((error: any) => {
        console.log(error);
        toast.error(error.response.data.message || error.message);
        throw error.message;
      });
      dispatch(modalActions.closeModalReview());
      toast.success('Salvo com sucesso!');
    });
  }
}

function createExtraReducers() {
  return (builder: any) => {
    treinos();
    treinosAll();
    markReview();
    markFavorito();
    exercicios();
    getTreino();
    avaliarTreino();

    function treinos() {
      let { pending, fulfilled, rejected } = extraActions.treinos;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.treinos = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function treinosAll() {
      let { pending, fulfilled, rejected } = extraActions.treinosAll;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.treinos = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function exercicios() {
      let { pending, fulfilled, rejected } = extraActions.exercicios;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.exercicios = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function markReview() {
      let { pending, fulfilled, rejected } = extraActions.markReview;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          // const newTreinoReview = state.treinos.find((item: any) => item.id === action.payload.id);
          // newTreinoReview!.review = newTreinoReview!.review === action.payload.value ? 0 : action.payload.value;
          const newTreinoReview = state.treinos.find((item: any) => item.id === 2);
          newTreinoReview!.review = 3;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function markFavorito() {
      let { pending, fulfilled, rejected } = extraActions.markFavorito;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          const newEdited: Treino = state.treinos.find((item: Treino) => item.id === action.payload.data);
          const index = state.treinos.indexOf(newEdited);
          state.treinos[index] = { ...newEdited, favorito: !newEdited.favorito };
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function getTreino() {
      let { pending, fulfilled, rejected } = extraActions.getTreino;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.treino = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function avaliarTreino() {
      let { pending, fulfilled, rejected } = extraActions.avaliarTreino;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any) => {
          state.stage = STAGES.rating;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const treinoActions = { ...slice.actions, ...extraActions };
export default slice.reducer;
