import React from 'react';
import { Documento } from '../../../interfaces';
import { Link } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

const DocumentoItem: React.FC<{ isListInView1: boolean; item: Documento }> = ({ isListInView1, item }) => {
  return (
    <>
      <li key={item.id}>
        <Dialog.Root>
          <Link
            to={`/documentos/dir/${item.tipoDesc.toLowerCase()}`}
            title={item.tipoDesc.toLowerCase()}
            className="ml-auto mr-4 w-min whitespace-nowrap overflow-hidden max-w-[10rem] text-center text-ellipsis bg-green-200 text-green-600 px-4 py-1 rounded-t-md transition dark:bg-slate-700 dark:text-slate-200 block hover:bg-green-300 dark:hover:bg-green-500"
          >
            {item.tipoDesc}
          </Link>

          {/* {false && (
          <article
            className={`bg-slate-100 rounded-lg p-3 sm:p-4 flex text-left transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent ${
              isListInView1 ? 'flex-row sm:h-32' : 'flex-col h-52 sm:h-64'
            }`}
          ></article>
        )} */}
          <div
            className={`relative flex rounded-[20px] w-full !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent ${
              isListInView1 ? 'flex-row sm:h-32' : 'flex-col h-auto'
            }`}
          >
            <div className={`h-full w-full ${isListInView1 ? 'flex' : ''}`}>
              <Dialog.Trigger asChild>
                <div className={`relative w-full ${isListInView1 ? 'h-full' : 'h-52'}`}>
                  <div
                    style={{
                      backgroundImage: `url(https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/Nft3.3b3e6a4b3ada7618de6c.png)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                    className={`mb-3 h-full w-full rounded-xl`}
                  />
                </div>
              </Dialog.Trigger>
              <div className="my-3 flex items-center justify-between px-1 md:items-start">
                <div className="mb-2">
                  <p className="text-lg font-bold text-navy-700 truncate"> {item.nome} </p>
                  <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">Por Atitudevs </p>
                </div>
              </div>
            </div>
          </div>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[850px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none overflow-auto">
              <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">{item.nome}</Dialog.Title>
              <div>
                {item.tipo === 3 && (
                  <audio controls className="w-full mt-5">
                    <source src={firebaseUrl(item)} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                )}
                {item.tipo === 2 && <iframe className="w-full h-96" src={decoreUrl(item.url)} title={item.nome}></iframe>}
                {item.tipo === 1 && (item.url.includes('jpg') || item.url.includes('png')) && (
                  <img src={firebaseUrl(item)} alt="Preview" className="h-full" />
                )}

                {item.tipo === 1 && item.url.includes('pdf') && (
                  <object data={firebaseUrl(item)} width={600} height={600} style={{ margin: 'auto' }}>
                    pdf
                  </object>
                )}
              </div>
              <Dialog.Close asChild>
                <button
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </li>
    </>
  );
};

export default React.memo(DocumentoItem);

const firebaseUrl = (documento: any) => {
  if (documento.tipo === 1) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/documentos%2F${documento.url}?alt=media`;
  }
  if (documento.tipo === 3) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/audios%2F${documento.url}?alt=media`;
  }
};

const decoreUrl = (url: string) => {
  let _url_fix = '';
  if (url.includes('vimeo')) {
    const urlArray = url.split('/')[3];
    _url_fix = `https://player.vimeo.com/video/${urlArray}?title=0&byline=0&portrait=0`;
  } else {
    if (url.split('/')[2] === 'youtu.be') {
      _url_fix = `https://www.youtube.com/embed/${url.split('/')[3]}`;
    } else {
      _url_fix = url.replace('watch?v=', 'embed/');
    }
  }
  return _url_fix;
};
