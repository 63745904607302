import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authHeader } from 'helpers';
import { Avaliacao } from 'interfaces';
import api from 'services/api';

type State = {
  liberado: number;
  perguntas: any[];
  loading: boolean;
  error: any;
  atestadoOk: boolean;
  objetivos: Avaliacao;
  parq: Avaliacao;
  anamnese: Avaliacao;
  radar: Avaliacao;
  imc: Avaliacao;
  neuroq: Avaliacao;
  antropometria: Avaliacao;
  resumo: any;
};
const AvaliacaoDefault = {
  list: [],
  currentPage: 0,
  pages: 0,
  selected: null,
};

const initialState: State = {
  liberado: 99,
  perguntas: [],
  loading: false,
  error: null,
  atestadoOk: false,
  objetivos: AvaliacaoDefault,
  parq: AvaliacaoDefault,
  anamnese: AvaliacaoDefault,
  radar: AvaliacaoDefault,
  imc: AvaliacaoDefault,
  neuroq: AvaliacaoDefault,
  antropometria: AvaliacaoDefault,
  resumo: null,
};
const name = 'avaliacao';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    checkStatus(state) {
      state.liberado = 7;
    },
    addObjetivo(state, action: PayloadAction<any>) {
      state.liberado = action.payload;
    },
  },
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return {
    objetivos: objetivos(),
    objetivosByAluno: objetivosByAluno(),
    nextPageObjetivos: nextPageObjetivos(),
    objetivosById: objetivosById(),
    addObjetivos: addObjetivos(),
    addParq: addParq(),
    parqByAluno: parqByAluno(),
    nextPageParq: nextPageParq(),
    parqById: parqById(),
    anamneseByAluno: anamneseByAluno(),
    nextPageAnamnese: nextPageAnamnese(),
    anamneseById: anamneseById(),
    radarByAluno: radarByAluno(),
    nextPageRadar: nextPageRadar(),
    radarById: radarById(),
    neuroqByAluno: neuroqByAluno(),
    nextPageNeuroq: nextPageNeuroq(),
    neuroqById: neuroqById(),
    antropometriaByAluno: antropometriaByAluno(),
    nextPageAntropometria: nextPageAntropometria(),
    antropometriaById: antropometriaById(),
    addAtestado: addAtestado(),
    addAnamnese: addAnamnese(),
    addRadar: addRadar(),
    addNeuroQ: addNeuroQ(),
    addIMC: addIMC(),
    addAntropometria: addAntropometria(),
    checkAtestado: checkAtestado(),
    getResumo: getResumo(),
  };

  function objetivos() {
    return createAsyncThunk(`${name}/perguntas`, async () => await api.get(`objetivos`, requestOptions));
  }

  function objetivosByAluno() {
    return createAsyncThunk(`${name}/objetivos_by_aluno`, async () => await api.get(`avaliacoes/aluno`, requestOptions));
  }

  function nextPageObjetivos() {
    return createAsyncThunk(
      `${name}/objetivos_next_page`,
      async (page: number) => await api.get(`avaliacoes/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function objetivosById() {
    return createAsyncThunk(
      `${name}/objetivos_by_id`,
      async (id: number) => await api.get(`objetivosalunos/avaliacao/${id}`, requestOptions)
    );
  }

  function parqByAluno() {
    return createAsyncThunk(`${name}/parqs_by_aluno`, async () => await api.get(`parqs/aluno`, requestOptions));
  }

  function nextPageParq() {
    return createAsyncThunk(
      `${name}/parq_next_page`,
      async (page: number) => await api.get(`parqs/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function parqById() {
    return createAsyncThunk(`${name}/parq_by_id`, async (id: number) => await api.get(`parqs/${id}`, requestOptions));
  }

  function anamneseByAluno() {
    return createAsyncThunk(`${name}/anamnese_by_aluno`, async () => await api.get(`anamneses/aluno`, requestOptions));
  }

  function nextPageAnamnese() {
    return createAsyncThunk(
      `${name}/anamnese_next_page`,
      async (page: number) => await api.get(`anamnese/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function anamneseById() {
    return createAsyncThunk(`${name}/anamnese_by_id`, async (id: number) => await api.get(`anamneses/${id}`, requestOptions));
  }

  function radarByAluno() {
    return createAsyncThunk(`${name}/radar_by_aluno`, async () => await api.get(`radar/aluno`, requestOptions));
  }

  function nextPageRadar() {
    return createAsyncThunk(
      `${name}/radar_next_page`,
      async (page: number) => await api.get(`radar/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function radarById() {
    return createAsyncThunk(`${name}/radar_by_id`, async (id: number) => await api.get(`radar/${id}`, requestOptions));
  }

  function neuroqByAluno() {
    return createAsyncThunk(`${name}/neuroq_by_aluno`, async () => await api.get(`neuroq/aluno`, requestOptions));
  }

  function nextPageNeuroq() {
    return createAsyncThunk(
      `${name}/neuroq_next_page`,
      async (page: number) => await api.get(`neuroq/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function neuroqById() {
    return createAsyncThunk(`${name}/neuroq_by_id`, async (id: number) => await api.get(`neuroq/${id}`, requestOptions));
  }

  function antropometriaByAluno() {
    return createAsyncThunk(`${name}/antropometria_by_aluno`, async () => await api.get(`antropometrias/aluno`, requestOptions));
  }

  function nextPageAntropometria() {
    return createAsyncThunk(
      `${name}/antropometria_next_page`,
      async (page: number) => await api.get(`antropometrias/aluno?PageNumber=${page}`, requestOptions)
    );
  }

  function antropometriaById() {
    return createAsyncThunk(`${name}/antropometria_by_id`, async (id: number) => await api.get(`antropometrias/${id}`, requestOptions));
  }

  function addObjetivos() {
    return createAsyncThunk(`${name}/objetivos_add`, async (data: any) => await api.post(`objetivosalunos`, data, requestOptions));
  }
  function addParq() {
    return createAsyncThunk(`${name}/parq_add`, async (data: any) => await api.post(`parqs`, data, requestOptions));
  }
  function addAtestado() {
    return createAsyncThunk(`${name}/atestado_add`, async (data: any) => await api.post(`atestados`, data, requestOptions));
  }
  function addAnamnese() {
    return createAsyncThunk(`${name}/anamnese_add`, async (data: any) => await api.post(`anamneses`, data, requestOptions));
  }
  function addRadar() {
    return createAsyncThunk(`${name}/radar_add`, async (data: any) => await api.post(`radar`, data, requestOptions));
  }
  function addNeuroQ() {
    return createAsyncThunk(`${name}/neuroq_add`, async (data: any) => await api.post(`neuroq`, data, requestOptions));
  }
  function addIMC() {
    return createAsyncThunk(`${name}/imc_add`, async (data: any) => await api.post(`imc`, data, requestOptions));
  }

  function addAntropometria() {
    return createAsyncThunk(`${name}/antropometria_add`, async (data: any) => await api.post(`antropometrias`, data, requestOptions));
  }

  function checkAtestado() {
    return createAsyncThunk(`${name}/atestado_check`, async () => await api.get(`atestados/check`, requestOptions));
  }

  function getResumo() {
    return createAsyncThunk(`${name}/get_resumo`, async () => await api.get(`dashboard/resumo`, requestOptions));
  }
}

function createExtraReducers() {
  return (builder: any) => {
    objetivos();
    addObjetivo();
    checkAtestado();
    objetivosByAluno();
    objetivosById();
    parqByAluno();
    parqById();
    anamneseByAluno();
    anamneseById();
    radarByAluno();
    radarById();
    neuroqByAluno();
    neuroqById();
    antropometriaByAluno();
    antropometriaById();
    getResumo();

    function objetivos() {
      let { pending, fulfilled, rejected } = extraActions.objetivos;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.perguntas = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function addObjetivo() {
      let { pending, fulfilled, rejected } = extraActions.addObjetivos;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {})
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function checkAtestado() {
      let { pending, fulfilled, rejected } = extraActions.checkAtestado;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.atestadoOk = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function objetivosByAluno() {
      let { pending, fulfilled, rejected } = extraActions.objetivosByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.objetivos.list = action.payload.data.list;
          state.objetivos.currentPage = action.payload.data.currentPage;
          state.objetivos.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function objetivosById() {
      let { pending, fulfilled, rejected } = extraActions.objetivosById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.objetivos.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function parqByAluno() {
      let { pending, fulfilled, rejected } = extraActions.parqByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.parq.list = action.payload.data.list;
          state.parq.currentPage = action.payload.data.currentPage;
          state.parq.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function parqById() {
      let { pending, fulfilled, rejected } = extraActions.parqById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.parq.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function anamneseByAluno() {
      let { pending, fulfilled, rejected } = extraActions.anamneseByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.anamnese.list = action.payload.data.list;
          state.anamnese.currentPage = action.payload.data.currentPage;
          state.anamnese.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function anamneseById() {
      let { pending, fulfilled, rejected } = extraActions.anamneseById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.anamnese.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function radarByAluno() {
      let { pending, fulfilled, rejected } = extraActions.radarByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.radar.list = action.payload.data.list;
          state.radar.currentPage = action.payload.data.currentPage;
          state.radar.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function radarById() {
      let { pending, fulfilled, rejected } = extraActions.radarById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.radar.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function neuroqByAluno() {
      let { pending, fulfilled, rejected } = extraActions.neuroqByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.neuroq.list = action.payload.data.list;
          state.neuroq.currentPage = action.payload.data.currentPage;
          state.neuroq.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function neuroqById() {
      let { pending, fulfilled, rejected } = extraActions.neuroqById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.neuroq.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function antropometriaByAluno() {
      let { pending, fulfilled, rejected } = extraActions.antropometriaByAluno;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.antropometria.list = action.payload.data.list;
          state.antropometria.currentPage = action.payload.data.currentPage;
          state.antropometria.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function antropometriaById() {
      let { pending, fulfilled, rejected } = extraActions.antropometriaById;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.antropometria.selected = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function getResumo() {
      let { pending, fulfilled, rejected } = extraActions.getResumo;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.resumo = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const avaliacaoActions = { ...slice.actions, ...extraActions };
export default slice.reducer;
