import React from 'react';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import avatar from '../../assets/avatar.svg';
import SearchField from './SearchField';
import { useAppDispatch } from '../../store/hooks';
import { menusActions } from '../../store/Menu.store';
import Notification from './Notification';
import Resumo from 'components/Resumo';

const HeaderTasks: React.FC = () => {
  const dispatch = useAppDispatch();

  const date: Date = new Date();
  const year: number = date.getFullYear();
  const month: number = date.getMonth();
  const day: number = date.getDate();

  const monthName: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const todayDate = `${day.toString().padStart(2, '0')} ${monthName[month].slice(0, 3)}, ${year}`;

  const dateTimeFormat = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}}`;

  const openMenuHeaderHandler = () => {
    dispatch(menusActions.openMenuHeader());
  };
  const openMenuAccountHandler = () => {
    dispatch(menusActions.openMenuAccount());
  };

  return (
    <header className="items-center grid grid-cols-[1fr_auto_1fr] gap-4 md:gap-0 md:flex ">
      <button className="mr-20 block xl:hidden" onClick={openMenuHeaderHandler} title="open menu">
        <MenuIcon />
      </button>
      <SearchField />
      <div className="text-center">
        <span className="text-slate-600 dark:text-slate-200 uppercase font-bold text-sm block xl:hidden">Atitudevs</span>
        <time dateTime={dateTimeFormat}>{todayDate}</time>
      </div>
      <div className="flex flex-1 justify-end">
        {/* <Notification /> */}
        <button onClick={openMenuAccountHandler} className="block xl:hidden">
          <img src={avatar} alt="avatar" className="w-10 h-10 rounded-full ml-4" />
        </button>
      </div>
    </header>
  );
};

export default HeaderTasks;
