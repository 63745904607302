import { useState, useEffect } from 'react';
import { Treino } from '../../interfaces';

const useSortTreinos = (treinos: Treino[]) => {
  const [sortedBy, setSortedBy] = useState<string>('');

  const [sortedTreinos, setSortedTreinos] = useState<Treino[]>(treinos);

  useEffect(() => {
    const sortByDate = (order: 'max-date' | 'min-date'): Treino[] => {
      const toMillisseconds = (date: string) => Date.parse(date);
      const treinosCopy = [...treinos];
      const sorted = treinosCopy.sort((task1, task2) => {
        const date1 = toMillisseconds(task1.dataFinal);
        const date2 = toMillisseconds(task2.dataFinal);

        if (date1 < date2) {
          return -1;
        }

        if (date1 > date2) {
          return 1;
        }

        return 0;
      });

      if (order === 'min-date') {
        return sorted;
      }

      if (order === 'max-date') {
        return sorted.reverse();
      }

      return treinos; //se não existir treinos (para não retornar undefined)
    };

    const sortByCompletedStatus = (completed: boolean): Treino[] => {
      const treinosCopy = [...treinos];
      const sorted = treinosCopy.sort((task1) => {
        if (task1.completed) {
          return -1;
        }
        return 0;
      });
      if (completed) {
        return sorted;
      }
      if (!completed) {
        return sorted.reverse();
      }
      return treinos;
    };

    if (sortedBy === 'min-date' || sortedBy === 'max-date') {
      setSortedTreinos(sortByDate(sortedBy));
    }
    if (sortedBy === '' || sortedBy === 'order-added') {
      setSortedTreinos(treinos);
    }
    if (sortedBy === 'completed-first') {
      setSortedTreinos(sortByCompletedStatus(true));
    }
    if (sortedBy === 'uncompleted-first') {
      setSortedTreinos(sortByCompletedStatus(false));
    }
  }, [sortedBy, treinos]);
  return { sortedBy, setSortedBy, sortedTreinos };
};

export default useSortTreinos;
