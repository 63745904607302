import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authHeader, history } from 'helpers';
import api from 'services/api';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
  const user: any = localStorage.getItem('user');
  return {
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(user),
    error: null,
    authenticate: localStorage.getItem('user') ? true : false,
    liberado: 99,
    completed: false,
    loading: false,
  };
}

function createReducers() {
  return {
    logout,
  };

  function logout(state: any) {
    state.user = null;
    state.authenticate = false;
    localStorage.removeItem('user');
    history.navigate('/login');
  }
}

function createExtraActions() {
  //   const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return {
    login: login(),
    forgot: forgot(),
    reset: reset(),
    check: check(),
    register: register(),
    createPerfil: createPerfil(),
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ email, password }: any) =>
        await api.post(`account/authenticatealuno`, { email, password }).catch((error: any) => {
          console.log(error);
          toast.error(error.response.data.message || error.message);
          throw error.message;
        })
    );
  }

  function forgot() {
    return createAsyncThunk(`${name}/forgot`, async ({ email }: any) => await api.post(`account/forgot-password`, { email }));
  }

  function reset() {
    return createAsyncThunk(`${name}/reset`, async ({ token, password, confirmPassword }: any, { rejectWithValue }) => {
      try {
        const response = await api.post(`account/reset-password`, { token, password, confirmPassword });
        return response;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data.message);
      }
    });
  }

  function check() {
    return createAsyncThunk(
      `${name}/check`,
      async ({ id }: any) =>
        await api.get(`account/check/${id}`, {
          headers: authHeader(),
        })
    );
  }

  function register() {
    return createAsyncThunk(`${name}/register`, async (data: any, { rejectWithValue }) => {
      try {
        await api.post('account/aluno', data);
        history.navigate('/login');
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data.message);
      }
    });
  }

  function createPerfil() {
    return createAsyncThunk(`${name}/create_perfil`, async (data: any) => await api.post(`alunos/completar`, data, requestOptions));
  }
}

function createExtraReducers() {
  return (builder: any) => {
    login();
    check();
    forgot();
    reset();
    createPerfil();

    function login() {
      let { pending, fulfilled, rejected } = extraActions.login;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          const user = action.payload.data;
          // store user details and basic auth data in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
          state.authenticate = true;
          state.liberado = user.liberado;
          state.completed = user.completed;
          state.loading = false;
          history.navigate('/');
          // get return url from location state or default to home page
          // const { from } = history.location.state || { from: { pathname: '/' } };
          // history.navigate(from);
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
          state.loading = false;
        });
    }

    function check() {
      var { pending, fulfilled, rejected } = extraActions.check;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          const user = action.payload.data;
          state.liberado = user.liberado;
          state.completed = user.completed;
          switch (action.payload.data.liberado) {
            case 0:
              history.navigate('/questionario/objetivos');
              break;
            case 1:
              history.navigate('/questionario/parq');
              break;
            case 2:
              history.navigate('/questionario/atestado');
              break;
            case 3:
              history.navigate('/questionario/anamnese');
              break;
            case 4:
              history.navigate('/questionario/radar');
              break;
            case 5:
              history.navigate('/questionario/neuroq');
              break;
            case 6:
              history.navigate('/questionario/antropometria');
              break;
            default:
              history.navigate('/');
          }
        })
        .addCase(rejected, (state: any, action: any) => {
          toast.dismiss();
          toast.error(`${action.payload} 🤯 😣`);
          state.error = action.payload;
        });
    }

    function forgot() {
      var { pending, fulfilled, rejected } = extraActions.forgot;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          toast.dismiss();
          toast.success(`${action.payload.data.message}`);
          state.error = null;
          history.navigate('/');
        })
        .addCase(rejected, (state: any, action: any) => {
          toast.dismiss();
          toast.error(`${action.payload} 🤯 😣`);
          state.error = action.payload;
        });
    }

    function reset() {
      var { pending, fulfilled, rejected } = extraActions.reset;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          toast.dismiss();
          toast.success(`${action.payload.data.message}`);
          state.error = null;
          history.navigate('/');
        })
        .addCase(rejected, (state: any, action: any) => {
          toast.dismiss();
          toast.error(`${action.payload} 🤯 😣`);
          state.error = action.payload;
        });
    }

    function createPerfil() {
      let { pending, fulfilled, rejected } = extraActions.createPerfil;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          const user = action.payload.data;
          // store user details and basic auth data in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
          state.authenticate = true;
          state.liberado = user.liberado;
          state.completed = true;
          state.loading = false;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
          state.loading = false;
        });
    }
  };
}
