import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { menusActions } from '../../store/Menu.store';
import NavLinks from './NavLinks';
import LayoutMenus from '../Utilities/LayoutMenus';
import Documents from './Documents/Document';
import logo from '../../assets/atitudevs.png';
import Tutorial from './Tutoriais/Tutorial';
import Aula from './Aulas/Aula';

const classLinkActive =
  'text-green-600 bg-blue-100 border-r-4 border-green-500 dark:bg-slate-700/[.2] dark:text-slate-200 dark:border-slate-200';

const Menu: React.FC = () => {
  const menuOpen = useAppSelector((state) => state.menu.menuHeaderOpened);
  const { role } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const closeMenuHandler = () => {
    dispatch(menusActions.closeMenuHeader());
  };
  return (
    <LayoutMenus menuOpen={menuOpen} closeMenuHandler={closeMenuHandler} className="left-0">
      <header className="h-full flex flex-col">
        <div className="w-36 xl:w-52 font-bold uppercase text-center mx-auto text-lg tracking-wide xl:block">
          <img src={logo} alt="" />
        </div>
        {/* <BtnAddHabit className="my-8 mx-4" /> */}
        <NavLinks classActive={classLinkActive} />
        {/* {process.env.NODE_ENV === 'development' && <Directories classActive={classLinkActive} />} */}
        {role === 'SuperAluno' && <Aula classActive={classLinkActive} />}
        <Documents classActive={classLinkActive} />
        <Tutorial classActive={classLinkActive} />
      </header>
    </LayoutMenus>
  );
};

export default Menu;
