import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Inputs = {
  pq01: string;
  pq02: string;
  pq03: string;
  pq04: string;
  pq05: string;
  pq06: string;
  pq07: string;
  pq08: string;
  pq09: string;
  pq10: string;
  veracidade: boolean;
  ciente: boolean;
};

const Parq: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const avaliacao = useAppSelector((state) => state.avaliacoes.parq.selected);
  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.parqById(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!avaliacao) return;
    reset(avaliacao);
  }, [avaliacao, reset]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await dispatch(avaliacaoActions.addParq(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Par-q</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <p className="text-sm text-gray-500 mb-5">
                    Este questionário tem por objetivo identificar a necessidade de avaliação por um médico antes do início ou do aumento de
                    nível da atividade física. Por favor, assinale "sim" ou "não" às seguintes perguntas:
                  </p>
                  <p className="text-sm text-gray-500 mb-5">Instrumento de acordo com a lei 6765/2014</p>
                  <p className="text-sm text-gray-500 mb-5">
                    Conforme Lei no. 6765/2014, a apresentação de atestado médico ou qualquer exame de aptidão física é obrigatório caso
                    alguma resposta seja positiva em relação a alguma pergunta do questionário de prontidão para a atividade física (Par-q).
                  </p>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      1. Algum médico já disse que você possui algum problema de coração ou pressão arterial, e que somente deveria realizar
                      atividade física supervisionado por profissionais de saúde?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq01S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq01', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq01S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq01N"
                        value={'Não'}
                        type="radio"
                        {...register('pq01', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq01N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq01 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">2. Você sente dores no peito quando pratica atividade física?</p>
                    <div className="flex items-center">
                      <input
                        id="pq02S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq02', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq02S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq02N"
                        value={'Não'}
                        type="radio"
                        {...register('pq02', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq02N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq02 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">3. No último mês, você sentiu dores no peito ao praticar atividade física?</p>
                    <div className="flex items-center">
                      <input
                        id="pq03S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq03', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq03S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq03N"
                        value={'Não'}
                        type="radio"
                        {...register('pq03', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq03N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq03 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      4. Você apresenta algum desequilíbrio devido à tontura e/ou perda momentânea da consciência?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq04S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq04', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq04S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq04N"
                        value={'Não'}
                        type="radio"
                        {...register('pq04', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq04N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq04 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      5. Você possui algum problema ósseo ou articular, que pode ser afetado ou agravado pela atividade física?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq05S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq05', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq05S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq05N"
                        value={'Não'}
                        type="radio"
                        {...register('pq05', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq05N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">6. Você toma atualmente algum tipo de medicação de uso contínuo?</p>
                    <div className="flex items-center">
                      <input
                        id="pq06S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq06', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq06S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq06N"
                        value={'Não'}
                        type="radio"
                        {...register('pq06', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq06N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      7. Você realiza algum tipo de tratamento médico para pressão arterial ou problemas cardíacos?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq07S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq07', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq07S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq07N"
                        value={'Não'}
                        type="radio"
                        {...register('pq07', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq07N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq07 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      8. Você realiza algum tratamento médico contínuo, que possa ser afetado ou prejudicado com a atividade física?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq08S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq08', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq08S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq08N"
                        value={'Não'}
                        type="radio"
                        {...register('pq08', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq08N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      9. Você já se submeteu a algum tipo de cirurgia, que comprometa de alguma forma a atividade física?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq09S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq09', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq09S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq09N"
                        value={'Não'}
                        type="radio"
                        {...register('pq09', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq09N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq09 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      10. Sabe de alguma outra razão pela qual a atividade física possa eventualmente comprometer sua saúde?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="pq10S"
                        value={'Sim'}
                        type="radio"
                        {...register('pq10', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq10S" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="pq10N"
                        value={'Não'}
                        type="radio"
                        {...register('pq10', { required: true })}
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label htmlFor="pq10N" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.pq10 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-6 space-y-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          type="checkbox"
                          {...register('veracidade', { required: true })}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          Declaro que estou ciente de que é recomendável conversar com um médico, antes de iniciar ou aumentar o nível de
                          atividade física pretendido, assumindo plena responsabilidade pela realização de qualquer atividade física sem o
                          atendimento desta recomendação.
                        </label>
                      </div>
                    </div>
                    {errors.veracidade && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className={`justify-center inline-flex items-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 w-full ${
                    loading ? 'transition ease-in-out duration-150 cursor-not-allowed' : ''
                  }`}
                >
                  {loading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {loading ? 'Gravando' : 'Gravar'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Parq };
