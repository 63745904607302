import React, { useState } from 'react';
import { Documento } from '../../interfaces';
import ButtonsSort from '../TasksSection/ButtonsSort';
import useSortDocumentos from 'components/hooks/useSortDocumentos';
import DocumentoItem from 'components/TasksSection/TaskItem/DocumentoItem';
import Pagination from 'components/Pagination';

type Props = {
  title: string;
  documentos: Documento[] | [];
  currentPage: number;
  pages: number;
  setPage: React.Dispatch<React.SetStateAction<any>>;
  setText: React.Dispatch<React.SetStateAction<any>>;
};

const LayoutDocuments: React.FC<Props> = ({ title, documentos, currentPage, pages, setPage, setText }) => {
  const [isListInView1, setIsListInView1] = useState<boolean>(false);
  const { sortedBy, setSortedBy, sortedDocumentos } = useSortDocumentos(documentos);
  const Title = `${title} (${documentos.length} ${documentos.length === 1 ? 'atividade' : 'atividades'})`;

  const changeFilter = (e: any) => {
    if (e.key === 'Enter') {
      setText(e.target.value);
    }
  };

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">{Title}</h1>
      <ButtonsSort isListInView1={isListInView1} setIsListInView1={setIsListInView1} sortedBy={sortedBy} setSortedBy={setSortedBy} />
      <input type="text" id="text" className="inputStyles w-full mt-2" onKeyDown={changeFilter} />
      <ul
        className={`tasksList mt-4 grid gap-2 sm:gap-4 xl:gap-6 ${
          isListInView1 ? 'grid-cols-1' : '2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-end'
        }`}
      >
        {sortedDocumentos.map((documento) => (
          <DocumentoItem key={documento.id} isListInView1={isListInView1} item={documento} />
        ))}
      </ul>
      <Pagination currentPage={currentPage} pages={pages} handleGotoPage={setPage} />
    </section>
  );
};

export default React.memo(LayoutDocuments);
