import { useAppDispatch } from 'store/hooks';
import { Star } from '../Star';
import { treinoActions } from 'store/Treino.store';

export const StarRating = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex gap-2">
      {[...Array(5)].map((_star, index) => {
        const currentRating = index + 1;
        return <Star key={index} currentRating={currentRating} onClick={() => dispatch(treinoActions.setRating(currentRating))} />;
      })}
    </div>
  );
};
