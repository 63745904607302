import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import LayoutVideos from '../Utilities/LayoutVideos';
import { videosActions } from 'store/Videos.store';

const Videos: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { list, currentPage, pages } = useAppSelector((state) => state.videos);
  const [page, setPage] = useState(1);
  const [text, setText] = useState('');

  useEffect(() => {
    const categoria = params.dir === 'exercicios' ? 3 : params.dir === 'aluno' ? 1 : 0;
    const filter = { categoria: categoria, page: page, text: text };
    dispatch(videosActions.nextPage(filter));
  }, [page, dispatch, params.dir, text]);

  useDescriptionTitle('Organize your tasks', 'Tutorial');
  return <LayoutVideos title="Tutorial" videos={list} currentPage={currentPage} pages={pages} setPage={setPage} setText={setText} />;
};

export default Videos;
