import React from 'react';

const Pendente: React.FC = () => {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="mt-5 md:col-span-2 md:mt-0">
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
            <fieldset>
              <legend className="contents font-semibold text-gray-900 text-4xl mb-5 uppercase">Atestado Médico</legend>
              <p className="text-xl text-gray-500 my-5">Aguarde a aprovação do professor.</p>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pendente;
