import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import LayoutAulas from '../Utilities/LayoutAulas';
import { actions } from 'store/Aulas.store';
import { useParams } from 'react-router-dom';
import { Capitalize } from 'utils/converter';

const Aulas: React.FC = () => {
  const { dir = '' } = useParams();
  const dispatch = useAppDispatch();
  const { list, currentPage, pages, favoritos } = useAppSelector((state) => state.aulas);
  const [page, setPage] = useState(1);
  const [text, setText] = useState('');

  useEffect(() => {
    const categoria = dir === 'imagem' ? 1 : dir === 'video' ? 2 : dir === 'audio' ? 3 : 0;
    const filter = { categoria: categoria, page: page, text: text, favoritos: favoritos };
    dispatch(actions.nextPage(filter));
  }, [page, dispatch, dir, text, favoritos]);

  const filter = () => {
    dispatch(actions.filterFavoritos());
  };

  useDescriptionTitle('Organize your tasks', 'audios');
  return (
    <LayoutAulas
      title={Capitalize(dir)}
      aulas={list}
      currentPage={currentPage}
      pages={pages}
      setPage={setPage}
      setText={setText}
      favoritos={favoritos}
      setFavoritos={filter}
    />
  );
};

export default Aulas;
