import Pagination from 'components/Pagination';
import ListItem from 'components/TasksSection/TaskItem/ListItem';
import React, { useEffect, useState } from 'react';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const NeuroQList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { list, currentPage, pages } = useAppSelector((state) => state.avaliacoes.neuroq);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(avaliacaoActions.neuroqByAluno());
  }, [dispatch]);

  useEffect(() => {
    dispatch(avaliacaoActions.nextPageNeuroq(page));
  }, [page, dispatch]);

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200 uppercase">Neuro-Q</h1>
      <ul className={`tasksList mt-4 grid gap-2 grid-cols-1`}>
        {list.map((item: any) => (
          <ListItem key={item.id} item={item} />
        ))}
      </ul>
      <Pagination currentPage={currentPage} pages={pages} handleGotoPage={setPage} />
    </section>
  );
};

export { NeuroQList };
