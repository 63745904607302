import { Routes, Route } from 'react-router-dom';
import Header from '../Header';
import { Anamnese, Antropometria, Atestado, Imc, NeuroQ, Objetivos, Parq, Radar } from '../Questionarios';
import { Bloqueado } from 'components/Questionarios/Bloqueado';

function QuestLayout() {
  return (
    <>
      <Header />
      <main className=" pt-5 pb-8 sm:pb-16 px-3 md:px-8 md:w-full xl:w-8/12 m-auto min-h-screen">
        <Routes>
          <Route path="/objetivos" element={<Objetivos />} />
          <Route path="/parq" element={<Parq />} />
          <Route path="/atestado" element={<Atestado />} />
          <Route path="/anamnese" element={<Anamnese />} />
          <Route path="/radar" element={<Radar />} />
          <Route path="/neuroq" element={<NeuroQ />} />
          <Route path="/imc" element={<Imc />} />
          <Route path="/antropometria" element={<Antropometria />} />
          <Route path="/bloqueado" element={<Bloqueado />} />
          <Route path="*" element={<div>Nao encontrado</div>} />
        </Routes>
      </main>
    </>
  );
}

export { QuestLayout };
