import { useState, useEffect } from 'react';
import { Aula } from '../../interfaces';

const useSortAulas = (aulas: Aula[]) => {
  const [sortedBy, setSortedBy] = useState<string>('');

  const [sorted, setSorted] = useState<Aula[]>(aulas);

  useEffect(() => {
    const sortByDate = (order: 'max-date' | 'min-date'): Aula[] => {
      const toMillisseconds = (date: string) => Date.parse(date);
      const aulasCopy = [...aulas];
      const sorted = aulasCopy.sort((aula1, aula2) => {
        const date1 = toMillisseconds(aula1.insercao);
        const date2 = toMillisseconds(aula2.insercao);

        if (date1 < date2) {
          return -1;
        }

        if (date1 > date2) {
          return 1;
        }

        return 0;
      });

      if (order === 'min-date') {
        return sorted;
      }

      if (order === 'max-date') {
        return sorted.reverse();
      }

      return aulas; //se não existir aulas (para não retornar undefined)
    };

    if (sortedBy === 'min-date' || sortedBy === 'max-date') {
      setSorted(sortByDate(sortedBy));
    }
    if (sortedBy === '' || sortedBy === 'order-added') {
      setSorted(aulas);
    }
    // if (sortedBy === 'completed-first') {
    //   setSorted(sortByCompletedStatus(true));
    // }
    // if (sortedBy === 'uncompleted-first') {
    //   setSorted(sortByCompletedStatus(false));
    // }
  }, [sortedBy, aulas]);
  return { sortedBy, setSortedBy, sorted };
};

export default useSortAulas;
