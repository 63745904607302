import React from 'react';
import AvaliacaoMenuIcon from './AvaliacaoMenuIcon';
import objetivo from '../../assets/icons/objetivo.svg';
import clipboard from '../../assets/icons/clipboard.svg';
import form from '../../assets/icons/form.svg';
import radar from '../../assets/icons/radar.svg';
import brain from '../../assets/icons/brain.svg';
import bmi from '../../assets/icons/bmi.svg';
import metabolism from '../../assets/icons/sindmet.svg';
import measuring from '../../assets/icons/measuring-tape.svg';
import caliper from '../../assets/icons/caliper.svg';
import rcq from '../../assets/icons/rcq.svg';
import Resumo from 'components/Resumo';

const avaliacoes = [
  { titulo: 'OBJETIVO', icon: objetivo, list: 'objetivos', form: 'objetivos/novo', disable: false },
  { titulo: 'PAR-Q', icon: clipboard, list: 'parq', form: 'parq/novo', disable: false },
  { titulo: 'ANAMNESE', icon: form, list: 'anamnese', form: 'anamnese/novo', disable: false },
  { titulo: 'RADAR DO BEM-ESTAR', icon: radar, list: 'radar', form: 'radar/novo', disable: false },
  { titulo: 'NEUROQ', icon: brain, list: 'neuroq', form: 'neuroq/novo', disable: false },
  { titulo: 'ANTROPOMETRIA', icon: measuring, list: 'antropometria', form: 'antropometria/novo', disable: false },
];

type Props = {
  title: string;
};

const LayoutAvaliacoes: React.FC<Props> = ({ title }) => {
  const Title = `${title}`;

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">{Title}</h1>
      <ul
        className={`tasksList mt-4 grid gap-2 sm:gap-4 xl:gap-6 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 grid-cols-2 grid-rows-5 items-end`}
      >
        {avaliacoes.map((e) => (
          <AvaliacaoMenuIcon key={e.titulo} nome={e.titulo} icon={e.icon} disable={e.disable} link={e.list} />
        ))}
        <li>
          <Resumo />
        </li>
      </ul>
    </section>
  );
};

export default React.memo(LayoutAvaliacoes);
