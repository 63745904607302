import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

// create slice

const name = 'users';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    users: {},
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

  return {
    getAll: getAll(),
  };

  function getAll() {
    return createAsyncThunk(`${name}/getAll`, async () => await api.get(baseUrl));
  }
}

function createExtraReducers() {
  return (builder: any) => {
    getAll();

    function getAll() {
      var { pending, fulfilled, rejected } = extraActions.getAll;
      builder
        .addCase(pending, (state: any) => {
          state.users = { loading: true };
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.users = action.payload;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.users = { error: action.error };
        });
    }
  };
}

function createReducers() {
  return {
    main,
  };

  function main() {
    console.log('');
  }
}
