import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticateLayout, PrivateRoute, PrivateRouteSafe, PublicLayout } from './components/Layouts';
import { QuestLayout } from './components/Layouts/QuestLayout';
import ModalCreateTask from './components/Utilities/ModalTask';
import { Task } from './interfaces';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { modalActions } from './store/Modal.store';
import { tasksActions } from './store/Tasks.store';
import { history } from './helpers/history';
import ModalPerfil from 'components/Utilities/ModalPerfil';
import { actions } from 'store/Perfil.store';
import { isTokenExpired } from 'utils/util';
import { authActions } from 'store';
import { PerfilLayout } from 'components/Layouts/PerfilRoute';
import ModalAtividade from 'components/Utilities/ModalAtividade';
import ModalTreino from 'components/Utilities/ModalTreino';
import ModalReview from 'components/Utilities/ModalReview';

const App: React.FC = () => {
  history.location = useLocation();
  history.navigate = useNavigate();

  const modal = useAppSelector((state) => state.modal);
  const treino = useAppSelector((state) => state.treinos.treino);
  const dispatch = useAppDispatch();

  const closeModalCreateTask = () => {
    dispatch(modalActions.closeModalCreateTask());
  };

  const createNewTaskHandler = (task: Task) => {
    dispatch(tasksActions.addNewTask(task));
  };

  const closeModalPerfil = () => {
    dispatch(modalActions.closeModalPerfil());
  };

  const closeModalAtividade = () => {
    dispatch(modalActions.closeModalAtividade());
  };

  const closeModalTreino = () => {
    dispatch(modalActions.closeModalTreino());
  };

  const closeModalReview = () => {
    dispatch(modalActions.closeModalReview());
  };

  const updatePerfil = (data: any) => {
    dispatch(actions.updatePerfil(data));
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) return;
    const userObj = JSON.parse(user?.toString());
    const isExpired = isTokenExpired(userObj.token);
    if (isExpired) dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <div className="bg-slate-200 min-h-screen text-slate-600 dark:bg-slate-900 dark:text-slate-400 xl:text-base sm:text-sm text-xs">
      {modal.modalCreateTaskOpen && (
        <ModalCreateTask onClose={closeModalCreateTask} nameForm="Add a task" onConfirm={createNewTaskHandler} />
      )}
      {modal.modalPerfilOpen && <ModalPerfil onClose={closeModalPerfil} onConfirm={updatePerfil} />}
      {modal.modalAtividadeOpen && <ModalAtividade onClose={closeModalAtividade} nameForm="Atividade" />}
      {modal.modalTreinoOpen && treino && <ModalTreino onClose={closeModalTreino} nameForm="Atividade" treino={treino} />}
      {modal.modalReviewOpen && <ModalReview nameForm="" onClose={closeModalReview} />}
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/*" element={<AuthenticateLayout />} />
        </Route>
        <Route path="perfil/*" element={<PerfilLayout />} />
        <Route element={<PrivateRouteSafe />}>
          <Route path="questionario/*" element={<QuestLayout />} />
        </Route>
        <Route path="login/*" element={<PublicLayout />} />
      </Routes>
    </div>
  );
};

export default App;
