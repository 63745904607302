import React, { useState } from 'react';
import { Aula } from '../../interfaces';
import useSortAulas from '../hooks/useSortAulas';
import ButtonsSort from '../TasksSection/ButtonsSort';
import AulaItem from '../TasksSection/TaskItem/AulaItem';
import Pagination from 'components/Pagination';
import ModalFilter from './ModalFilter';
import Checkbox from 'components/Checkbox';

type Props = {
  title: string;
  aulas: Aula[] | [];
  currentPage: number;
  pages: number;
  favoritos: boolean;
  setPage: React.Dispatch<React.SetStateAction<any>>;
  setText: React.Dispatch<React.SetStateAction<any>>;
  setFavoritos: React.Dispatch<React.SetStateAction<any>>;
};

const LayoutAulas: React.FC<Props> = ({ title, aulas, currentPage, pages, setPage, setText, favoritos, setFavoritos }) => {
  const [isListInView1, setIsListInView1] = useState<boolean>(false);
  const { sortedBy, setSortedBy, sorted } = useSortAulas(aulas);
  const [showModal, setModalShow] = useState<boolean>(false);
  const Title = `${title} (${aulas.length} ${aulas.length === 1 ? 'aula' : 'aulas'})`;

  const changeFilter = (e: any) => {
    if (e.key === 'Enter') {
      setText(e.target.value);
    }
  };

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">{Title}</h1>
      <ButtonsSort isListInView1={isListInView1} setIsListInView1={setIsListInView1} sortedBy={sortedBy} setSortedBy={setSortedBy} />
      <input type="text" id="text" className="inputStyles w-full mt-2" onKeyDown={changeFilter} />
      <Checkbox isChecked={favoritos} setChecked={setFavoritos} label="Favoritos" />
      <ul
        className={`tasksList mt-4 grid gap-2 sm:gap-4 xl:gap-6 ${
          isListInView1 ? 'grid-cols-1' : '2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-end'
        }`}
      >
        {sorted.map((task) => (
          <AulaItem key={task.id} isListInView1={isListInView1} item={task} />
        ))}
      </ul>
      <Pagination currentPage={currentPage} pages={pages} handleGotoPage={setPage} />
      {showModal && (
        <ModalFilter
          onClose={() => setModalShow(false)}
          onConfirm={() => console.log('confirm')}
          text="This directory and all its tasks will be deleted."
        />
      )}
    </section>
  );
};

export default React.memo(LayoutAulas);
