import React, { useState } from 'react';
import { Video } from '../../interfaces';
import useSortVideos from '../hooks/useSortVideos';
import ButtonsSort from '../TasksSection/ButtonsSort';
import VideoItem from '../TasksSection/TaskItem/VideoItem';
import Pagination from 'components/Pagination';
import { useParams } from 'react-router-dom';

type Props = {
  title: string;
  videos: Video[] | [];
  currentPage: number;
  pages: number;
  setPage: React.Dispatch<React.SetStateAction<any>>;
  setText: React.Dispatch<React.SetStateAction<any>>;
};

const LayoutVideos: React.FC<Props> = ({ title, videos, currentPage, pages, setPage, setText }) => {
  const { dir } = useParams();
  const [isListInView1, setIsListInView1] = useState<boolean>(false);
  const { sortedBy, setSortedBy, sortedVideos } = useSortVideos(videos);
  const tasksTitle = `${title} ${dir} (${videos.length} ${videos.length === 1 ? 'video' : 'videos'})`;

  const changeFilter = (e: any) => {
    if (e.key === 'Enter') {
      setText(e.target.value);
    }
  };

  return (
    <section>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-2xl text-lg dark:text-slate-200">{tasksTitle}</h1>
      <ButtonsSort isListInView1={isListInView1} setIsListInView1={setIsListInView1} sortedBy={sortedBy} setSortedBy={setSortedBy} />
      <input type="text" id="text" className="inputStyles w-full mt-2" onKeyDown={changeFilter} />
      <ul
        className={`tasksList mt-4 grid gap-2 sm:gap-4 xl:gap-6 ${
          isListInView1 ? 'grid-cols-1' : '2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-end'
        }`}
      >
        {sortedVideos.map((task) => (
          <VideoItem key={task.id} isListInView1={isListInView1} item={task} />
        ))}
      </ul>
      <Pagination currentPage={currentPage} pages={pages} handleGotoPage={setPage} />
    </section>
  );
};

export default React.memo(LayoutVideos);
