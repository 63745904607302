import { Action, createAsyncThunk, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { Aula } from '../interfaces';
import { authHeader } from 'helpers';
import api from 'services/api';

const initialState: {
  list: Aula[];
  currentPage: number;
  pages: number;
  favoritos: boolean;
  aula: Aula | null;
} = {
  list: [],
  currentPage: 0,
  pages: 0,
  favoritos: false,
  aula: null,
};

const name = 'aulas';
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const aulasSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    addNew(state, action: PayloadAction<Aula>) {
      state.list = [action.payload, ...state.list];
    },
    remove(state, action) {
      const newList = state.list.filter((item) => item.id !== action.payload);
      state.list = newList;
    },
    markAsImportant(state, action: PayloadAction<string>) {
      const newFavorited = state.list.find((item) => item.id === Number(action.payload));
      newFavorited!.important = !newFavorited!.important;
    },
    edit(state, action: PayloadAction<Aula>) {
      const Id = action.payload.id;
      const newEdited: Aula = state.list.find((item: Aula) => item.id === Id)!;
      const index = state.list.indexOf(newEdited);
      state.list[index] = action.payload;
    },
    deleteAllData(state) {
      state.list = [];
    },
    filterFavoritos(state) {
      state.favoritos = !state.favoritos;
    },
  },
  extraReducers,
});

function createExtraActions() {
  const requestOptions = {
    // method: 'GET',
    headers: authHeader(),
  };

  return {
    list: getList(),
    nextPage: nextPage(),
    markFavorito: markFavorito(),
    getAula: getAula(),
  };

  function getList() {
    return createAsyncThunk(`${name}/list`, async () => await api.get(`aulas`, requestOptions));
  }

  function nextPage() {
    return createAsyncThunk(`${name}/list/page`, async ({ categoria, page, text, favoritos }: any) => {
      const query = text ? `&Text=${text}` : '';
      return await api.get(`aulas?categoria=${categoria}&PageNumber=${page}${query}&favoritos=${favoritos}`, requestOptions);
    });
  }

  function markFavorito() {
    return createAsyncThunk(`${name}/favoritar`, async (id: number) => await api.post(`aulas/favoritar/${id}`, { id: id }, requestOptions));
  }

  function getAula() {
    return createAsyncThunk(`${name}/get_aula`, async (id: number) => await api.get(`aulas/${id}`, requestOptions));
  }
}

function createExtraReducers() {
  return (builder: any) => {
    getList();
    nextPage();
    markFavorito();
    getAula();

    function getList() {
      let { pending, fulfilled, rejected } = extraActions.list;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function nextPage() {
      let { pending, fulfilled, rejected } = extraActions.nextPage;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.list = action.payload.data.list;
          state.currentPage = action.payload.data.currentPage;
          state.pages = action.payload.data.pages;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }

    function markFavorito() {
      let { pending, fulfilled, rejected } = extraActions.markFavorito;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          const newEdited: Aula = state.list.find((item: Aula) => item.id === Number(action.payload.data));
          const index = state.list.indexOf(newEdited);
          state.list[index] = { ...newEdited, favorito: !newEdited.favorito };
          console.log(newEdited);
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
    function getAula() {
      let { pending, fulfilled, rejected } = extraActions.getAula;
      builder
        .addCase(pending, (state: any) => {
          state.error = null;
        })
        .addCase(fulfilled, (state: any, action: any) => {
          state.aula = action.payload.data;
        })
        .addCase(rejected, (state: any, action: any) => {
          state.error = action.error;
        });
    }
  };
}

export const actions = { ...aulasSlice.actions, ...extraActions };
export default aulasSlice.reducer;

export const aulasMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
  const nextAction = next(action);
  const isADirectoryAction: boolean = action.type.toLowerCase().includes('directory');

  if (action.type.startsWith('aulas/') && !isADirectoryAction) {
    const List = store.getState().aulas.list;
    localStorage.setItem('aulas', JSON.stringify(List));
  }

  if (actions.deleteAllData.match(action)) {
    localStorage.removeItem('aulas');
  }

  if (actions.remove.match(action)) {
    if (localStorage.getItem('aulas')) {
      const _localStorage = JSON.parse(localStorage.getItem('aulas')!);
      if (_localStorage.length === 0) {
        localStorage.removeItem('aulas');
      }
    }
  }
  return nextAction;
};
