import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'store';
import { useAppDispatch } from 'store/hooks';
import * as z from 'zod';
import { toast } from 'react-toastify';

const TermoArquivo = 'https://atitudevs.blob.core.windows.net/documentos/Termo de uso AVS_aluno_31.10.20.pdf';

type FormValues = {
  nome: string;
  sobrenome: string;
  email: string;
  password: string;
  confirmPassword: string;
  termo: boolean;
};

const schema = z.object({
  nome: z.string().min(1, { message: '*Obrigatorio.' }),
  sobrenome: z.string().min(1, { message: '*Obrigatorio.' }),
  email: z.string().min(1, { message: '*Obrigatorio.' }),
  password: z.string().min(1, { message: '*Obrigatorio.' }),
  confirmPassword: z.string().min(1, { message: '*Obrigatorio.' }),
  termo: z.boolean(),
});

const Register: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ resolver: zodResolver(schema) });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showpass, setShowPass] = useState(false);
  const showPassword = () => setShowPass(!showpass);
  const { termo } = watch();

  const onSubmit = handleSubmit(async (data) => {
    if (!termo) {
      toast.error('É necessario aceitar os termos de uso');
      return;
    }
    try {
      await dispatch(authActions.register(data));
      // navigate('/login');
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <section>
      <div className="container mx-auto h-full px-6 py-10 md:py-24">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="mb-12 md:mb-0 w-3/4 md:w-8/12 lg:w-6/12">
            {/* <img src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="w-full" alt="Logo" /> */}
            <iframe src="https://player.vimeo.com/video/892308014" className="w-full h-full lg:h-[470px]" />
          </div>
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <form onSubmit={onSubmit}>
              <div className="relative mb-6" data-te-input-wrapper-init>
                <input
                  type="text"
                  className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                  id="nome"
                  placeholder="Nome"
                  {...register('nome')}
                />
                <label
                  htmlFor="nome"
                  className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                >
                  Nome
                </label>
                {errors.nome && <p className="text-red-500">*Obrigatório.</p>}
              </div>
              <div className="relative mb-6" data-te-input-wrapper-init>
                <input
                  type="text"
                  className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                  id="sobrenome"
                  placeholder="Sobrenome"
                  {...register('sobrenome')}
                />
                <label
                  htmlFor="sobrenome"
                  className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                >
                  Sobrenome
                </label>
                {errors.sobrenome && <p className="text-red-500">*Obrigatório.</p>}
              </div>
              <div className="relative mb-6" data-te-input-wrapper-init>
                <input
                  type="text"
                  className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none [&:not([active])]:placeholder:opacity-0"
                  id="email"
                  placeholder="E-mail"
                  {...register('email')}
                />
                <label
                  htmlFor="email"
                  className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                >
                  E-mail
                </label>
                {errors.email?.message && <p className="text-red-500">{errors.email.message}</p>}
              </div>
              <div className="relative mb-6">
                <input
                  type={showpass ? 'text' : 'password'}
                  className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 placeholder-shown:border-none border-2 border-blue-600 [&:not([active])]:placeholder:opacity-0"
                  id="password"
                  autoComplete="off"
                  placeholder="Password"
                  {...register('password')}
                />
                <label
                  htmlFor="password"
                  className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                >
                  Password
                </label>
                <div className="absolute inset-y-0 right-3 flex items-center pl-3 cursor-pointer" onClick={showPassword}>
                  {showpass ? <FiEye /> : <FiEyeOff />}
                </div>
                {errors.password && <p className="text-red-500">*Obrigatório.</p>}
              </div>
              <div className="relative mb-6">
                <input
                  type={showpass ? 'text' : 'password'}
                  className="peer block min-h-[auto] w-full rounded border-0 px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 placeholder-shown:border-none border-2 border-blue-600 [&:not([active])]:placeholder:opacity-0"
                  id="confirmPassword"
                  autoComplete="off"
                  placeholder="Password"
                  {...register('confirmPassword')}
                />
                <label
                  htmlFor="confirmPassword"
                  className="pointer-events-none absolute left-3 -top-5 mb-0 text-neutral-500 transition-all duration-200 ease-out peer-focus:-top-5 peer-placeholder-shown:top-2"
                >
                  Password
                </label>
                <div className="absolute inset-y-0 right-3 flex items-center pl-3 cursor-pointer" onClick={showPassword}>
                  {showpass ? <FiEye /> : <FiEyeOff />}
                </div>
                {errors.confirmPassword && <p className="text-red-500">*Obrigatório.</p>}
              </div>
              <div className="relative mb-6">
                <a className="text-lg font-medium" href={TermoArquivo} target="_blank">
                  Visualizar o termo de uso
                </a>
                <label className="mb-0 flex items-center cursor-pointer">
                  <div className="mr-2 bg-slate-300/[.5] dark:bg-slate-800 w-5 h-5 rounded grid place-items-center border border-slate-300 dark:border-slate-700">
                    {termo && <span className="bg-green-500 w-3 h-3 block rounded"></span>}
                  </div>
                  <span className="order-1 flex-1">Aceito os termos de uso</span>
                  <input type="checkbox" className="sr-only" {...register('termo')} />
                </label>
                {errors.termo && <p className="text-red-500">*Obrigatório.</p>}
              </div>
              <button
                type="submit"
                className="inline-block w-full rounded bg-blue-600 px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
              >
                Cadastrar
              </button>

              <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                <p className="mx-4 mb-0 text-center font-semibold">OU</p>
              </div>
              <a
                className="mb-3 flex w-full items-center justify-center rounded bg-primary px-7 pb-2.5 pt-3 text-center text-sm font-medium uppercase leading-normal text-blue-500 shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                style={{ backgroundColor: '#fff' }}
                href="/login"
                role="button"
              >
                Login
              </a>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
