import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PerimetriaFields: React.FC<Props> = ({ children }) => {
  return (
    <div className="w-3/12 md:w-1/6 flex-none flex items-center justify-around">
      <div className="w-full grid grid-cols-2 gap-2">{children}</div>
    </div>
  );
};

export default PerimetriaFields;
