import React, { useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Cross2Icon } from '@radix-ui/react-icons';
import {
  RadarCalculo2,
  RadarCalculo3,
  RadarEmocionalDepara,
  RadarFisicoDepara,
  RadarRelacionamentoDepara,
  colorCardWr1,
  colorCardWr7,
} from './functions';
import objetivo from '../../assets/icons/objetivo.svg';
import doctor from '../../assets/icons/doctor.svg';
import psychologist from '../../assets/icons/psychologist.svg';
import dentist from '../../assets/icons/dentist.svg';
import nutritionist from '../../assets/icons/nutritionist.svg';
import medicine from '../../assets/icons/medicine.svg';
import healthInsurance from '../../assets/icons/health-insurance.svg';
import healthCondition from '../../assets/icons/health-condition.svg';
import dna from '../../assets/icons/dna.svg';
import diabetes from '../../assets/icons/diabetes.svg';
import blood from '../../assets/icons/blood-pressure.svg';
import obesity from '../../assets/icons/obesity.svg';
import lung from '../../assets/icons/lung.svg';
import knee from '../../assets/icons/knee-joint.svg';
import spine from '../../assets/icons/spine.svg';
import tendonitis from '../../assets/icons/tendonitis.svg';
import sadness from '../../assets/icons/sadness.svg';
import anxiety from '../../assets/icons/anxiety.svg';
import exercise from '../../assets/icons/exercise.svg';
import meal from '../../assets/icons/meal.svg';
import smoking from '../../assets/icons/smoking.svg';
import alcohol from '../../assets/icons/alcohol.svg';
import sleeping from '../../assets/icons/sleeping.svg';
import emotional from '../../assets/icons/emotional.svg';
import positivefeeling from '../../assets/icons/positivefeeling.svg';
import religion from '../../assets/icons/religion.svg';
import thinking from '../../assets/icons/thinking.svg';
import selfimage from '../../assets/icons/selfimage.svg';
import selfesteem from '../../assets/icons/selfesteem.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { avaliacaoActions } from 'store/Avaliacoes.store';

const Resumo: React.FC = () => {
  const resumo = useAppSelector((state) => state.avaliacoes.resumo);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(avaliacaoActions.getResumo());
  }, [dispatch]);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div
          className={`relative flex rounded-[20px] w-full !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent flex-col h-auto cursor-pointer`}
        >
          <div className={`h-full w-full`}>
            <div className={`relative flex flex-col w-full h-40 justify-around items-center text-center`}>
              <div className="w-20 h-20">
                <img src={objetivo} alt="menu" className={'opacity-100'} />
              </div>
              <div>Relatorio Basico</div>
            </div>
          </div>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 overflow-y-auto" />
        <Dialog.Content className="fixed top-[50%] left-[50%] h-screen w-auto translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-50">
          {resumo && (
            <div>
              <ScrollArea.Root className="h-screen overflow-hidden">
                <ScrollArea.Viewport className="w-full h-full" style={{ scrollbarWidth: 'auto' }}>
                  <div className="flex justify-between w-full">
                    <div className="border-2 border-t-black border-x-black w-10/12">
                      Nome: {resumo.nome} {resumo.sobrenome}
                    </div>
                    <div className="pl-1 border-2 border-t-black border-r-black w-2/12">Idade: {resumo.idade}</div>
                  </div>
                  {/* <div className="border-2 border-t-black border-x-black">Objetivo:</div> */}
                  {/* <div className="border-2 border-t-black border-x-black">Meta:</div> */}
                  <div className="flex justify-between">
                    <div className="border-2 border-t-black border-x-black w-1/3">Peso: {resumo.peso} kg</div>
                    <div className="border-2 border-t-black border-r-black w-1/3">Imc: {resumo.imc}</div>
                    <div className="border-2 border-t-black border-r-black w-1/3">Classificação: {resumo.imcClassificacao}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="border-2 border-t-black border-x-black w-1/2">% Gordura: {resumo.gordura.toFixed(2)}</div>
                    <div className="border-2 border-t-black border-r-black w-1/2">Classificação: {resumo.gorduraClassificacao}</div>
                  </div>
                  {resumo.temRcq && (
                    <div className="flex justify-between">
                      <div className="border-2 border-t-black border-x-black w-1/5">RCQ: {resumo.rcq}</div>
                      <div className="border-2 border-t-black border-r-black w-1/5">Classificação: {resumo.rcqClassificacao}</div>
                      <div className="border-2 border-t-black border-r-black w-1/5">Cintura: {resumo.cintura}</div>
                      <div className="border-2 border-t-black border-r-black w-1/5">Classificação: {resumo.cinturaClassificacao}</div>
                      <div className="border-2 border-t-black border-r-black w-1/5">Quadril: {resumo.quadril}</div>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <div className="border-2 border-y-black border-x-black w-1/3">
                      Dimensão Fisica:{' '}
                      {RadarFisicoDepara(resumo.wr01) + RadarFisicoDepara(resumo.wr03) === 0 && (
                        <small className="text-default">Inativo</small>
                      )}
                      {RadarFisicoDepara(resumo.wr01) +
                        RadarFisicoDepara(resumo.wr02) +
                        RadarFisicoDepara(resumo.wr03) +
                        RadarFisicoDepara(resumo.wr04) <
                        7 &&
                        RadarFisicoDepara(resumo.wr01) + RadarFisicoDepara(resumo.wr03) !== 0 && (
                          <small className="text-default">Parcialmente Ativo</small>
                        )}
                      {RadarFisicoDepara(resumo.wr01) +
                        RadarFisicoDepara(resumo.wr02) +
                        RadarFisicoDepara(resumo.wr03) +
                        RadarFisicoDepara(resumo.wr04) >
                        6 && <small className="text-default">Ativo</small>}
                    </div>
                    <div className="border-2 border-y-black border-r-black w-1/3">
                      Dimensão Social:{' '}
                      {RadarCalculo2(
                        RadarRelacionamentoDepara(resumo.wr11),
                        RadarRelacionamentoDepara(resumo.wr12),
                        RadarRelacionamentoDepara(resumo.wr13),
                        RadarRelacionamentoDepara(resumo.wr14)
                      )}
                    </div>
                    <div className="border-2 border-y-black border-r-black w-1/3">
                      Dimensão Emocional:{' '}
                      {RadarCalculo3(
                        RadarEmocionalDepara(resumo.wr15),
                        RadarEmocionalDepara(resumo.wr16),
                        RadarEmocionalDepara(resumo.wr17),
                        RadarEmocionalDepara(resumo.wr18),
                        RadarEmocionalDepara(resumo.wr19),
                        RadarEmocionalDepara(resumo.wr20)
                      )}
                    </div>
                  </div>

                  {resumo.temAnamnese && (
                    <div>
                      <h1>Anamnese</h1>
                      <div className="flex flex-wrap">
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini01 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={doctor} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini01 === 0 && 'não realiza acompanhamento / tratamento médico'}
                              {resumo.ini01 === 1 && `realiza acompanhamento / tratamento médico na frequencia de ${resumo.ini02} ano`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini03 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={psychologist} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini03 === 0 && 'não realiza acompanhamento / tratamento psicológico / psiquiátrico'}
                              {resumo.ini03 === 1 &&
                                `realiza acompanhamento / tratamento psicológico / psiquiátrico para ${resumo.ini04} na frequencia de ${resumo.ini05} ao ano`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini06 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={dentist} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini06 === 0 && 'não realiza acompanhamento / tratamento odontológico'}
                              {resumo.ini06 === 1 &&
                                `realiza acompanhamento / tratamento odontológico na frequencia de ${resumo.ini07} ao ano`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini08 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={nutritionist} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini08 === 0 && 'não realiza acompanhamento / tratamento nutricional'}
                              {resumo.ini08 === 1 &&
                                `realiza acompanhamento / tratamento nutricional na frequencia de ${resumo.ini09} ao ano`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini10 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={medicine} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini10 === 0 && 'não toma nenhum tipo de medicamento'}
                              {resumo.ini10 === 1 && `toma o medicamento por indicação ${resumo.ini11}`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: '#fff' }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={healthInsurance} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini12 === 0 && `Não possui plano de Saúde`}
                              {resumo.ini12 === 1 && `Possui Plano de Saúde ${resumo.ini13}`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini14 === 'Bom' || resumo.ini14 === 'Muito Bom' ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={healthCondition} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">{`Classifica sua condição de saúde como ${resumo.ini14}`}</div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini15 === 'Não' ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={dna} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini15 === 'Não' ? 'Não possui risco hereditário' : `Possui risco hereditário em ${resumo.ini15}`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini16 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={diabetes} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini16 === 0 && 'Nenhum médico disse que tenha ou já teve diabetes.'}
                              {resumo.ini16 === 1 && `Já foi ou está diagnosticado com diabetes.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini17 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={blood} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini17 === 0 && 'Nenhum médico disse que tenha ou já teve hipertensão arterial.'}
                              {resumo.ini17 === 1 && `Já foi ou está diagnosticado com hipertensão arterial.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini18 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={obesity} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini18 === 0 && 'Nenhum médico disse que tenha ou já teve obesidade.'}
                              {resumo.ini18 === 1 && `Já foi ou está diagnosticado com obesidade.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini19 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={lung} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini19 === 0 && 'Nenhum médico disse que tenha ou já teve doença respiratória.'}
                              {resumo.ini19 === 1 && `Já foi ou está diagnosticado com doença respiratória.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini20 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={knee} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini20 === 0 && 'Nenhum médico disse que tenha ou já teve doença articular.'}
                              {resumo.ini20 === 1 && `Já foi ou está diagnosticado com doença articular.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini21 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={spine} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini21 === 0 && 'Nenhum médico disse que tenha ou já teve problema de coluna (costas).'}
                              {resumo.ini21 === 1 && `Já foi ou está diagnosticado com problema de coluna (costas).`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini22 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={tendonitis} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini22 === 0 && 'Nenhum médico disse que tenha ou já teve tendinite ou LER.'}
                              {resumo.ini22 === 1 && `Já foi ou está diagnosticado com tendinite ou LER.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini23 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={sadness} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini23 === 0 && 'Nenhum médico disse que tenha ou já teve Depressão.'}
                              {resumo.ini23 === 1 && `Já foi ou está diagnosticado com Depressão.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.ini24 === 0 ? 'greenyellow' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={anxiety} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.ini24 === 0 && 'Nenhum médico disse que tenha ou já teve transtorno de ansiedade.'}
                              {resumo.ini24 === 1 && `Já foi ou está diagnosticado com transtorno de ansiedade.`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {resumo.temRadar && (
                    <div>
                      <h1>Estilo de Vida</h1>
                      <div className="flex flex-wrap">
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr01) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={exercise} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr01 === 'Nenhum'
                                ? 'Não CAMINHOU na última semana'
                                : `CAMINHOU ${resumo.wr01} na última semana ${resumo.wr02} contínuos.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr03) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={exercise} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr03 === 'Não'
                                ? 'NÃO PRATICOU, regularmente, algum tipo de atividade física'
                                : `PRATICOU ${resumo.wr03} ${resumo.wr04} contínuos.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr05) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={meal} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr05 === 'Nunca'
                                ? 'NÃO Come verduras e legumes durante a semana.'
                                : `Come verduras e legumes ${resumo.wr05}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr06) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={meal} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr06 === 'Nunca'
                                ? 'NÃO Come frutas ou toma suco natural durante a semana.'
                                : `Come frutas ou toma suco natural ${resumo.wr06}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr7(resumo.wr07) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={meal} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr07 === 'Nunca'
                                ? 'NÃO Toma refrigerantes ou sucos artificias durante a semana.'
                                : `Toma refrigerantes ou sucos artificias ${resumo.wr07}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr08) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={smoking} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr08 === 'Nunca fumei' ? 'Nunca fumou.' : `${resumo.wr08}.`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex break-words rounded-md h-56 w-40 m-2"
                          style={{ background: resumo.wr09 === 'Não' ? 'lawngreen' : 'red' }}
                        >
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={alcohol} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr09 === 'Não'
                                ? 'NÃO TOMOU, nos últimos 30 dias, 5+ doses de bebidas alcoólicas numa mesma ocasião'
                                : 'TOMOU, nos últimos 30 dias, 5+ doses de bebidas alcoólicas numa mesma ocasião'}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr10) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={sleeping} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">{`Considera que DORME bem ${resumo.wr10}.`}</div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr11) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={emotional} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {`Classifica a qualidade dos seus relacionamentos que mantém com outras pessoas como ${resumo.wr11}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr12) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={emotional} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {`Dedica seu tempo voluntariamente a projetos social ${resumo.wr12}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr13) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={emotional} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {`Classifica seu nível pessoal de integração/adaptação ao ambiente como ${resumo.wr13}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr14) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={emotional} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {`Tem achado que está sendo útil na vida de alguém ${resumo.wr14}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr15) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={positivefeeling} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr15 === 'Nada' ? 'NÃO aproveita a vida.' : `Aproveita a vida ${resumo.wr15}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr16) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={religion} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr16 === 'Nada' ? 'Acha que a vida não tem sentido.' : `Acha que a vida tem ${resumo.wr16}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr17) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={thinking} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr17 === 'Nada' ? 'Não consegue se concentrar.' : `Consegue se concentrar ${resumo.wr17}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr18) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={selfimage} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr18 === 'Nada'
                                ? 'Não é capaz de aceitar sua aparência física.'
                                : `É capaz de aceitar sua aparência física ${resumo.wr18}.`}
                            </div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr19) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={selfesteem} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">{`Está ${resumo.wr19} consigo mesmo.`}</div>
                          </div>
                        </div>
                        <div className="flex break-words rounded-md h-56 w-40 m-2" style={{ background: colorCardWr1(resumo.wr20) }}>
                          <div className="flex-auto p-4">
                            <button className="block m-auto h-16 w-16">
                              <img className="w-full" alt="..." src={exercise} />
                            </button>
                            <div className="text-center mt-5 h-12 text-black">
                              {resumo.wr20 === 'Nunca' ? 'Nunca tem sentimentos positivos.' : `Tem sentimentos positivos ${resumo.wr20}.`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className="flex select-none touch-none p-0.5 bg-blackA6 transition-colors duration-[160ms] ease-out hover:bg-blackA8 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                  orientation="vertical"
                >
                  <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                  className="flex select-none touch-none p-0.5 bg-blackA6 transition-colors duration-[160ms] ease-out hover:bg-blackA8 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                  orientation="horizontal"
                >
                  <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner className="bg-blackA8" />
              </ScrollArea.Root>
            </div>
          )}
          <Dialog.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[5px] right-[5px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Resumo;
