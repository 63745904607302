import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import AccountData from '../AccountSection/AccountData';
import MainSection from '../TasksSection/MainSection';
import Menu from '../Menu/Menu';
import { actions } from '../../store/Professor.store';
import BlockSection from 'components/TasksSection/BlockSection';

function AuthenticateLayout() {
  const dispatch = useAppDispatch();
  const completed: boolean = useAppSelector((state) => state.auth.completed);

  useEffect(() => {
    dispatch(actions.getProfessor());
  }, []);

  return (
    <div>
      <Menu />
      <MainSection />
      <AccountData />
    </div>
  );
}

export { AuthenticateLayout };
