import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import Modal from './Modal';
import { useAppSelector } from 'store/hooks';
import { convertToDate, formatToDate } from 'utils/converter';
import { estados } from 'utils/apoio';
import api from 'services/api';
import _ from 'lodash';

type Props = {
  onClose: () => void;
  onConfirm: (data: any) => void;
};

type Inputs = {
  nome: string;
  sobrenome: string;
  nascimento: Date;
  nascimentoFormat: string;
  sexo: string;
  estadoCivil: string;
  escolaridade: string;
  celular: string;
  email: string;
  cpf: string;
  cep: string;
  endereco: string;
  estado: string;
  cidade: string;
};

const sexos = [
  { name: 'Feminino', value: '2' },
  { name: 'Masculino', value: '1' },
];
const estadoCivil = [
  { name: 'Solteiro', value: 1 },
  { name: 'Casado', value: 2 },
  { name: 'Viuvo', value: 3 },
  { name: 'Divorciado', value: 4 },
  { name: 'Não Declarado', value: 5 },
];
const escolaridade = [
  {
    id: 1,
    value: 1,
    name: 'Educação infantil ',
  },
  {
    id: 2,
    value: 2,
    name: 'Ensino Fundamental ',
  },
  {
    id: 3,
    value: 3,
    name: 'Ensino Médio ',
  },
  {
    id: 4,
    value: 4,
    name: 'Superior (Graduação) ',
  },
  {
    id: 5,
    value: 5,
    name: 'Pós-graduação ',
  },
  {
    id: 6,
    value: 6,
    name: 'Mestrado ',
  },
  {
    id: 7,
    value: 7,
    name: 'Doutorado',
  },
];

const ModalPerfil: React.FC<Props> = ({ onClose, onConfirm }) => {
  const { aluno } = useAppSelector((state) => state.perfil);
  const { register, setValue, handleSubmit, reset } = useForm<Inputs>();

  useEffect(() => {
    reset(aluno);
    if (!aluno) return;
    setValue('nascimentoFormat', formatToDate(new Date(aluno.nascimento)));
  }, [aluno, setValue, reset]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    data.nascimento = convertToDate(data.nascimentoFormat);
    const _data = _.mapValues(data, (v) => (v === '' ? null : v));
    onConfirm(_data);
    onClose();
  };

  const getCEP = (cepValue: string) => {
    const _cep: string = cepValue || aluno.cep;
    const cep = _cep.replaceAll('_', '');
    console.log(_cep);

    if (cep.length !== 8) return;

    api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        if (response.data.erro) {
          return;
        }

        const { uf, logradouro, localidade } = response.data;
        const estado = estados.find((e) => e.name === uf);

        setValue('endereco', logradouro);
        setValue('estado', estado?.value || `0`);
        setValue('cidade', localidade);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal onClose={onClose} title="Perfil">
      <ScrollArea.Root className="h-screen" type="scroll">
        <ScrollArea.Viewport className="w-[20rem] xl:w-[60rem] h-full">
          <form className="flex flex-col stylesInputsField" onSubmit={handleSubmit(onSubmit)}>
            <label>
              Nome
              <input type="text" placeholder="Nome" required {...register('nome', { required: true })} className="w-full" />
            </label>
            <label>
              Sobrenome
              <input type="text" placeholder="Sobrenome" required {...register('sobrenome', { required: true })} className="w-full" />
            </label>
            <label>
              Nascimento
              <input type="text" className="w-full" readOnly required {...register('nascimentoFormat', { required: true })} />
            </label>
            <label>
              Sexo
              <select className="block w-full" {...register('sexo', { required: true })}>
                {sexos.map((sexo: any) => (
                  <option key={sexo.id} value={sexo.value} className="bg-slate-100 dark:bg-slate-800">
                    {sexo.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Estado civil
              <select className="block w-full" {...register('estadoCivil', { required: false })}>
                <option value="" className="bg-slate-100 dark:bg-slate-800" />
                {estadoCivil.map((e: any) => (
                  <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
                    {e.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Escolaridade
              <select className="block w-full" {...register('escolaridade', { required: false })}>
                <option value="" className="bg-slate-100 dark:bg-slate-800" />
                {escolaridade.map((e: any) => (
                  <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
                    {e.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Email
              <input type="text" placeholder="email@email.com" readOnly {...register('email', { required: true })} className="w-full" />
            </label>
            <label>
              Celular
              <input type="text" placeholder="99999-9999" required {...register('celular', { required: true })} className="w-full" />
            </label>
            <label>
              CPF
              <input type="text" placeholder="999.999.999-99" required {...register('cpf', { required: true })} className="w-full" />
            </label>
            <label>
              CEP
              <input
                type="text"
                placeholder="99999-999"
                {...register('cep', { required: false })}
                onChange={(e) => getCEP(e.target.value)}
                className="w-full"
              />
            </label>
            <label>
              Endereço
              <input type="text" placeholder="Endereço" {...register('endereco', { required: false })} className="w-full" />
            </label>
            <label>
              Estado
              <select className="block w-full" {...register('estado', { required: false })}>
                <option value="" className="bg-slate-100 dark:bg-slate-800" />
                {estados.map((e: any) => (
                  <option key={e.id} value={e.value} className="bg-slate-100 dark:bg-slate-800">
                    {e.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Cidade
              <input type="text" placeholder="Cidade" {...register('cidade', { required: false })} className="w-full" />
            </label>
            <button type="submit" className="btn mt-5 mb-20">
              Gravar
            </button>
          </form>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className="flex select-none touch-none p-0.5 bg-blackA6 transition-colors duration-[160ms] ease-out hover:bg-blackA8 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
          orientation="vertical"
        >
          <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner className="bg-blackA8" />
      </ScrollArea.Root>
    </Modal>
  );
};

export default ModalPerfil;
