import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ptbr from 'dayjs/locale/pt-br';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { getWeekDays } from 'utils/get-week-days';
import { HabitDay } from './HabitDay';
import api from 'services/api';
import { useAppSelector } from 'store/hooks';

dayjs.locale(ptbr);

type IHabitos = {
  summary: Summary;
  setSummary: (data: any) => void;
  currentDate: any;
  setCurrentDate: (data: any) => void;
};

const Habitos: React.FC<IHabitos> = ({ summary, setSummary, currentDate, setCurrentDate }) => {
  // const token = useAppSelector((state) => state.auth.user.token);

  // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  // useEffect(() => {
  //   api.get('habitos/summary').then((response) => {
  //     setSummary(response.data);
  //   });
  // }, []);

  function handlePreviousMonth() {
    const previousMonth = currentDate.subtract(1, 'month');
    setCurrentDate(previousMonth);
  }

  function handleNextMonth() {
    const nextMonth = currentDate.add(1, 'month');
    setCurrentDate(nextMonth);
  }

  const shortWeekDays = getWeekDays({ short: true });

  const currentMonth = currentDate.format('MMMM');
  const currentYear = currentDate.format('YYYY');

  // eslint-disable-next-line
  const blockedDates: BlockedDates = {
    blockedWeekDays: [],
    blockedDates: [],
  };

  const calendarWeeks = useMemo(() => {
    if (!blockedDates) {
      return [];
    }
    // console.log('calendarWeeks ~ blockedDates', blockedDates);

    const daysInMonthArray = Array.from({
      length: currentDate.daysInMonth(),
    }).map((_, i) => {
      return currentDate.set('date', i + 1);
    });

    const firstWeekDay = currentDate.get('day');

    const previousMonthFillArray = Array.from({
      length: firstWeekDay,
    })
      .map((_, i) => {
        return currentDate.subtract(i + 1, 'day');
      })
      .reverse();

    const lastDayInCurrentMonth = currentDate.set('date', currentDate.daysInMonth());
    const lastWeekDay = lastDayInCurrentMonth.get('day');

    const nextMonthFillArray = Array.from({
      length: 7 - (lastWeekDay + 1),
    }).map((_, i) => {
      return lastDayInCurrentMonth.add(i + 1, 'day');
    });

    const daysBlockBefore = dayjs(new Date()).add(-7, 'day');
    const daysBlockAfter = dayjs(new Date()).add(7, 'day');

    const calendarDays = [
      ...previousMonthFillArray.map((date) => {
        return { date, disabled: true };
      }),
      ...daysInMonthArray.map((date) => {
        // console.log(summary.find((s) => dayjs(s.data).get('date') === date.get('date')));
        return {
          date,
          disabled:
            date.endOf('day').isBefore(daysBlockBefore.toDate()) ||
            date.endOf('day').isAfter(daysBlockAfter.toDate()) ||
            blockedDates.blockedWeekDays.includes(date.get('day')) ||
            blockedDates.blockedDates.includes(date.get('date')),
          quantidade: summary.find((s) => dayjs(s.data).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'))?.quantidade,
          completo: summary.find((s) => dayjs(s.data).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'))?.completo,
        };
      }),
      ...nextMonthFillArray.map((date) => {
        return { date, disabled: true };
      }),
    ];

    const calendarWeeks = calendarDays.reduce<CalendarWeeks>((weeks, _, i, original) => {
      const isNewWeek = i % 7 === 0;

      if (isNewWeek) {
        weeks.push({
          week: i / 7 + 1,
          days: original.slice(i, i + 7),
        });
      }

      return weeks;
    }, []);

    return calendarWeeks;
  }, [currentDate, blockedDates, summary]);

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center justify-between">
        <p className="font-medium capitalize">
          {currentMonth} <span className="text-gray-600">{currentYear}</span>
        </p>
        <div className="flex gap-2 text-gray-600">
          <button className="cursor-pointer leading-3 rounded-sm hover:bg-gray-100" onClick={handlePreviousMonth}>
            <CaretLeft size={25} />
          </button>
          <button className="cursor-pointer leading-none rounded-sm hover:bg-gray-100" onClick={handleNextMonth}>
            <CaretRight size={25} />
          </button>
        </div>
      </div>
      {true ? (
        <table className="w-full border-spacing-1 table-fixed">
          <thead>
            <tr>
              {shortWeekDays.map((weekDay) => (
                <th className="text-gray-600 font-medium text-sm" key={weekDay}>
                  {weekDay}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full table-row-group align-middle before:block leading-3 content-['.'] text-gray-800">
            {calendarWeeks.map(({ week, days }) => {
              return (
                <tr key={week}>
                  {days.map(({ date, disabled, quantidade, completo }) => {
                    return (
                      <td className="box-border" key={date.toString()}>
                        <HabitDay date={date} disabled={disabled} amount={quantidade} defaultCompleted={completo} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full">Sem atividades para o mês selecionado</div>
      )}
    </div>
  );
};

export default Habitos;

interface CalendarWeek {
  week: number;
  days: Array<{
    date: dayjs.Dayjs;
    disabled: boolean;
    quantidade?: number;
    completo?: number;
  }>;
}

type CalendarWeeks = CalendarWeek[];

interface BlockedDates {
  blockedWeekDays: number[];
  blockedDates: number[];
}

// interface CalendarProps {
//   selectedDate: Date | null;
//   onDateSelected: (date: Date) => void;
// }

type Summary = {
  id: string;
  data: string;
  quantidade: number;
  completo: number;
}[];
