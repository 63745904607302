import React, { useEffect } from 'react';
import LayoutRoutes from '../Utilities/LayoutRoutes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import { treinoActions } from 'store/Treino.store';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const treinos = useAppSelector((state) => state.treinos.treinos);

  useEffect(() => {
    dispatch(treinoActions.treinos());
    dispatch(treinoActions.exercicios());
  }, [dispatch]);

  useDescriptionTitle('Organize your tasks', 'Home');
  return <LayoutRoutes title="Home" treinos={treinos}></LayoutRoutes>;
};

export default Home;
