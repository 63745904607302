import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalCreateTaskOpen: false,
  modalFilterOpen: false,
  modalPerfilOpen: false,
  modalAtividadeOpen: false,
  modalTreinoOpen: false,
  modalReviewOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    openModalCreateTask(state) {
      state.modalCreateTaskOpen = true;
    },
    closeModalCreateTask(state) {
      state.modalCreateTaskOpen = false;
    },
    openModalPerfil(state) {
      state.modalPerfilOpen = true;
    },
    closeModalPerfil(state) {
      state.modalPerfilOpen = false;
    },
    openModalAtividade(state) {
      state.modalAtividadeOpen = true;
    },
    closeModalAtividade(state) {
      state.modalAtividadeOpen = false;
    },
    openModalTreino(state) {
      state.modalTreinoOpen = true;
    },
    closeModalTreino(state) {
      state.modalTreinoOpen = false;
    },
    openModalReview(state) {
      state.modalReviewOpen = true;
    },
    closeModalReview(state) {
      state.modalReviewOpen = false;
    },
  },
});

export const modalActions = modalSlice.actions;
export default modalSlice.reducer;
