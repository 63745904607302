import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Inputs = {
  wr01: string;
  wr02: string;
  wr03: string;
  wr04: string;
  wr05: string;
  wr06: string;
  wr07: string;
  wr08: string;
  wr09: string;
  wr10: string;
  wr11: string;
  wr12: string;
  wr13: string;
  wr14: string;
  wr15: string;
  wr16: string;
  wr17: string;
  wr18: string;
  wr19: string;
  wr20: string;
};

const Radar: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const avaliacao = useAppSelector((state) => state.avaliacoes.radar.selected);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.radarById(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!avaliacao) return;
    reset(avaliacao);
  }, [avaliacao, reset]);

  const { wr01, wr03 } = watch();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await dispatch(avaliacaoActions.addRadar(data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">
        Estilo de vida
      </h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <p className="text-sm text-gray-500 mb-5">
                    O Radar do Bem Estar é um instrumento que avalia indicadores subjetivos relacionados a saúde física, relacionamento
                    pessoal e no bem estar emocional. Na DIMENSÃO FíSICA, queremos saber:
                  </p>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      1. Em quantos dias da última semana você CAMINHOU por pelo menos 10 minutos contínuos em casa ou no trabalho, como
                      forma de transporte para ir de um lugar para outro, ou por lazer?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr01.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nenhum'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nenhum
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr01.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'1 Vez'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        1 Vez
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr01.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'2 Vezes'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        2 Vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr01.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'3 Vezes'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        3 Vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr01.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'4 Vezes'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        4 Vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr01.6"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'5 a 7 Vezes'}
                        {...register('wr01', { required: true })}
                      />
                      <label htmlFor="wr01.6" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        5 a 7 Vezes
                      </label>
                    </div>
                    {errors.wr01 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {wr01 !== 'Nenhum' && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">2. Nos dias em que você caminhou, qual a duraçã média por dia?</p>
                      <div className="flex items-center">
                        <input
                          id="wr02.1"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 10 minutos e 15 minutos'}
                          {...register('wr02', { required: false })}
                        />
                        <label htmlFor="wr02.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 10 minutos e 15 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr02.2"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 15 minutos e 20 minutos'}
                          {...register('wr02', { required: false })}
                        />
                        <label htmlFor="wr02.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 15 minutos e 20 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr02.1"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 20 minutos e 30 minutos'}
                          {...register('wr02', { required: false })}
                        />
                        <label htmlFor="wr02.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 20 minutos e 30 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr02.2"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Mais que 30 minutos'}
                          {...register('wr02', { required: false })}
                        />
                        <label htmlFor="wr02.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Mais que 30 minutos
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      3. Você pratica, regularmente, algum tipo de atividade física(ginástica, musculação, ciclismo, esporte, dança ou artes
                      marciais)?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr03.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Não'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr03.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'1 dia por semana'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        1 dia por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr03.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'2 dias por semana'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        2 dias por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr03.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'3 dias por semana'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        3 dias por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr03.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'4 dias por semana'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        4 dias por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr03.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'5 a 7 dias por semana'}
                        {...register('wr03', { required: true })}
                      />
                      <label htmlFor="wr03.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        5 a 7 dias por semana
                      </label>
                    </div>
                    {errors.wr03 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                {wr03 !== 'Não' && (
                  <fieldset>
                    <div className="mt-4 space-y-4">
                      <p className="text-sm text-gray-500 mb-5">
                        4. Se você pratica alguma destas atividades física de forma regular, a duração média é igual ou superior a 30
                        minutos por dia?
                      </p>
                      <div className="flex items-center">
                        <input
                          id="wr04.1"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 10 minutos e 15 minutos'}
                          {...register('wr04', { required: false })}
                        />
                        <label htmlFor="wr04.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 10 minutos e 15 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr04.2"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 15 minutos e 20 minutos'}
                          {...register('wr04', { required: false })}
                        />
                        <label htmlFor="wr04.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 15 minutos e 20 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr04.1"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Entre 20 minutos e 30 minutos'}
                          {...register('wr04', { required: false })}
                        />
                        <label htmlFor="wr04.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Entre 20 minutos e 30 minutos
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="wr04.2"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={'Mais que 30 minutos'}
                          {...register('wr04', { required: false })}
                        />
                        <label htmlFor="wr04.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          Mais que 30 minutos
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      5. Em quantos dias da semana você come legumes ou verduras (alface, tomate, couve, cenoura, chuchu, berinjela,
                      abobrinha / não vale batata, mandioca ou inhame)?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr05.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca'}
                        {...register('wr05', { required: true })}
                      />
                      <label htmlFor="wr05.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr05.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'1x a 3x por semana'}
                        {...register('wr05', { required: true })}
                      />
                      <label htmlFor="wr05.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        1x a 3x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr05.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'4x a 6x por semana'}
                        {...register('wr05', { required: true })}
                      />
                      <label htmlFor="wr05.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        4x a 6x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr05.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 1x por dia'}
                        {...register('wr05', { required: true })}
                      />
                      <label htmlFor="wr05.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 1x por dia
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr05.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 2x ou mais por dia'}
                        {...register('wr05', { required: true })}
                      />
                      <label htmlFor="wr05.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 2x ou mais por dia
                      </label>
                    </div>
                    {errors.wr05 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      6. Em quantos dias da semana você come frutas (ou toma suco de fruta natural)?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr06.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca'}
                        {...register('wr06', { required: true })}
                      />
                      <label htmlFor="wr06.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr06.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'1x a 3x por semana'}
                        {...register('wr06', { required: true })}
                      />
                      <label htmlFor="wr06.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        1x a 3x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr06.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'4x a 6x por semana'}
                        {...register('wr06', { required: true })}
                      />
                      <label htmlFor="wr06.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        4x a 6x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr06.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 1x por dia'}
                        {...register('wr06', { required: true })}
                      />
                      <label htmlFor="wr06.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 1x por dia
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr06.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 2x ou mais por dia'}
                        {...register('wr06', { required: true })}
                      />
                      <label htmlFor="wr06.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 2x ou mais por dia
                      </label>
                    </div>
                    {errors.wr06 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      7. Em quantos dias da semana você toma refrigerantes ou sucos artificiais(refrescos)?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr07.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca'}
                        {...register('wr07', { required: true })}
                      />
                      <label htmlFor="wr07.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr07.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'1x a 3x por semana'}
                        {...register('wr07', { required: true })}
                      />
                      <label htmlFor="wr07.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        1x a 3x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr07.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'4x a 6x por semana'}
                        {...register('wr07', { required: true })}
                      />
                      <label htmlFor="wr07.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        4x a 6x por semana
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr07.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 1x por dia'}
                        {...register('wr07', { required: true })}
                      />
                      <label htmlFor="wr07.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 1x por dia
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr07.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Diariamente 2x ou mais por dia'}
                        {...register('wr07', { required: true })}
                      />
                      <label htmlFor="wr07.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Diariamente 2x ou mais por dia
                      </label>
                    </div>
                    {errors.wr07 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">8. Quantos cigarros você fuma por dia?</p>
                    <div className="flex items-center">
                      <input
                        id="wr08.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca fumei'}
                        {...register('wr08', { required: true })}
                      />
                      <label htmlFor="wr08.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca fumei
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr08.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Parei de fumar há menos de 2 anos'}
                        {...register('wr08', { required: true })}
                      />
                      <label htmlFor="wr08.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Parei de fumar há menos de 2 anos
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr08.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'FUMO menos de 10 cigarros por dia'}
                        {...register('wr08', { required: true })}
                      />
                      <label htmlFor="wr08.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        FUMO menos de 10 cigarros por dia
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr08.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'FUMO de 10 a 20 cigarros por dia'}
                        {...register('wr08', { required: true })}
                      />
                      <label htmlFor="wr08.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        FUMO de 10 a 20 cigarros por dia
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr08.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'FUMO mais de 20 cigarros por dia'}
                        {...register('wr08', { required: true })}
                      />
                      <label htmlFor="wr08.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        FUMO mais de 20 cigarros por dia
                      </label>
                    </div>
                    {errors.wr08 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      9. Nos últimos 30 dias, você tomou cinco ou mais doses de bebidas alcoólicas numa mesma ocasião.(uma lata de cerveja,
                      uma taça de vinho ou uma dose de cachaça, whisky ou qualquer outra bebida alcoólica destilada)
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr09.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sim'}
                        {...register('wr09', { required: true })}
                      />
                      <label htmlFor="wr09.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sim
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr09.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Não'}
                        {...register('wr09', { required: true })}
                      />
                      <label htmlFor="wr09.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Não
                      </label>
                    </div>
                    {errors.wr09 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">10. Com que frequência você considera que dorme bem?</p>
                    <div className="flex items-center">
                      <input
                        id="wr10.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sempre ou quase sempre'}
                        {...register('wr10', { required: true })}
                      />
                      <label htmlFor="wr10.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sempre ou quase sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr10.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Às vezes'}
                        {...register('wr10', { required: true })}
                      />
                      <label htmlFor="wr10.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Às vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr10.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca ou raramente'}
                        {...register('wr10', { required: true })}
                      />
                      <label htmlFor="wr10.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca ou raramente
                      </label>
                    </div>
                    {errors.wr10 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <legend className="text-xl font-semibold text-gray-700 mb-5">Na DIMENSÃO SOCIAL, informe:</legend>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      11. De uma maneira geral, como você classifica a qualidade dos seus relacionamentos que mantém com outras
                      pessoas?(amigos, colegas de trabalho, chefes)
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr11.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito satisfeito'}
                        {...register('wr11', { required: true })}
                      />
                      <label htmlFor="wr11.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito satisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr11.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Satisfeito'}
                        {...register('wr11', { required: true })}
                      />
                      <label htmlFor="wr11.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Satisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr11.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nem insatisfeito e nem satisfeito'}
                        {...register('wr11', { required: true })}
                      />
                      <label htmlFor="wr11.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nem insatisfeito e nem satisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr11.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Insatisfeito'}
                        {...register('wr11', { required: true })}
                      />
                      <label htmlFor="wr11.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Insatisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr11.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito insatisfeito'}
                        {...register('wr11', { required: true })}
                      />
                      <label htmlFor="wr11.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito insatisfeito
                      </label>
                    </div>
                    {errors.wr11 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      12. Você procura cultivar amigos e está satisfeito com seus relacionamentos.
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr12.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sempre'}
                        {...register('wr12', { required: true })}
                      />
                      <label htmlFor="wr12.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr12.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Quase sempre'}
                        {...register('wr12', { required: true })}
                      />
                      <label htmlFor="wr12.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Quase sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr12.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Às vezes'}
                        {...register('wr12', { required: true })}
                      />
                      <label htmlFor="wr12.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Às vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr12.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca ou raramente'}
                        {...register('wr12', { required: true })}
                      />
                      <label htmlFor="wr12.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca ou raramente
                      </label>
                    </div>
                    {errors.wr12 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      13. Seu lazer inclui encontros com amigos, atividades em grupo, participação em associações ou entidades sociais?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr13.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sempre'}
                        {...register('wr13', { required: true })}
                      />
                      <label htmlFor="wr13.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr13.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Quase sempre'}
                        {...register('wr13', { required: true })}
                      />
                      <label htmlFor="wr13.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Quase sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr13.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Às vezes'}
                        {...register('wr13', { required: true })}
                      />
                      <label htmlFor="wr13.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Às vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr13.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca ou raramente'}
                        {...register('wr13', { required: true })}
                      />
                      <label htmlFor="wr13.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca ou raramente
                      </label>
                    </div>
                    {errors.wr13 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      14. Você procura ser ativo em sua comunidade, sentindo-se útil no seu ambiente social?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr14.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sempre'}
                        {...register('wr14', { required: true })}
                      />
                      <label htmlFor="wr14.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr14.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Quase sempre'}
                        {...register('wr14', { required: true })}
                      />
                      <label htmlFor="wr14.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Quase sempre
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr14.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Às vezes'}
                        {...register('wr14', { required: true })}
                      />
                      <label htmlFor="wr14.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Às vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr14.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca ou raramente'}
                        {...register('wr14', { required: true })}
                      />
                      <label htmlFor="wr14.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca ou raramente
                      </label>
                    </div>
                    {errors.wr14 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <legend className="text-xl font-semibold text-gray-700 mb-5">Na DIMENSÃO EMOCIONAL, informe:</legend>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">15. Quanto você aproveita a vida?</p>
                    <div className="flex items-center">
                      <input
                        id="wr15.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nada'}
                        {...register('wr15', { required: true })}
                      />
                      <label htmlFor="wr15.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nada
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr15.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito pouco'}
                        {...register('wr15', { required: true })}
                      />
                      <label htmlFor="wr15.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito pouco
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr15.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Mais ou menos'}
                        {...register('wr15', { required: true })}
                      />
                      <label htmlFor="wr15.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Mais ou menos
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr15.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Bastante'}
                        {...register('wr15', { required: true })}
                      />
                      <label htmlFor="wr15.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Bastante
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr15.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Extremamente'}
                        {...register('wr15', { required: true })}
                      />
                      <label htmlFor="wr15.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Extremamente
                      </label>
                    </div>
                    {errors.wr15 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">16. Em que medida você acha que a sua vida tem sentido?</p>
                    <div className="flex items-center">
                      <input
                        id="wr16.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nada'}
                        {...register('wr16', { required: true })}
                      />
                      <label htmlFor="wr16.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nada
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr16.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito pouco'}
                        {...register('wr16', { required: true })}
                      />
                      <label htmlFor="wr16.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito pouco
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr16.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Mais ou menos'}
                        {...register('wr16', { required: true })}
                      />
                      <label htmlFor="wr16.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Mais ou menos
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr16.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Bastante'}
                        {...register('wr16', { required: true })}
                      />
                      <label htmlFor="wr16.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Bastante
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr16.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Extremamente'}
                        {...register('wr16', { required: true })}
                      />
                      <label htmlFor="wr16.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Extremamente
                      </label>
                    </div>
                    {errors.wr16 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">17. Quanto você consegue se concentrar?</p>
                    <div className="flex items-center">
                      <input
                        id="wr17.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nada'}
                        {...register('wr17', { required: true })}
                      />
                      <label htmlFor="wr17.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nada
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr17.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito pouco'}
                        {...register('wr17', { required: true })}
                      />
                      <label htmlFor="wr17.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito pouco
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr17.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Mais ou menos'}
                        {...register('wr17', { required: true })}
                      />
                      <label htmlFor="wr17.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Mais ou menos
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr17.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Bastante'}
                        {...register('wr17', { required: true })}
                      />
                      <label htmlFor="wr17.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Bastante
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr17.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Extremamente'}
                        {...register('wr17', { required: true })}
                      />
                      <label htmlFor="wr17.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Extremamente
                      </label>
                    </div>
                    {errors.wr17 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">18. Quanto você é capaz de aceitar sua aparência física?</p>
                    <div className="flex items-center">
                      <input
                        id="wr18.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nada'}
                        {...register('wr18', { required: true })}
                      />
                      <label htmlFor="wr18.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nada
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr18.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito pouco'}
                        {...register('wr18', { required: true })}
                      />
                      <label htmlFor="wr18.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito pouco
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr18.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Médio'}
                        {...register('wr18', { required: true })}
                      />
                      <label htmlFor="wr18.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Médio
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr18.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito'}
                        {...register('wr18', { required: true })}
                      />
                      <label htmlFor="wr18.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr18.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Completamente'}
                        {...register('wr18', { required: true })}
                      />
                      <label htmlFor="wr18.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Completamente
                      </label>
                    </div>
                    {errors.wr18 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">19. Quão satisfeito(a) você está consigo mesmo?</p>
                    <div className="flex items-center">
                      <input
                        id="wr19.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito insatisfeito'}
                        {...register('wr19', { required: true })}
                      />
                      <label htmlFor="wr19.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito insatisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr19.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Insatisfeito'}
                        {...register('wr19', { required: true })}
                      />
                      <label htmlFor="wr19.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Insatisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr19.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nem insatisfeito, nem satifeito'}
                        {...register('wr19', { required: true })}
                      />
                      <label htmlFor="wr19.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nem insatisfeito, nem satifeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr19.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Satisfeito'}
                        {...register('wr19', { required: true })}
                      />
                      <label htmlFor="wr19.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Satisfeito
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr19.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito satisfeito'}
                        {...register('wr19', { required: true })}
                      />
                      <label htmlFor="wr19.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito satisfeito
                      </label>
                    </div>
                    {errors.wr19 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <p className="text-sm text-gray-500 mb-5">
                      20. Com que frequência você tem sentimentos positivos tais como bom humor, calma e tranquilidade?
                    </p>
                    <div className="flex items-center">
                      <input
                        id="wr20.1"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Nunca'}
                        {...register('wr20', { required: true })}
                      />
                      <label htmlFor="wr20.1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Nunca
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr20.2"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Algumas vezes'}
                        {...register('wr20', { required: true })}
                      />
                      <label htmlFor="wr20.2" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Algumas vezes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr20.3"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Frequentemente'}
                        {...register('wr20', { required: true })}
                      />
                      <label htmlFor="wr20.3" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Frequentemente
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr20.4"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Muito frequentemente'}
                        {...register('wr20', { required: true })}
                      />
                      <label htmlFor="wr20.4" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Muito frequentemente
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="wr20.5"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={'Sempre'}
                        {...register('wr20', { required: true })}
                      />
                      <label htmlFor="wr20.5" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Sempre
                      </label>
                    </div>
                    {errors.wr20 && <p className="text-red-500">*Obrigatório.</p>}
                  </div>
                </fieldset>
              </div>
              {!(Number(id) > 0) && (
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
                    disabled={loading}
                  >
                    Gravar
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Radar };
