import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDescriptionTitle from '../hooks/useDescriptionTitle';
import LayoutDocuments from '../Utilities/LayoutDocuments';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actions } from 'store/Documentos.store';

const Document: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { list, currentPage, pages } = useAppSelector((state) => state.documentos);
  const [page, setPage] = useState(1);
  const [text, setText] = useState('');

  useEffect(() => {
    const categoria = params.dir === 'documento' ? 1 : params.dir === 'video' ? 2 : params.dir === 'audio' ? 3 : 0;
    const filter = { categoria: categoria, page: page, text: text };
    dispatch(actions.nextPage(filter));
  }, [page, dispatch, params.dir, text]);

  useDescriptionTitle(`Tasks in "${params.dir}"`, params.dir ? params.dir + ' directory' : '');
  return (
    <LayoutDocuments
      title={`${params.dir}`}
      documentos={list}
      currentPage={currentPage}
      pages={pages}
      setPage={setPage}
      setText={setText}
    />
  );
};

export default Document;
