import { Routes, Route } from 'react-router-dom';
import {
  Anamnese,
  Atestado,
  Imc,
  NeuroQ,
  Objetivos,
  Parq,
  Radar,
  ObjetivoList,
  ParqList,
  AnamneseList,
  RadarList,
  NeuroQList,
  ImcsList,
  Antropometria,
  AntropometriaList,
} from '../Questionarios';
import Avaliacoes from 'components/Routes/Avaliacoes';

function AvaliacaoLayout() {
  return (
    <Routes>
      <Route path="/" element={<Avaliacoes />} />
      <Route path="/objetivos" element={<ObjetivoList />} />
      <Route path="/objetivos/:id" element={<Objetivos />} />
      <Route path="/parq" element={<ParqList />} />
      <Route path="/parq/:id" element={<Parq />} />
      <Route path="/atestado" element={<Atestado />} />
      <Route path="/atestado/:id" element={<Atestado />} />
      <Route path="/anamnese" element={<AnamneseList />} />
      <Route path="/anamnese/:id" element={<Anamnese />} />
      <Route path="/radar" element={<RadarList />} />
      <Route path="/radar/:id" element={<Radar />} />
      <Route path="/neuroq" element={<NeuroQList />} />
      <Route path="/neuroq/:id" element={<NeuroQ />} />
      <Route path="/imc" element={<ImcsList />} />
      <Route path="/imc/:id" element={<Imc />} />
      <Route path="/antropometria" element={<AntropometriaList />} />
      <Route path="/antropometria/:id" element={<Antropometria />} />
      <Route path="*" element={<div>Nao encontrado</div>} />
    </Routes>
  );
}

export { AvaliacaoLayout };
