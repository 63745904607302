import { ChangeEvent } from 'react';

interface Props {
  value: string;
  onChange: (ev: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({ value, onChange }: Props) => {
  return (
    <textarea
      className="w-72 h-24 p-3 bg-gray-300 border-none outline-none rounded-lg text-base resize-none placeholder:text-gray-600"
      placeholder="Escreva seu comentário aqui."
      value={value}
      onChange={onChange}
    ></textarea>
  );
};
