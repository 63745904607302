import PerimetriaDesc from './PerimetriaDesc';
import PerimetriaField from './PerimetriaField';
import PerimetriaFields from './PerimetriaFields';
import PerimetriaFieldsItem from './PerimetriaFieldsItem';
import PerimetriaRow from './PerimetriaRow';
import PerimetriaType from './PerimetriaType';

export const Perimetria = {
  Root: PerimetriaRow,
  Type: PerimetriaType,
  Desc: PerimetriaDesc,
  Field: PerimetriaField,
  Fields: PerimetriaFields,
  FieldsItem: PerimetriaFieldsItem,
};
